import { get } from 'lodash-es';
import React, { useContext } from 'react';
import { AuthState } from 'types';

import { Loader } from '../../components';
import { AuthContext } from '../AuthWrapper';
import ConfirmSignIn from './ConfirmSignIn';
import ForgotPassword from './ForgotPassword';
import ForgotPasswordSubmit from './ForgotPasswordSubmit';
import MFASetup from './MFASetup';
import SetNewPassword from './SetNewPassword';
import SignIn from './SignIn';

interface ILoginWizard {
  authState: AuthState;
  setState: any;
  user: any;
  forgottenPassword?: {
    username?: string;
  };
}

const loginWizardDefault: ILoginWizard = {
  authState: AuthState.Default,
  setState: () => null,
  user: {},
};

export const LoginWizardContext =
  React.createContext<ILoginWizard>(loginWizardDefault);

const getPage = (authState: AuthState) => {
  switch (authState) {
    case AuthState.ForgotPassword:
      return <ForgotPassword />;
    case AuthState.ForgotPasswordSubmit:
      return <ForgotPasswordSubmit />;
    case AuthState.MfaSetup:
      return <MFASetup />;
    case AuthState.SetNewPassword:
      return <SetNewPassword />;
    case AuthState.ConfirmSignIn:
      return <ConfirmSignIn />;
    case AuthState.Authenticated:
      return <Loader fullScreen={true} size="large" message="Logging in..." />;
    default:
      return <SignIn />;
  }
};

const LoginWizard = () => {
  const { auth, setAuth } = useContext(AuthContext);

  const defaultWizardState = {
    authState: auth.isAuthenticated
      ? AuthState.Authenticated
      : AuthState.Default,
    user: {},
  };

  const [state, setState] = React.useState(defaultWizardState);

  React.useEffect(() => {
    if (state.authState === AuthState.Authenticated) {
      const group = get(
        state,
        'user.signInUserSession.idToken.payload.cognito:groups[0]',
      );
      setAuth({ isAuthenticated: true, group });
    }
  }, [state, setAuth]);

  return (
    <LoginWizardContext.Provider
      value={{
        setState,
        ...state,
      }}
    >
      {getPage(state.authState)}
    </LoginWizardContext.Provider>
  );
};

export default LoginWizard;
