import { Layout as AntdLayout } from 'antd';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { useBoolean } from '..';
import Sider from './Sider';
import Trigger from './Trigger';

const Content = styled.div`
  height: 100vh;
  overflow: scroll;
`;

interface IProps extends RouteComponentProps {
  children: JSX.Element;
}

export const Layout = ({ children, history }: IProps) => {
  const menuCollapsed = useBoolean(false);
  const drawerVisible = useBoolean(false);

  const handleMenuItemClick = (route: string) => {
    history.push(route);
    drawerVisible.setFalse();
  };

  return (
    <AntdLayout>
      <Sider
        isMenuCollapsed={menuCollapsed.value}
        isDrawerVisible={drawerVisible.value}
        onDrawerClose={drawerVisible.setFalse}
        onMenuItemClick={handleMenuItemClick}
        onBreakpoint={(v: boolean) => menuCollapsed.setValue(v)}
      />
      <AntdLayout.Content>
        <Content>
          {menuCollapsed.value && (
            <Trigger
              isDrawerVisible={drawerVisible.value}
              toggleDrawerVisible={drawerVisible.toggle}
            />
          )}
          {children}
        </Content>
      </AntdLayout.Content>
    </AntdLayout>
  );
};

export default withRouter(Layout);
