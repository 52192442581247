import { message } from 'antd';
import { TFunction } from 'i18next';
import React from 'react';
import { ExportFormat } from 'types';

import { useBoolean } from '../../hooks';
import ExportButtonDropdown from './ExportButtonDropdown';
import { exportToFile } from './generateSpreadsheet';

export interface IExportButton<T> {
  onClick: () => Promise<{ data: T[]; fileName: string } | undefined>;
  count: number;
  label: string | TFunction;
}

function ExportButton<T>({ onClick, count, label }: IExportButton<T>) {
  const isLoading = useBoolean(false);

  const handleClick = async (exportFormat: ExportFormat) => {
    try {
      isLoading.setTrue();
      const { data, fileName } = await onClick();

      exportToFile<T>({
        format: exportFormat,
        fileName,
        data,
      });
    } catch (e) {
      message.error(e.message);
    } finally {
      isLoading.setFalse();
    }
  };

  return (
    <ExportButtonDropdown
      label={label}
      count={count}
      onClick={handleClick}
      isLoading={isLoading.value}
    />
  );
}

export default ExportButton;
