import React, { lazy, Suspense, useContext } from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import { AdminUserRole } from 'types';
import { QueryParamProvider } from 'use-query-params';

import { AuthContext } from '../auth/AuthWrapper';
import LoginWizard from '../auth/components/LoginWizard';
import { Layout, Loader, NotFoundPage } from '../components';
import ProtectedRoute from './ProtectedRoute';

const AllAdminUserRoles = [
  AdminUserRole.admin,
  AdminUserRole.cs,
  AdminUserRole.sales,
  AdminUserRole.developer,
];

const Router = () => {
  const { auth } = useContext(AuthContext);

  if (!auth.isAuthenticated) {
    return <LoginWizard />;
  }

  return (
    <HashRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Layout>
          <Suspense fallback={<Loader fullScreen={true} />}>
            <Switch>
              <Route
                path="/"
                exact={true}
                render={() => <Redirect to={'/customers'} />}
              />
              <Route
                path="/:type/data/:customerId/:module?"
                component={lazy(
                  () => import('../modules/customers/data/CustomerDataRouter'),
                )}
              />
              <ProtectedRoute
                path="/customers/:action?/:id?"
                allowedRoles={AllAdminUserRoles}
                component={lazy(() => import('../modules/customers/Customers'))}
              />
              <ProtectedRoute
                path="/templates/:action?/:id?"
                allowedRoles={[AdminUserRole.admin, AdminUserRole.cs]}
                component={lazy(() => import('../modules/customers/Templates'))}
              />
              <ProtectedRoute
                path="/phone-numbers"
                component={lazy(
                  () => import('../modules/phoneNumbers/list/PhoneNumberList'),
                )}
                allowedRoles={[AdminUserRole.admin, AdminUserRole.cs]}
              />
              <ProtectedRoute
                path="/analytics/:dashboardId"
                component={lazy(() => import('../modules/analytics/Dashboard'))}
                allowedRoles={[
                  AdminUserRole.admin,
                  AdminUserRole.cs,
                  AdminUserRole.developer,
                ]}
              />
              <ProtectedRoute
                path="/analytics"
                component={lazy(
                  () => import('../modules/analytics/DashboardList'),
                )}
                allowedRoles={[
                  AdminUserRole.admin,
                  AdminUserRole.cs,
                  AdminUserRole.developer,
                ]}
              />
              <ProtectedRoute
                path="/users"
                component={lazy(
                  () => import('../modules/adminUsers/list/AdminUserList'),
                )}
                allowedRoles={[AdminUserRole.admin]}
              />
              <ProtectedRoute
                path="*"
                component={NotFoundPage}
                allowedRoles={AllAdminUserRoles}
              />
            </Switch>
          </Suspense>
        </Layout>
      </QueryParamProvider>
    </HashRouter>
  );
};

export default Router;
