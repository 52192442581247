export enum MutationTypes {
  Create = 'create',
  Edit = 'edit',
  Import = 'import',
  Login = 'login',
  Remove = 'remove',
  Reset = 'reset',
  Export = 'export',
}

export enum SystemModules {
  AdminUser = 'admin_user',
  PhoneNumber = 'phone_number',
}

export enum CustomerModules {
  Customer = 'customer',
  Images = 'image',
}

export enum CustomerDataModules {
  Asset = 'asset',
  ApiUser = 'api_user',
  DashboardUser = 'dashboard_user',
  Fieldworker = 'fieldworker',
  AssetAttachment = 'asset_attachment',
  AssetAttachmentRelation = 'asset_attachment_relation',
  AssetGPSDevice = 'asset_gps_device',
  ChargeCode = 'charge_code',
  Client = 'client',
  ClientContact = 'client_contact',
  Competency = 'competency',
  Contact = 'contact',
  DocumentType = 'document_type',
  EmploymentType = 'employment_type',
  Form = 'form',
  Induction = 'induction',
  Project = 'project',
  ProjectContact = 'project_contact',
  Task = 'task',
  User = 'user',
  UserCompetency = 'user_competency',
  UserInduction = 'user_induction',
  UserType = 'user_type',
  Supplier = 'supplier',
  WebhookModule = 'webhook_module',
  Webhook = 'webhook',
  WorkItem = 'work_item',
  Setting = 'setting',
}

export const Modules = {
  ...CustomerDataModules,
  ...CustomerModules,
  ...SystemModules,
};
export type Modules = CustomerDataModules | CustomerModules | SystemModules;
