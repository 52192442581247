import { adminUsers } from './adminUsers';
import { analytics } from './analytics';
import { apiUsers } from './apiUsers';
import { assetAttachments } from './assetAttachments';
import { assetGPSDevices } from './assetGPSDevices';
import { assets } from './assets';
import { auth } from './auth';
import { chargeCodes } from './chargeCodes';
import { clients } from './clients';
import { common } from './common';
import { competencies } from './competencies';
import { components } from './components';
import { contacts } from './contacts';
import { customers } from './customers';
import { dashboardUsers } from './dashboardUsers';
import { documentTypes } from './documentTypes';
import { employmentTypes } from './employmentTypes';
import { fieldworkers } from './fieldworkers';
import { forms } from './forms';
import { inductions } from './inductions';
import { phoneNumbers } from './phoneNumbers';
import { projects } from './projects';
import { router } from './router';
import { subscriptions } from './subscriptions';
import { suppliers } from './suppliers';
import { tasks } from './tasks';
import { templates } from './templates';
import { users } from './users';
import { webhooks } from './webhook';
import { webhookModules } from './webhookModules';
import { workItems } from './workItems';

export default {
  analytics,
  assets,
  'asset-attachments': assetAttachments,
  'asset-gps-devices': assetGPSDevices,
  'admin-users': adminUsers,
  auth,
  'charge-codes': chargeCodes,
  common,
  components,
  customers,
  contacts,
  clients,
  competencies,
  'document-types': documentTypes,
  router,
  users,
  'api-users': apiUsers,
  'dashboard-users': dashboardUsers,
  fieldworkers,
  'work-items': workItems,
  'employment-types': employmentTypes,
  forms,
  inductions,
  tasks,
  projects,
  'phone-numbers': phoneNumbers,
  subscriptions,
  suppliers,
  templates,
  webhooks,
  'webhook-modules': webhookModules,
};
