import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import Auth from '@aws-amplify/auth';
import { CognitoIdToken } from 'amazon-cognito-identity-js';
import ApolloClient, { InMemoryCache } from 'apollo-boost';
import { ErrorResponse } from 'apollo-link-error';
import { GraphQLError } from 'graphql';
import React from 'react';

import { AuthContext } from '../auth/AuthWrapper';
import { Loader } from '../components';

const cache = new InMemoryCache();

export const ApolloClientWrapper = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { auth } = React.useContext(AuthContext);
  const [cognitoIdToken, setCognitoIdToken] = React.useState<
    CognitoIdToken | undefined
  >();

  React.useEffect(() => {
    Auth.currentSession().then((s) => setCognitoIdToken(s.getIdToken()));
  }, [auth]);

  const onError = ({ graphQLErrors, networkError }: ErrorResponse) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        if (extensions && extensions.code === 'UNAUTHENTICATED') {
          Auth.currentSession().then((s) => {
            if (!s.isValid()) {
              setCognitoIdToken(s.getIdToken());
            }
          });
        }
        // tslint:disable-next-line
        console.warn(
          `[GraphQL error]: Message: ${message}, Location: ` +
            `${locations}, Path: ${path}`,
        );
      });
    }
    if (networkError) {
      // tslint:disable-next-line
      console.warn(`[Network error]: ${networkError}`);
    }
  };

  // state between state being set and login
  if (!cognitoIdToken && auth.isAuthenticated) {
    return <Loader fullScreen={true} message="Obtaining ID Token..." />;
  }

  const client = new ApolloClient({
    cache,
    onError,
    credentials: 'same-origin',
    headers: {
      Authorization: cognitoIdToken
        ? `Bearer ${cognitoIdToken.getJwtToken()}`
        : '',
    },
    // SSM value includes single quotes around URL, so need to remove them:
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT?.replace(/'/g, ''),
  });

  return <ApolloHooksProvider client={client}>{children}</ApolloHooksProvider>;
};
