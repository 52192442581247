import { Button, Modal } from 'antd';
import { getIn, useFormikContext } from 'formik';
import { size } from 'lodash-es';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useBoolean } from '../../../hooks';
import {
  CheckCircleIcon,
  DeleteIcon,
  EditIcon,
  ExclamationCircleIcon,
} from '../../../icons';
import renderFields from '../utils/renderFields';

interface IProps<T> {
  onRemove: (index: number) => void;
  fields: any[];
  index: number;
  name: string;
}

const Wrapper = styled.div`
  display: grid;
  grid-column-gap: 10px;
  align-items: center;
  justify-content: center;
  grid-template-columns: 20px 1fr 32px;
  margin-bottom: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Fields = styled.div`
  & > * {
    margin: 0 5px;
    overflow: hidden;
  }
  & > :first-child {
    margin-left: 0;
  }
  & > :last-child {
    margin-right: 0;
  }
`;

const ModalFieldLayout = function <T>({
  index,
  onRemove,
  fields,
  name,
}: IProps<T>) {
  const handleClick = () => onRemove(index);
  const isVisible = useBoolean(false);
  const form = useFormikContext();

  useEffect(() => {
    form.validateForm();
  }, [form]);

  const error = getIn(form.errors, `${name}[${index}]`);
  const hasError = !!size(error);

  return (
    <Wrapper>
      {hasError ? (
        <ExclamationCircleIcon style={{ color: 'red', fontSize: 20 }} />
      ) : (
        <CheckCircleIcon style={{ color: 'green', fontSize: 20 }} />
      )}
      <Button
        onClick={isVisible.setTrue}
        type="primary"
        ghost={true}
        icon={<EditIcon />}
      >
        Resolve
      </Button>
      <Modal
        width="50%"
        style={{ top: 20 }}
        onOk={isVisible.setFalse}
        onCancel={isVisible.setFalse}
        visible={isVisible.value}
      >
        <Fields>{renderFields<T>({ fields, index, name })}</Fields>
      </Modal>
      <ButtonWrapper>
        <Button
          key={`${index}-remove`}
          shape="circle"
          icon={<DeleteIcon />}
          onClick={handleClick}
          ghost={true}
          danger
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default ModalFieldLayout;
