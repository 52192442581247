export const tasks = {
  title: 'task',
  title_plural: 'tasks',
  fields: {
    id: 'ID',
    isActive: 'Active',
    name: 'Name',
    chargeCode: 'Charge code',
    chargeCodeId: 'Charge code ID',
    chargeCodeLabel: 'Charge code label',
    payLevel: 'Pay level',
    payrollAccount: 'Payroll account',
    required: 'Requires',
  },
  tags: {
    assets: 'Assets',
    isSupervisor: 'Supervisor',
  },
};
