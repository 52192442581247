import { Button, Result } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IProps extends RouteComponentProps {
  hideButton?: boolean;
}

const NotFoundPage = ({ history, hideButton }: IProps) => {
  const { t } = useTranslation('components');
  const handleOnButtonClick = () => history.push('/customers');

  return (
    <Result
      status="404"
      title={t('NotFoundPage.labels.title')}
      subTitle={t('NotFoundPage.labels.subTitle')}
      extra={
        !hideButton && (
          <Button type="primary" onClick={handleOnButtonClick}>
            {t('ErrorPage.labels.button')}
          </Button>
        )
      }
    />
  );
};

export default withRouter(NotFoundPage);
