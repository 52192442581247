import { Formik } from 'formik';
import { Form } from 'formik-antd';
import React from 'react';

export interface IFormFunc {
  setSubmitting: (v: boolean) => void;
}

type Nullable<T> = { [P in keyof T]: T[P] | null };

export interface IFormProps<TInitial, TResult> {
  children?: JSX.Element | JSX.Element[];
  validationSchema?: any;
  initialValues?: Nullable<TInitial> | null;
  onSubmit: (values: TResult, func: IFormFunc) => void;
}

const AssForm = <TInitial, TResult>({
  children,
  initialValues,
  validationSchema,
  onSubmit,
}: IFormProps<TInitial, TResult>) => {
  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={true}
      validateOnChange={false}
      validateOnMount={true}
      initialValues={initialValues || {}}
    >
      {() => <Form>{children}</Form>}
    </Formik>
  );
};

export default AssForm;
