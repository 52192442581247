export const webhooks = {
  title: 'webhook',
  title_plural: 'webhooks',
  fields: {
    isActive: 'Active',
    id: 'ID',
    name: 'Name',
    secretKey: 'Secret key',
    url: 'URL',
    webhookModule: 'Webhook module',
    moduleId: 'Module ID',
    moduleName: 'Module name',
    moduleMethodName: 'Module method',
    moduleVersion: 'Module version',
    createdByName: 'Created by',
    actions: 'Actions',
  },
  create: {
    button: 'Create',
    title: 'Create new webhook',
    submit: 'Create',
    success: 'Webhook {{ name }} successfully created',
  },
  edit: {
    title: 'Edit {{ name }}',
    loading: 'Loading webhook',
    success: 'Webhook {{ name }} successfully updated',
    submit: 'Update webhook',
  },
};
