export enum UserTypeId {
  Admin = 1,
  Fieldworker = 2,
  Dashboard = 3,
  Api = 4,
}

export enum UserTypeName {
  Admin = 'admin',
  Fieldworker = 'fieldworker',
  Dashboard = 'dashboard',
  Api = 'api',
}
