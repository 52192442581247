import { message, Tooltip } from 'antd';
import React from 'react';
import ReactCopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import { CopyIcon } from '.';

interface IProps {
  textToCopy: string;
  messageText?: string;
}

const CopyToClipboard = ({ textToCopy, messageText }: IProps) => {
  const { t } = useTranslation('components');

  const onCopy = (fieldLabel: string) =>
    message.success(
      t('CopyToClipboard.messages.success', {
        what: messageText ?? fieldLabel,
      }),
    );

  return (
    <ReactCopyToClipboard text={textToCopy} onCopy={onCopy}>
      <Tooltip title={t('CopyToClipboard.labels.title')}>
        <CopyIcon style={{ color: '#929191', cursor: 'pointer' }} />
      </Tooltip>
    </ReactCopyToClipboard>
  );
};

export default CopyToClipboard;
