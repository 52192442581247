import { Button } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AuthState } from 'types';

import { LeftIcon, SubmitButton } from '../../components';
import { LoginWizardContext } from './LoginWizard';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Actions = () => {
  const { t } = useTranslation('auth');
  const loginWizard = useContext(LoginWizardContext);

  const handleOnLoginButtonClick = () => {
    loginWizard.setState({ authState: AuthState.SignIn });
  };

  return (
    <Wrapper>
      <Button onClick={handleOnLoginButtonClick} icon={<LeftIcon />}>
        {t('AuthPageActions.labels.login')}
      </Button>
      <SubmitButton>{t('AuthPageActions.labels.confirm')}</SubmitButton>
    </Wrapper>
  );
};

export default Actions;
