import { Field } from 'formik';
import React from 'react';

interface IProps<T> {
  fields: any[];
  index: number;
  name: string;
}

function renderFields<T>({ fields, index, name }: IProps<T>) {
  return fields.map((field: any, i: number) => {
    const rowName = `${name}[${index}]`;

    return (
      <Field name={rowName} key={`${i}-field`}>
        {() =>
          React.cloneElement(field, {
            key: `${i}-component`,
            name: `${rowName}.${field.props.name}`,
          })
        }
      </Field>
    );
  });
}

export default renderFields;
