export const competencies = {
  title: 'competency',
  title_plural: 'competencies',
  fields: {
    actions: 'Actions',
    id: 'ID',
    name: 'Name',
    licenseNumber: 'License number',
    requiresExpirationDate: 'Requires expiration date',
    requiresIssueDate: 'Requires issue date',
    required: 'Requires',
  },
  tags: {
    issueDate: 'Issue Date',
    expirationDate: 'Exp. Date',
  },
  users: {
    title: 'User competencies',
    add: 'Add user',
    save: 'Update linked users',
    fields: {
      title: 'Users linked to {{ name }}',
      issueDate: 'Issue date',
      expirationDate: 'Expiration date',
      description: 'Description',
      userId: 'Select user',
    },
    messages: {
      loading: 'Loading users linked to competency',
      success: 'List of users with {{ name }} competency updated',
      empty: 'No competencies linked to this user.',
    },
  },
  edit: {
    title: 'Edit {{ name }}',
    loading: 'Loading competency',
    success: 'Competency {{ name }} successfully updated',
    submit: 'Update competency',
  },
};
