import { Badge, Divider, Empty } from 'antd';
import { ArrayHelpers, FieldArray, useFormikContext } from 'formik';
import { get } from 'lodash-es';
import React from 'react';
import styled from 'styled-components';

import AddButton from './components/AddButton';
import Fields from './components/Fields';

export interface IListField<T> {
  name: string;
  fields: JSX.Element[];
  placeholder: string;
  addLabel?: string;
  maxHeight?: string;
  icon?: JSX.Element;
}

const FieldWrapper = styled.div`
  ${(props: { maxHeight?: string }) => `
    ${props.maxHeight && `max-height: ${props.maxHeight};`}
    overflow-x: scroll;
  `};
`;

const FieldEnd = styled.div`
  padding: 10px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-right: 10px;
`;

const scrollToRef = (ref: any) =>
  ref.current && ref.current.scrollIntoView({ behavior: 'smooth' });

const ListField = function <T>({
  fields,
  addLabel,
  name,
  placeholder,
  icon,
  maxHeight,
}: IListField<T>) {
  const fieldEnd = React.useRef(null);
  const form = useFormikContext();
  const values = get(form.values, name, []);

  React.useEffect(() => {
    scrollToRef(fieldEnd);
  }, [values.length]);

  const hasNoValues = values.length === 0;

  const handleRender = (arrayHelpers: ArrayHelpers) => (
    <>
      <Divider orientation="left">
        <HeaderWrapper>
          {icon && <IconWrapper>{icon}</IconWrapper>}
          {placeholder}
          <Badge
            count={values.length}
            style={{ marginLeft: '10px', backgroundColor: '#1890ff' }}
          />
        </HeaderWrapper>
      </Divider>
      {hasNoValues && <Empty description="Nothing to see here. Move along." />}
      <FieldWrapper maxHeight={maxHeight}>
        {values.map((item: T, index: number) => (
          <Fields<T>
            key={index}
            // TODO: improve performance of array helpers after Formik issue https://github.com/jaredpalmer/formik/issues/1253
            onRemove={arrayHelpers.remove}
            fields={fields}
            index={index}
            name={name}
          />
        ))}
        <FieldEnd ref={fieldEnd} />
      </FieldWrapper>

      {addLabel && (
        <AddButton
          key={`${name}-add`}
          name={name}
          label={addLabel}
          // TODO: improve performance of array helpers after Formik issue https://github.com/jaredpalmer/formik/issues/1253
          onClick={arrayHelpers.push}
        />
      )}
    </>
  );

  return (
    <FieldArray name={name} render={handleRender} validateOnChange={true} />
  );
};

export default ListField;
