import React from 'react';

import FieldHoc from './components/FieldHoc';
import DatePicker, { IDatePickerProps } from './DatePicker';

const TimePicker = React.forwardRef<any, IDatePickerProps<any>>(
  (props, ref) => {
    return <DatePicker {...props} picker="time" mode={undefined} ref={ref} />;
  },
);

TimePicker.displayName = 'TimePicker';

export default FieldHoc(TimePicker);
