import React from 'react';
import styled from 'styled-components';

import { Spin } from './index';

const Wrapper = styled.div`
  ${(props: { fullScreen?: boolean }) => `
  display: grid;
  align-items: center;
  min-height: 100%;
  justify-content: center;
  padding: 10px;
  height: ${props.fullScreen ? '100vh' : 'initial'}
  `};
`;

const Content = styled.div`
  display: grid;
  grid-row-gap: 10px;
`;

interface IProps {
  fullScreen?: boolean;
  message?: string;
  size?: 'small' | 'default' | 'large';
}

const Loader = ({ fullScreen, message, size }: IProps) => (
  <Wrapper fullScreen={fullScreen}>
    <Content>
      <Spin size={size} />
      <span>{message}</span>
    </Content>
  </Wrapper>
);

export default Loader;
