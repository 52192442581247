import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en';

export const translations = {
  en,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    defaultNS: 'common',
    lng: 'en',
    ns: ['common', 'components'],
    resources: translations,
  });

export default i18n;
