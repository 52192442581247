import {
  InputNumber as FormikInputNumber,
  InputNumberProps,
} from 'formik-antd';
import React from 'react';

import FieldHoc from './components/FieldHoc';

const InputNumber = (props: InputNumberProps) => (
  <FormikInputNumber {...props} />
);

export default FieldHoc(InputNumber);
