import { Checkbox, Divider } from 'antd';
import { CheckboxOptionType, CheckboxValueType } from 'antd/lib/checkbox/Group';
import { Field, FieldProps } from 'formik';
import React from 'react';
import styled from 'styled-components';

const Option = styled.div`
  padding: 5px;
`;

interface IOption {
  value: number | string;
  label: string;
}

interface IProps {
  name: string;
  options: IOption[];
  placeholder: string;
}

interface IGetCheckboxState {
  value?: CheckboxValueType[];
  options: IOption[];
}

const getIsIndeterminate = ({ value, options }: IGetCheckboxState): boolean =>
  value && !!value.length && value.length < options.length;

const getIsChecked = ({ value, options }: IGetCheckboxState): boolean =>
  value && value.length === options.length;

const CheckboxGroup = ({ name, options, ...restProps }: IProps) => {
  const [isIndeterminate, setIsIndeterminate] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(false);

  return (
    <Field name={name}>
      {({
        field: { value },
        form: { setFieldValue, setFieldTouched },
      }: FieldProps) => {
        setIsIndeterminate(getIsIndeterminate({ value, options }));
        setIsChecked(getIsChecked({ value, options }));

        const handleCheckAllChange = (e: any) => {
          setIsIndeterminate(false);
          setIsChecked(e.target.checked);
          setFieldValue(
            name,
            e.target.checked ? options.map((o: any) => o.value) : [],
          );
          setFieldTouched(name, true);
        };

        const handleChange = (v: CheckboxValueType[]) => {
          setIsChecked(getIsChecked({ value: v, options }));
          setIsIndeterminate(getIsIndeterminate({ value: v, options }));
          setFieldValue(name, v);
          setFieldTouched(name, true);
        };

        return (
          <>
            <Divider orientation="left">
              <Checkbox
                indeterminate={isIndeterminate}
                onChange={handleCheckAllChange}
                checked={isChecked}
                style={{ marginRight: '10px' }}
              />
              {restProps.placeholder}
            </Divider>
            <Checkbox.Group
              value={value}
              onChange={handleChange}
              style={{ width: '100%' }}
              {...restProps}
            >
              {options.map((o: CheckboxOptionType, i: number) => (
                <Option key={i}>
                  <Checkbox value={o.value}>{o.label}</Checkbox>
                </Option>
              ))}
            </Checkbox.Group>
          </>
        );
      }}
    </Field>
  );
};

export default CheckboxGroup;
