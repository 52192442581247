import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { AdminUserRole } from 'types';

import { AuthContext } from '../auth/AuthWrapper';
import { isAuthorized } from '../auth/utils';
import { ErrorPage } from '../components';

interface IProps {
  path: any;
  exact?: boolean;
  component: any;
  allowedRoles: AdminUserRole[];
}

const ProtectedRoute = ({
  component: Component,
  allowedRoles,
  path,
  exact,
  history,
}: RouteComponentProps & IProps) => {
  const { t } = useTranslation('router');
  const { auth } = useContext(AuthContext);

  const render = (props: any) => {
    if (!isAuthorized(auth, allowedRoles)) {
      return (
        <ErrorPage
          title={t('ProtectedRoute.messages.errorPageTitle')}
          onButtonClick={() => history.push('/customers')}
        />
      );
    }

    return <Component {...props} />;
  };

  return <Route path={path} exact={exact} render={render} />;
};

export default withRouter(ProtectedRoute);
