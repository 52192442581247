import React from 'react';
import { AdminUserRole } from 'types';

import {
  AnalyticsIcon,
  CustomerIcon,
  TemplateIcon,
  TwilioIcon,
  UserIcon,
} from '../icons';

export interface IMenuItem {
  allowedRoles?: AdminUserRole[];
  label: string;
  route: string;
  icon: JSX.Element;
}

export const menuItems: IMenuItem[] = [
  {
    allowedRoles: [
      AdminUserRole.admin,
      AdminUserRole.cs,
      AdminUserRole.developer,
      AdminUserRole.sales,
    ],
    icon: <CustomerIcon />,
    label: 'Customers',
    route: '/customers',
  },
  {
    allowedRoles: [AdminUserRole.admin, AdminUserRole.cs],
    icon: <TemplateIcon />,
    label: 'Templates',
    route: '/templates',
  },
  ...(process.env.REACT_APP_STAGE === 'production'
    ? [
        {
          allowedRoles: [
            AdminUserRole.admin,
            AdminUserRole.cs,
            AdminUserRole.developer,
          ],
          icon: <AnalyticsIcon />,
          label: 'Analytics',
          route: '/analytics',
        },
      ]
    : []),
  {
    allowedRoles: [AdminUserRole.admin, AdminUserRole.cs],
    icon: <TwilioIcon />,
    label: 'Phone Numbers',
    route: '/phone-numbers',
  },
  {
    allowedRoles: [AdminUserRole.admin],
    icon: <UserIcon />,
    label: 'Users',
    route: '/users',
  },
];
