export const phoneNumbers = {
  title: 'Phone numbers',
  fields: {
    id: 'ID',
    friendlyName: 'Friendly name',
    phoneNumber: 'Phone number',
    createdDateTime: 'Created',
    modifiedDateTime: 'Modified',
    hasCustomer: 'Has customer',
  },
  TotalPhoneNumbers: {
    labels: {
      title: 'Total phone numbers: <br>',
    },
  },
};
