export const clients = {
  title: 'client',
  title_plural: 'clients',
  fields: {
    id: 'ID',
    isActive: 'Active',
    externalId: 'External ID',
    name: 'Name',
    abn: 'ABN',
    contacts: 'Contacts',
    actions: 'Actions',
  },
  contacts: {
    title: 'Contacts',
    loading: "Loading client's contacts",
  },
  edit: {
    title: 'Edit {{ name }}',
    loading: 'Loading client',
    success: 'Client {{ name }} successfully updated',
    submit: 'Update client',
  },
};
