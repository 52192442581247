import { size } from 'lodash-es';
import React from 'react';

import { Fields, Label } from './RowFieldLayout';

const RowLabels = ({ fields }: { fields: JSX.Element[] }) => (
  <Fields columns={size(fields)}>
    {fields.map((field) => (
      <Label key={field.props.name}>{field.props.placeholder}</Label>
    ))}
  </Fields>
);

export default RowLabels;
