export const webhookModules = {
  title: 'webhook module',
  title_plural: 'webhook modules',
  fields: {
    id: 'ID',
    moduleName: 'Module name',
    methodName: 'Method name',
    version: 'Version',
    deprecationDate: 'Deprecation date',
    actions: 'Actions',
  },
};
