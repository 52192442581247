export const projects = {
  title: 'project',
  title_plural: 'projects',
  fields: {
    id: 'ID',
    isActive: 'Active',
    externalId: 'External ID',
    name: 'Name',
    email: 'Email',
    clientId: 'Client ID',
    clientName: 'Client',
    address: 'Address',
    contacts: 'Contacts',
    actions: 'Actions',
    companyId: 'Company ID',
    postcode: 'Postcode',
    suburb: 'Suburb',
    state: 'State',
  },
  contacts: {
    title: 'Contacts',
    loading: "Loading project's contacts",
  },
};
