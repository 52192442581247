import { Button, message } from 'antd';
import { get } from 'lodash-es';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthState } from 'types';

import { Form, IFormFunc, Input, SubmitButton } from '../../components';
import { signIn } from '../api';
import { signInValidationSchema } from '../validations';
import AuthPage from './AuthPage';
import { LoginWizardContext } from './LoginWizard';

interface ISignInFormValues {
  name: string;
  password: string;
}

const SignIn = () => {
  const { t } = useTranslation('auth');
  const loginWizard = useContext(LoginWizardContext);

  const handleSubmit = async (
    values: Partial<ISignInFormValues>,
    func: IFormFunc,
  ) => {
    try {
      const username = get(values, 'username');
      const password = get(values, 'password');
      if (username && password) {
        const res = await signIn(username, password);
        loginWizard.setState(res);
      }
    } catch (error) {
      message.error(error.message);
      func.setSubmitting(false);
    }
  };

  const handleOnClick = () =>
    loginWizard.setState({ authState: AuthState.ForgotPassword });

  return (
    <AuthPage title={t('SignIn.labels.title')}>
      <Form onSubmit={handleSubmit} validationSchema={signInValidationSchema}>
        <Input placeholder={t('SignIn.labels.username')} name="username" />
        <Input
          placeholder={t('SignIn.labels.password')}
          name="password"
          type="password"
        />
        <Button type="dashed" style={{ width: '100%' }} onClick={handleOnClick}>
          {t('SignIn.labels.forgotPassword')}
        </Button>
        <SubmitButton
          style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
        >
          {t('SignIn.labels.login')}
        </SubmitButton>
      </Form>
    </AuthPage>
  );
};

export default SignIn;
