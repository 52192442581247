import { Result } from 'antd';
import React from 'react';

import { LoadingIcon } from '../../icons';

const ImportLoading = () => (
  <Result
    icon={<LoadingIcon spin={true} />}
    style={{ fontSize: 64 }}
    title="Loading..."
    subTitle="Your import should be just about ready!"
  />
);

export default ImportLoading;
