export const components = {
  export: {
    loading: 'Exporting {{count}} {{ name }}...',
  },
  remove: {
    title: 'Confirm removal',
    content: 'Are you sure you want to remove {{count}} {{ name }}?',
    button: 'Remove',
    messages: {
      loading: 'Removing {{count}} {{name}}...',
      success: '{{count}} {{name}} successfully removed',
    },
  },
  Address: {
    fields: {
      address1: 'Address 1',
      address2: 'Address 2',
      city: 'City',
      state: 'State',
      postcode: 'Postcode',
      address: 'Address',
      suburb: 'Suburb',
    },
    labels: {
      title: 'Address',
      empty: 'N/A',
    },
  },
  AuditLogItemHeader: {
    labels: {
      payload: 'Payload',
    },
  },
  AuditLogItemDetails: {
    labels: {
      itemId: 'Item id: ',
      modifiedBy: 'Modified by: ',
      module: 'Module: ',
    },
  },
  Contact: {
    fields: {
      officePhone: 'Office phone',
      mobile: 'Mobile',
      email: 'Email',
      contact: 'Contact',
      emergencyContact: 'Emergency contact',
      emergencyContactName: 'Emergency contact name',
    },
    labels: {
      title: 'Contact information',
      empty: 'N/A',
    },
  },
  RequiredFields: {
    fields: {
      requireClient: 'Client',
      requireForTimesheets: 'Timesheets',
      requireGeolocation: 'Geolocation',
      requireMachine: 'Asset',
      requireProject: 'Project',
      requireSupplier: 'Supplier',
      requireWorker: 'Worker',
    },
    labels: {
      title: 'Required fields',
      empty: 'N/A',
    },
  },
  App: {
    fields: {
      appVersion: 'App version',
      deviceOs: 'Device OS',
      deviceOsVersion: 'Device OS version',
    },
    labels: {
      title: 'App',
      empty: 'N/A',
    },
  },
  Other: {
    fields: {
      comments: 'Comments',
    },
    labels: {
      title: 'Other',
      empty: 'N/A',
    },
  },
  ConfirmDeletionDialog: {
    labels: {
      title: 'Confirm {{ what }} deletion',
      placeholder: '{{ what }} ID to remove',
    },
    messages: {
      confirm: 'You are about to remove the {{ what }}',
    },
  },
  CopyToClipboard: {
    labels: {
      title: 'Copy to clipboard',
    },
    messages: {
      success: '{{ what }} copied to clipboard',
    },
  },
  ErrorBoundary: {
    labels: {
      title: 'Something went wrong!',
    },
  },
  ErrorPage: {
    labels: {
      button: 'Return',
    },
  },
  ExportButton: {
    labels: {
      export: 'Export',
    },
  },
  HeaderRow: {
    labels: {
      searchPlaceholder: 'Press enter to search...',
    },
  },
  ImportWarningDialog: {
    labels: {
      title: 'Import warning',
    },
  },
  NotFoundPage: {
    labels: {
      title: '404',
      subTitle: 'Page not found!',
    },
  },
  Page: {
    labels: {
      signOut: 'Sign out',
    },
  },
};
