export const suppliers = {
  title: 'supplier',
  title_plural: 'suppliers',
  fields: {
    id: 'ID',
    isActive: 'Active',
    name: 'Name',
    abn: 'ABN',
    externalId: 'External ID',
    actions: 'Actions',
  },
  contacts: {
    title: 'Contacts',
    loading: "Loading supplier's contacts",
  },
};
