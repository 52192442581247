import { Omit } from 'antd/es/_util/type';
import generatePicker, {
  PickerProps,
} from 'antd/es/date-picker/generatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { default as utc } from 'dayjs/plugin/utc';
import { Field, FieldProps } from 'formik';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import React, { Ref } from 'react';

import FieldHoc, { IFieldHocProps } from './components/FieldHoc';

dayjs.extend(utc);

export type IDatePickerProps<T> = IFieldHocProps &
  Omit<PickerProps<T>, 'picker'> & { ref?: Ref<any>; picker?: any };

function DatePicker<T>({ name, picker, ...props }: IDatePickerProps<T>) {
  const GeneratedPicker = generatePicker<any>(dayjsGenerateConfig);

  const formatFieldValue = (value: Dayjs) =>
    value ? dayjs.utc(value) : undefined;

  return (
    <Field name={name}>
      {({ field, form: { setFieldValue, setFieldTouched } }: FieldProps) => (
        <GeneratedPicker
          onChange={(value) => setFieldValue(name, formatFieldValue(value))}
          onBlur={() => setFieldTouched(name)}
          value={formatFieldValue(field.value)}
          style={{ width: '100%' }}
          picker={picker || 'date'}
          {...props}
        />
      )}
    </Field>
  );
}

export default FieldHoc(DatePicker) as typeof DatePicker;
