import { UploadFile } from 'antd/lib/upload/interface';

export const readAsBinaryString = <T extends {}>(
  inputFile: UploadFile,
): Promise<string | ArrayBuffer> => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    fileReader.onload = () => {
      if (fileReader.result) {
        resolve(fileReader.result);
      }
    };

    fileReader.readAsBinaryString(inputFile as any);
  });
};
