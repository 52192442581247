import React from 'react';
import { useTranslation } from 'react-i18next';

import ErrorPage from './ErrorPage';

interface IState {
  hasError: boolean;
  error?: any;
}

class ErrorBoundary extends React.Component<unknown, IState> {
  constructor(props: { [k: string]: any }) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidCatch(error: any) {
    this.setState({
      hasError: true,
      error: {
        message: error.message,
        stack: error.stack,
        date: new Date(),
        environment: process.env.REACT_APP_STAGE,
      },
    });
  }

  public onClick = () => {
    window.location.href = '/';
  };

  public render() {
    if (this.state.hasError) {
      return (
        <ErrorPageWrapper onClick={this.onClick} error={this.state.error} />
      );
    }

    return this.props.children;
  }
}

const ErrorPageWrapper = ({
  onClick,
  error,
}: {
  onClick: () => void;
  error: any;
}) => {
  const { t } = useTranslation('components');

  return (
    <ErrorPage
      title={t('ErrorBoundary.labels.title')}
      error={error}
      onButtonClick={onClick}
    />
  );
};

export default ErrorBoundary;
