import { Drawer, Layout as AntdLayout } from 'antd';
import React from 'react';

import Menu from './Menu';

interface IProps {
  isMenuCollapsed: boolean;
  isDrawerVisible: boolean;
  onBreakpoint: (broken: boolean) => void;
  onMenuItemClick: (route: string) => void;
  onDrawerClose: () => void;
}

const Sider = ({
  isDrawerVisible,
  isMenuCollapsed,
  onBreakpoint,
  onMenuItemClick,
  onDrawerClose,
}: IProps) => (
  <>
    {isMenuCollapsed && (
      <Drawer
        bodyStyle={{ padding: 0 }}
        placement="right"
        closable={true}
        onClose={onDrawerClose}
        visible={isDrawerVisible}
      >
        <Menu theme="light" onClick={onMenuItemClick} />
      </Drawer>
    )}
    <AntdLayout.Sider
      collapsed={true}
      trigger={null}
      breakpoint="sm"
      theme="dark"
      collapsedWidth={isMenuCollapsed ? 0 : undefined}
      onBreakpoint={onBreakpoint}
    >
      <Menu theme="dark" onClick={onMenuItemClick} />
    </AntdLayout.Sider>
  </>
);

export default Sider;
