import { Button, Table } from 'antd';
import { size } from 'lodash-es';
import React from 'react';
import styled from 'styled-components';

import { RightIcon } from '../../icons';

const ButtonWrapper = styled.div`
  display: grid;
  justify-content: flex-end;
  grid-template-columns: 100px 100px auto;
  grid-column-gap: 10px;
`;

interface IProps<T> {
  data: T[];
  onCancel: () => void;
  onComplete: (data: T[]) => void;
  tableColumns: object[];
}

type DataSourceItem<T> = T & { id: number };
type DataSource<T> = DataSourceItem<T>[];

function Overview<T>({ data, onCancel, onComplete, tableColumns }: IProps<T>) {
  const dataSource: DataSource<T> = data.map((d, index) => ({
    ...d,
    id: index,
  }));

  const handleComplete = () => onComplete(data);

  return (
    <>
      <Table rowKey="id" columns={tableColumns} dataSource={dataSource} />
      <ButtonWrapper>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          disabled={size(data) === 0}
          onClick={handleComplete}
          type="primary"
        >
          Continue
          <RightIcon />
        </Button>
      </ButtonWrapper>
    </>
  );
}

export default Overview;
