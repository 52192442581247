import Auth from '@aws-amplify/auth';
import { Menu as AntdMenu } from 'antd';
import { MenuTheme } from 'antd/es/menu/MenuContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { LogoutIcon } from '..';
import { AuthContext } from '../../auth/AuthWrapper';
import { isAuthorized } from '../../auth/utils';
import { IMenuItem, menuItems } from './menuItems';

export const Logo = styled.div`
  ${(props: { src: any }) => `
    background-image: url(${props.src});
    height: 25px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 10px;
  `};
`;

interface IProps extends RouteComponentProps {
  theme: MenuTheme;
  onClick: (route: string) => void;
}

const Menu = ({ theme, location, onClick }: IProps) => {
  const { t } = useTranslation('components');
  const { auth } = useContext(AuthContext);

  const selected = menuItems.find((menuItem: IMenuItem) =>
    location.pathname.startsWith(menuItem.route),
  );

  const signOut = () => {
    Auth.signOut().then(() => (window.location.href = '/'));
  };

  return (
    <>
      <Logo src={theme === 'dark' ? './logo-light.png' : './logo-dark.png'} />
      <AntdMenu
        style={{ border: 0 }}
        theme={theme}
        selectedKeys={selected ? [selected.route] : []}
      >
        {menuItems.map(({ allowedRoles, route, icon, label }: IMenuItem) => {
          if (!allowedRoles || isAuthorized(auth, allowedRoles)) {
            return (
              <AntdMenu.Item key={route} onClick={() => onClick(route)}>
                {icon}
                <span>{label}</span>
              </AntdMenu.Item>
            );
          }

          return null;
        })}
      </AntdMenu>
      <AntdMenu
        theme={theme}
        style={{ bottom: 0, position: 'absolute', border: 0, width: '100%' }}
      >
        <AntdMenu.Item key="sign-out" onClick={signOut}>
          <LogoutIcon />
          <span>{t('Page.labels.signOut')}</span>
        </AntdMenu.Item>
      </AntdMenu>
    </>
  );
};

export default withRouter(Menu);
