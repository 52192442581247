import { Table as AntTable } from 'antd';
import { TableProps } from 'antd/lib/table';
import React from 'react';
import styled from 'styled-components';

import { Spin } from '.';

interface IProps<T> extends TableProps<T> {
  loading: boolean;
}

const Wrapper = styled.div`
  .ant-table-body {
    overflow: scroll;
  }
`;

function Table<T extends { [k: string]: any }>(props: IProps<T>) {
  const isLoading = props.loading;

  return (
    <Wrapper>
      <AntTable<T>
        size={props.size || 'small'}
        {...props}
        loading={{
          indicator: <Spin />,
          spinning: isLoading,
        }}
      />
    </Wrapper>
  );
}

export default Table;
