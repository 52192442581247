import Auth from '@aws-amplify/auth';
import { get } from 'lodash-es';
import React from 'react';

import { Loader, useBoolean } from '../components';

export interface IAuth {
  isAuthenticated: boolean;
  group?: string;
}

const authDefault: IAuth = {
  isAuthenticated: false,
};

interface IAuthContextDefault {
  auth: IAuth;
  setAuth: (a: IAuth) => void;
}

const authContextDefault: IAuthContextDefault = {
  auth: authDefault,
  setAuth: () => null,
};

export const AuthContext = React.createContext(authContextDefault);

const AuthWrapper = ({ children }: any) => {
  const isLoading = useBoolean(true);
  const [auth, setAuth] = React.useState(authDefault);

  React.useEffect(() => {
    Auth.currentSession()
      .then((s) => {
        setAuth({
          isAuthenticated: true,
          group: get(s.getIdToken(), 'payload.cognito:groups[0]'),
        });
      })
      .finally(isLoading.setFalse);
  }, [isLoading.setFalse]);

  if (isLoading.value) {
    return <Loader fullScreen={true} size="large" message="Logging in..." />;
  }

  return (
    <AuthContext.Provider value={{ auth, setAuth: (a: IAuth) => setAuth(a) }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthWrapper;
