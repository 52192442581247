import { customers } from './customers';

export const templates = {
  ...customers,
  total: 'Total templates:',
  title: 'Templates',
  create: {
    title: 'Create new template',
    submit: 'Create template',
    messages: {
      success: 'Customer successfully created',
      pending: 'Creating...',
    },
  },
  edit: {
    title: 'Update template {{id}}',
    submit: 'Update template',
    messages: {
      success: 'Template {{ id }} successfully updated',
    },
  },
};
