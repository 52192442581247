export const assetGPSDevices = {
  title: 'asset GPS device',
  title_plural: 'asset GPS devices',
  fields: {
    id: 'ID',
    imei: 'IMEI',
    sim: 'SIM',
    vehicleId: 'Vechicle ID',
    modifiedAt: 'Modified',
    iccid: 'ICCID',
    deviceType: 'Device Type',
    deviceTypeMapper: 'Device Type Mapper',
    isActive: 'Active',
    actions: 'Actions',
  },
  create: {
    button: 'Create',
    title: 'Create new asset gps device',
    submit: 'Create',
    success: 'Asset gps device {{imei}} successfully created',
  },
  edit: {
    loading: 'Loading asset gps device',
    title: 'Edit {{ imei }}',
    submit: 'Update asset gps device',
    success: 'Asset gps device {{imei}} successfully updated',
  },
};
