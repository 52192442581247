import { Alert } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface IProps {
  message: string;
  description?: string;
}

const Wrapper = styled.div`
  padding: 10px;
`;

const ErrorMessage = ({ message, description }: IProps) => (
  <Wrapper>
    <Alert
      message={message}
      description={description}
      type="error"
      showIcon={true}
    />
  </Wrapper>
);

export default ErrorMessage;
