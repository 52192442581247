export interface ITwilioFleet {
  account_sid: string;
  sid: string;
  unique_name: string;
  date_created: Date;
  date_updated: Date;
  url: string;
  data_enabled: boolean;
  data_limit: number;
  data_metering: string;
  commands_enabled: boolean;
  commands_url: string;
  commands_method: string;
  sms_commands_enabled: boolean;
  sms_commands_url: string;
  sms_commands_method: string;
  network_access_profile_sid: string;
}

export interface ITwilioFleetCreate {
  NetworkAccessProfile: string;
  UniqueName: string;
  DataEnabled: boolean;
  DataLimit: number;
  CommandsEnabled: boolean;
  CommandsUrl: string;
  CommandsMethod: string;
  SmsCommandsEnabled: boolean;
  SmsCommandsUrl: string;
  SmsCommandsMethod: string;
}

export interface ITwilioIoTMeta {
  page: number;
  page_size: number;
  first_page_url: string;
  previous_page_url: string;
  url: string;
  next_page_url: string;
  key: string;
}

export interface ITwilioFleets {
  fleets: ITwilioFleet[];
  meta: ITwilioIoTMeta;
}

export interface ITwilioNetworkAccessProfileLinks {
  networks: string[];
}

export interface ITwilioNetworkAccessProfile {
  sid: string;
  unique_name: string;
  account_sid: string;
  date_created: Date;
  date_updated: Date;
  url: string;
  links: ITwilioNetworkAccessProfileLinks;
}

export interface ITwilioNetworkAccessProfiles {
  network_access_profiles: ITwilioNetworkAccessProfile[];
  meta: ITwilioIoTMeta;
}

export enum TwilioSimStatus {
  ready = 'ready',
  active = 'active',
  inactive = 'inactive',
}

export interface ITwilioSimUpdate {
  SimSid: string;
  FleetSid: string;
  SimStatus: TwilioSimStatus;
}

export interface ITwilioSimLinks {
  billing_periods: string;
}

export interface ITwilioSim {
  status: TwilioSimStatus;
  unique_name: string;
  date_updated: Date;
  account_sid: string;
  fleet_sid: string;
  url: string;
  date_created: string;
  sid: string;
  iccid: string;
  links: ITwilioSimLinks;
}

export interface ITwilioSims {
  sims: ITwilioSim[];
  meta: ITwilioIoTMeta;
}
