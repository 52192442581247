import { getIn, useFormikContext } from 'formik';
import { isString } from 'lodash-es';
import React from 'react';
import styled from 'styled-components';

const Message = styled.div`
  color: red;
`;

interface IProps {
  name: string;
}

const ErrorMessage = ({ name }: IProps) => {
  const form = useFormikContext();
  const error = getIn(form.errors, name);

  return error && isString(error) ? <Message>{error}</Message> : null;
};

export default ErrorMessage;
