import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';

import ErrorMessage from './ErrorMessage';

export interface IFieldIsVisible {
  name: string;
  values: any;
}

export interface IFieldHocProps {
  name: string;
  placeholder?: string;
  isVisible?: ({ name, values }: IFieldIsVisible) => boolean;
}

const Wrapper = styled.div`
  width: 100%;
  padding: 5px 0;
`;

const Label = styled.label`
  color: #555;
`;

const ComponentWrapper = styled.div`
  margin: 7px 0;
`;

const FieldHoc = function <P>(Component: React.ComponentType<P>) {
  return function Comp(p: P & IFieldHocProps) {
    const form = useFormikContext();
    const { isVisible, ...props } = p;
    const shouldRender =
      isVisible && isVisible({ name: p.name, values: form.values });

    if (shouldRender || !isVisible) {
      return (
        <Wrapper>
          {props.placeholder && <Label>{props.placeholder}: </Label>}
          <ComponentWrapper>
            <Component {...(props as P)} />
          </ComponentWrapper>
          <ErrorMessage name={props.name} />
        </Wrapper>
      );
    }

    return null;
  };
};

export default FieldHoc;
