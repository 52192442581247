import { message } from 'antd';
import { get } from 'lodash-es';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthState } from 'types';

import { Form, IFormFunc, Input } from '../../components';
import { forgotPasswordSubmit } from '../api';
import { forgotPasswordSubmitValidationSchema } from '../validations';
import AuthPage from './AuthPage';
import AuthPageActions from './AuthPageActions';
import { LoginWizardContext } from './LoginWizard';

interface IForgotPasswordFormValues {
  code: string;
  password: string;
}

const ForgotPasswordSubmit = () => {
  const { t } = useTranslation('auth');
  const loginWizard = useContext(LoginWizardContext);

  const handleSubmit = async (
    values: IForgotPasswordFormValues,
    func: IFormFunc,
  ) => {
    try {
      const { code, password } = values;

      await forgotPasswordSubmit({
        username: get(loginWizard, 'forgottenPassword.username'),
        token: code,
        password,
      });

      loginWizard.setState({ authState: AuthState.SignIn });
      message.success(t('ForgotPasswordSubmit.messages.success'));
    } catch (e) {
      message.error(e.message);
    } finally {
      func.setSubmitting(false);
    }
  };

  return (
    <AuthPage
      title={t('ForgotPasswordSubmit.labels.title')}
      subtitle={`Verification code was sent to: ${get(
        loginWizard,
        'forgottenPassword.email',
      )}`}
    >
      <Form
        onSubmit={handleSubmit}
        validationSchema={forgotPasswordSubmitValidationSchema}
      >
        <Input
          name="code"
          placeholder={t('ForgotPasswordSubmit.labels.code')}
        />
        <Input
          type="password"
          name="password"
          placeholder={t('ForgotPasswordSubmit.labels.password')}
        />
        <AuthPageActions />
      </Form>
    </AuthPage>
  );
};

export default ForgotPasswordSubmit;
