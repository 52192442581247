import { Button, List, Modal, Result } from 'antd';
import { size } from 'lodash-es';
import React from 'react';

import { useBoolean } from '../../hooks';
import { IImportWizardError } from '../ImportWizard';

interface IProps {
  onSubmit: () => void;
  error: IImportWizardError;
}

const ResultError = ({ onSubmit, error }: IProps) => {
  const isVisible = useBoolean(false);

  return (
    <>
      <Result
        status="error"
        title="Import failed"
        subTitle={error.message}
        extra={[
          ...(size(error.errors)
            ? [<Button onClick={isVisible.setTrue}>Show error log</Button>]
            : []),
          <Button type="primary" key="console" onClick={onSubmit}>
            Retry
          </Button>,
        ]}
      />
      <Modal
        visible={isVisible.value}
        footer={null}
        onCancel={isVisible.setFalse}
        style={{ top: 20 }}
        width="80%"
      >
        <List
          size="small"
          bordered={true}
          dataSource={error.errors}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
      </Modal>
    </>
  );
};

export default ResultError;
