import { saveAs } from 'file-saver';
import { isObject, zipObject } from 'lodash-es';
import { ExportFormat } from 'types';
import * as XLSX from 'xlsx';

// removes store fields like __typename
const serializeData = (list: any[]): any[] =>
  list.map((item: any) =>
    zipObject(
      Object.keys(list[0]).filter((k: string) => k !== '__typename'),
      Object.values(item).map((val: any) =>
        // @ts-ignore
        isObject(val) && val !== null ? val.name : val,
      ),
    ),
  );

// generates blob from workbook object
const s2ab = (s: any) => {
  const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
  const view = new Uint8Array(buf); // create uint8array as viewer
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  } // convert to octet

  return buf;
};

export const exportToFile = <T>({
  fileName,
  format,
  data,
}: {
  data: T[];
  fileName: string;
  format?: ExportFormat;
}) => {
  if (format === ExportFormat.Json) {
    generateJson<T>({ data, fileName });
  } else {
    generateSheet<T>({ data, fileName, bookType: format });
  }
};

const generateJson = <T>({
  data,
  fileName,
}: {
  data: T[];
  fileName: string;
}) => {
  const serialized = serializeData(data);
  saveAs(
    new Blob([JSON.stringify(serialized)], { type: 'application/json' }),
    `${fileName}.json`,
  );
};

const generateSheet = <T>({
  data,
  fileName,
  bookType = ExportFormat.Xlsx,
}: {
  data: T[];
  fileName: string;
  bookType?: XLSX.BookType;
}) => {
  const serialized = serializeData(data);

  // create workbook and fill with data
  const book = XLSX.utils.book_new();
  const sheet = XLSX.utils.json_to_sheet(serialized, {
    header: Object.keys(serialized[0]),
  });
  XLSX.utils.book_append_sheet(book, sheet, 'export');
  // convert workbook to a proper xlsx file binary
  const FileData = XLSX.write(book, { bookType, type: 'binary' });
  // trigger download
  saveAs(
    new Blob([s2ab(FileData)], { type: 'application/octet-stream' }),
    `${fileName}.${bookType}`,
  );
};
