import { Button, Dropdown, Menu } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import React from 'react';
import { ExportFormat } from 'types';

import { ExportIcon } from '../../icons';
import { IExportButton } from './ExportButton';

interface IProps<T> {
  isLoading?: boolean;
  count: IExportButton<T>['count'];
  label: IExportButton<T>['label'];
  onClick: (e: ExportFormat) => void;
}

const formats = [
  ExportFormat.Csv,
  ExportFormat.Xlsx,
  ExportFormat.Json,
  ExportFormat.Ods,
];

function ExportButtonDropdown<T>({
  isLoading,
  count,
  onClick,
  label,
}: IProps<T>) {
  const handleClick = (e: MenuInfo) => onClick(e.key as ExportFormat);

  return (
    <Dropdown
      disabled={!count}
      overlay={
        <Menu onClick={handleClick}>
          {formats.map((format) => (
            <Menu.Item key={format}>{format.toUpperCase()}</Menu.Item>
          ))}
        </Menu>
      }
    >
      <Button loading={isLoading}>
        <ExportIcon />
        {label}
      </Button>
    </Dropdown>
  );
}

export default ExportButtonDropdown;
