export const employmentTypes = {
  title: 'employment type',
  title_plural: 'employment types',
  fields: {
    id: 'ID',
    name: 'Name',
    actions: 'Actions',
  },
  edit: {
    title: 'Edit {{ name }}',
    loading: 'Loading employment type',
    success: 'Employment type {{ name }} successfully updated',
    submit: 'Update employment type',
  },
};
