import { Button, Result } from 'antd';
import React from 'react';

import { CheckIcon, HomeIcon } from '../../icons';

interface IProps {
  onSubmit: () => void;
}

const ResultSuccess = ({ onSubmit }: IProps) => (
  <Result
    status="success"
    icon={<CheckIcon style={{ fontSize: 64 }} />}
    title="You did it!"
    subTitle="Your import is complete! Click below to return to the table."
    extra={[
      <Button type="primary" key="console" onClick={onSubmit}>
        <HomeIcon />
        Return
      </Button>,
    ]}
  />
);

export default ResultSuccess;
