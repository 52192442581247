import { Divider, Drawer as AntDrawer, Typography } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import React from 'react';
import styled from 'styled-components';

const { Text } = Typography;

interface IProps extends DrawerProps {
  children: JSX.Element;
  title?: JSX.Element | string;
}

export const DrawerFooter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
`;

export const DrawerContent = styled.div`
  ${(props: { hasTitle?: boolean }) => `
    width: 100%;
    height: 100%;
    max-height: calc(100vh - ${props.hasTitle ? '135px' : '80px'});
    overflow: scroll;
`}
`;

export const DrawerTitle = ({ title }: { title?: JSX.Element | string }) => (
  <>
    <Text strong={true}>{title}</Text>
    <Divider style={{ margin: '14px 0' }} />
  </>
);

const Drawer = ({ children, width, title, ...props }: IProps) => {
  const hasTitle = !!title;

  return (
    <AntDrawer
      {...props}
      destroyOnClose={true}
      title={hasTitle && <DrawerTitle title={title} />}
      closable={false}
      width={width ?? '50%'}
    >
      {children}
    </AntDrawer>
  );
};

export default Drawer;
