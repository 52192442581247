import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { array, object, Schema } from 'yup';

import { Form, IFormFunc, ListField, RightIcon, SubmitButton } from '../..';

const ButtonWrapper = styled.div`
  display: grid;
  justify-content: flex-end;
  grid-template-columns: 100px 100px auto;
  grid-column-gap: 10px;
`;

interface IProps<T> {
  invalidData: T[];
  validation: Schema<T>;
  onCancel: () => void;
  onSubmit: (data: T[]) => void;
  formFields: any;
}

function ResolveInvalid<T>({
  invalidData,
  validation,
  formFields,
  onCancel,
  onSubmit,
}: IProps<T>) {
  const handleSubmit = async (result: { data: T[] }, func: IFormFunc) => {
    func.setSubmitting(false);
    onSubmit(result.data);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validationSchema={object({ data: array(validation) })}
      initialValues={{ data: invalidData }}
    >
      <ListField
        placeholder="Resolve invalid data"
        name="data"
        fields={formFields}
      />
      <ButtonWrapper>
        <Button onClick={onCancel}>Cancel</Button>
        <SubmitButton>
          Continue
          <RightIcon />
        </SubmitButton>
      </ButtonWrapper>
    </Form>
  );
}

export default ResolveInvalid;
