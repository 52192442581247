import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';

export const getDateFormat = () => dayjs().localeData().longDateFormat('L');

export const initDayjs = () => {
  dayjs.extend(localizedFormat);
  dayjs.extend(localeData);
  dayjs.extend(utc);
  dayjs.extend(customParseFormat);

  switch (window.navigator.language?.toLowerCase()) {
    case 'cs':
    case 'cs-cz':
      dayjs.locale('cs');
      break;
    case 'en-au':
      dayjs.locale('en-au');
      break;
    default:
      dayjs.locale('en');
      break;
  }
};
