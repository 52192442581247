import React from 'react';
import styled from 'styled-components';

interface IProps {
  children: JSX.Element;
  title: string;
  subtitle?: string;
}

const Wrapper = styled.div`
  grid-template-rows: 100px auto 100px;
  display: grid;
  height: 100vh;
  background: radial-gradient(#00284d, #001529);
`;

const ContentWrapper = styled.div`
  align-self: center;
  justify-self: center;
`;

const Content = styled.div`
  box-shadow: 0px 0px 5px #fff;
  padding: 20px 20px 5px 20px;
  margin-top: 10px;
  width: 250px;
  background-color: #fff;
`;

const Title = styled.h1`
  font-size: 20px;
  margin-bottom: 0;
  color: #fff;
`;

const Subtitle = styled.h2`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
  color: #fff;
`;

const Logo = styled.img`
  width: 90px;
  margin: 10px;
`;

const AuthPage = ({ children, title, subtitle }: IProps) => (
  <Wrapper>
    <Logo src="./logo-light-full.png" />
    <ContentWrapper>
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <Content>{children}</Content>
    </ContentWrapper>
  </Wrapper>
);

export default AuthPage;
