export const auth = {
  fields: {
    username: 'Username',
    password: 'Password',
    code: 'Code',
  },
  AuthPageActions: {
    labels: {
      login: 'Sign In',
      confirm: 'Confirm',
    },
  },
  ConfirmSignIn: {
    labels: {
      title: 'Authentication code',
      code: 'Code',
    },
  },
  ForgotPassword: {
    labels: {
      title: 'Forgotten password',
      username: 'Username',
    },
  },
  ForgotPasswordSubmit: {
    labels: {
      title: 'Set new password',
      password: 'New password',
      code: 'Verification code',
    },
    messages: {
      success: 'Password successfully changed.',
    },
  },
  MFASetup: {
    labels: {
      title: 'MFA setup required',
      code: 'Code',
    },
  },
  SignIn: {
    labels: {
      title: 'Sign in to Admin Dashboard',
      username: 'Username',
      password: 'Password',
      login: 'Login',
      forgotPassword: 'Forgot password?',
    },
  },
  SetNewPassword: {
    labels: {
      title: 'Please set a new password',
      password: 'New Password',
    },
    messages: {
      success: 'Password successfully set.',
    },
  },
};
