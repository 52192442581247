import { Button } from 'antd';
import { get, size } from 'lodash-es';
import React from 'react';
import styled from 'styled-components';

import { DeleteIcon } from '../../../icons';
import renderFields from '../utils/renderFields';
import RowLabels from './RowLabels';

interface IProps<T> {
  onRemove: (index: number) => void;
  fields: JSX.Element[];
  index: number;
  name: string;
}

export const Label = styled.div`
  font-weight: 500;
  margin-left: 3px;
`;

export const Fields = styled.div`
  display: grid;
  grid-column-gap: 10px;
  align-items: baseline;

  label {
    display: none;
  }

  ${(props: { columns: number }) => `
    grid-template-columns: repeat(${get(props, 'columns')}, 1fr) 32px;
  `};
`;

const RowFieldLayout = function <T>({
  index,
  onRemove,
  fields,
  name,
}: IProps<T>) {
  const handleClick = () => onRemove(index);
  const isFirstRow = !index;

  return (
    <>
      {isFirstRow && <RowLabels fields={fields} />}
      <Fields columns={size(fields)}>
        {renderFields<T>({ fields, index, name })}
        <Button
          key={`${index}-remove`}
          shape="circle"
          icon={<DeleteIcon />}
          onClick={handleClick}
          ghost={true}
          danger
        />
      </Fields>
    </>
  );
};

export default RowFieldLayout;
