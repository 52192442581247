import { message } from 'antd';
import { get } from 'lodash-es';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthState } from 'types';

import { Form, IFormFunc, Input } from '../../components';
import { forgotPassword } from '../api';
import { forgotPasswordValidationSchema } from '../validations';
import AuthPage from './AuthPage';
import AuthPageActions from './AuthPageActions';
import { LoginWizardContext } from './LoginWizard';

interface IForgotPasswordFormValues {
  username: string;
}

const ForgotPassword = () => {
  const { t } = useTranslation('auth');
  const loginWizard = useContext(LoginWizardContext);

  const handleSubmit = async (
    values: IForgotPasswordFormValues,
    func: IFormFunc,
  ) => {
    try {
      const { username } = values;
      const response = await forgotPassword(username);

      loginWizard.setState({
        forgottenPassword: {
          username,
          email: get(response, 'CodeDeliveryDetails.Destination'),
        },
        authState: AuthState.ForgotPasswordSubmit,
      });
    } catch (error) {
      message.error(error.message);
    } finally {
      func.setSubmitting(false);
    }
  };

  return (
    <AuthPage title={t('ForgotPassword.labels.title')}>
      <Form
        onSubmit={handleSubmit}
        validationSchema={forgotPasswordValidationSchema}
      >
        <Input
          placeholder={t('ForgotPassword.labels.username')}
          name="username"
        />
        <AuthPageActions />
      </Form>
    </AuthPage>
  );
};

export default ForgotPassword;
