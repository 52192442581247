export const common = {
  messages: {
    sure: 'Are you sure?',
  },
  labels: {
    at: 'at',
    clickToUpload: 'Click to Upload',
    yes: 'Yes',
    no: 'No',
    all: 'All',
    cancel: 'Cancel',
    edit: 'Edit',
    archive: 'Archive',
    activate: 'Activate',
  },
  test: 'Test',
  activeCount: '{{count}} active',
  itemWithCount: '<strong> {{count}} item</strong>',
  itemWithCount_plural: '<strong> {{count}} items</strong>',
  validation: {
    required: 'This field is required',
    maxLength: 'Maximal length is {{ length }} characters!',
    idPattern:
      'Only lowercase letters, numbers, dashes and underscores allowed!',
    duplicate: 'This value already exists',
    email: 'This is not a valid email address',
    assignarEmail: "This is not valid '@assignar.com' email!",
  },
  select: 'Select $t({{name}})',
  import: 'Import',
  export: 'Export',
  create: 'Create',
  update: 'Update',
};
