import i18next from 'i18next';
import * as Yup from 'yup';

const usernameValidationSchema = Yup.string().required(
  i18next.t('validation.required'),
);
const passwordValidationSchema = Yup.string().required(
  i18next.t('validation.required'),
);
const codeValidationSchema = Yup.string().required(
  i18next.t('validation.required'),
);

export const signInValidationSchema = Yup.object().shape({
  username: usernameValidationSchema,
  password: passwordValidationSchema,
});

export const forgotPasswordValidationSchema = Yup.object().shape({
  username: usernameValidationSchema,
});

export const forgotPasswordSubmitValidationSchema = Yup.object().shape({
  code: codeValidationSchema,
  password: passwordValidationSchema,
});

export const setNewPasswordValidationSchema = Yup.object().shape({
  password: passwordValidationSchema,
});

export const MFASetupValidationSchema = Yup.object().shape({
  code: codeValidationSchema,
});

export const confirmSignInValidationSchema = Yup.object().shape({
  code: codeValidationSchema,
});
