export const inductions = {
  title: 'induction',
  title_plural: 'inductions',
  fields: {
    id: 'ID',
    name: 'Name',
    clientId: 'Client ID',
    clientName: 'Client',
    projectId: 'Project ID',
    projectName: 'Project',
    requiresExpirationDate: 'Requires expiration date',
    requiresIssueDate: 'Requires issue date',
    actions: 'Actions',
    required: 'Requires',
  },
  tags: {
    issueDate: 'Issue Date',
    expirationDate: 'Exp. Date',
  },
  users: {
    title: 'User inductions',
    add: 'Add User',
    save: 'Update linked users',
    fields: {
      title: 'Users linked to {{ name }}',
      userId: 'Select user',
      issueDate: 'Issue date',
      expirationDate: 'Expiration date',
    },
    messages: {
      loading: 'Loading users linked to induction',
      success: 'List of users with {{ name }} induction updated',
      empty: 'No inductions linked to this user.',
    },
  },
};
