import 'antd/dist/antd.css';
import 'dayjs/locale/cs';
import 'dayjs/locale/en';
import 'dayjs/locale/en-au';
import './app.css';
import './i18n';

import Auth from '@aws-amplify/auth';
import React from 'react';
import ReactDOM from 'react-dom';

import { ApolloClientWrapper } from './apollo/ApolloClientWrapper';
import AuthWrapper from './auth/AuthWrapper';
import { ErrorBoundary } from './components';
import Router from './router/Router';
import { initDayjs } from './utils/date';

Auth.configure({
  mandatorySignIn: true,
  region: process.env.REACT_APP_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
});

initDayjs();

ReactDOM.render(
  <ErrorBoundary>
    <AuthWrapper>
      <ApolloClientWrapper>
        <Router />
      </ApolloClientWrapper>
    </AuthWrapper>
  </ErrorBoundary>,
  document.getElementById('root'),
);
