export const assets = {
  title: 'asset',
  title_plural: 'assets',
  fields: {
    isActive: 'Active',
    id: 'ID',
    externalId: 'External ID',
    name: 'Name',
    description: 'Description',
    regNumber: 'Rego',
    model: 'Model',
    make: 'Make',
    year: 'Year',
    attachments: 'Attachments',
    serialNumber: 'Serial number',
    actions: 'Actions',
  },
  attachments: {
    title: 'Attachments',
    save: 'Update attachments',
    add: 'Add attachment',
    fields: {
      title: 'Edit {{name}} attachments',
      attachmentId: 'Select attachment',
    },
    messages: {
      loading: 'Loading asset attachments',
      success: "{{ name }}'s attachments successfully updated",
    },
  },
  create: {
    button: 'Create',
    title: 'Create new asset',
    submit: 'Create',
    success: 'Asset {{ name }} successfully created',
  },
  edit: {
    title: 'Edit {{ name }}',
    loading: 'Loading asset',
    success: 'Asset {{ name }} successfully updated',
    submit: 'Update asset',
  },
};
