export const customers = {
  title: 'Customers',
  fields: {
    salesforceID: 'Salesforce ID',
    accountOwner: 'Account Owner',
    actions: 'Actions',
    expirationDateTime: 'Expiration date',
    isActive: 'Active',
    isTemplate: 'Template',
    templateId: 'Template ID',
    id: 'Customer ID',
    isTwilioAdded: 'Phone number assigned',
    isInsightsEnabled: 'Insights enabled',
    loginId: 'Login ID',
    name: 'Name',
    subscription: 'Subscription',
    smsLimit: 'SMS limit',
    smsUsage: 'SMS usage',
    smsUsageSubTitle: 'Monthly usage / limit',
    smsQuota: 'SMS quota',
    userQuota: 'User Quota',
    insightsCreatorUsage: 'Insights Creator Usage',
    insightsCreatorUsageSubTitle: 'Used / Allocated',
    insightsCreatorAllocatedSeats: 'Insights Creator Seats Quota',
    insightsCreatorUsers: 'Insights Creator Users',
    insightsViewerUsage: 'Insights Viewer Usage',
    insightsViewerUsageSubTitle: 'Used / Allocated',
    insightsViewerAllocatedSeats: 'Insights Viewer Seats Quota',
    insightsViewerUsers: 'Insights Viewer Users',
    isCustomerMessageEnabled: 'Turn on Customer Message',
    customerMessage: 'Message',
    isGPSApplicationAdded: 'GPS Gate Enabled',
  },
  disable: {
    title: 'Disable {{ name }}',
    body: 'Customer will be disabled. Once disabled, login to the customer is not possible but you can still edit customer data through Admin Dashboard. Only disabled customers can be removed.',
  },
  enable: {
    title: 'Enable  {{ name }}',
    body: 'Customer will be enabled, Once enabled, it is possible to login to the customer.',
  },
  actions: {
    settings: 'Settings',
    data: 'Data',
    remove: 'Remove',
    disable: 'Disable',
    enable: 'Enable',
    twilio: 'Phone number',
    flags: 'Flags',
    gps: 'GPS Gate',
  },
  create: {
    title: 'Create customer',
    submit: 'Create customer',
    messages: {
      success: 'Customer successfully created',
      pending: 'Creating...',
    },
  },
  edit: {
    title: 'Update customer {{id}}',
    submit: 'Update customer',
    messages: {
      success: 'Customer {{ id }} successfully updated',
    },
  },
  flags: {
    title: 'Product Flags for customer {{id}}',
    insightsLabel: 'Insights Flag Enabled',
    submit: 'Update Product Flags',
    messages: {
      success: 'Product Flags for customer {{ id }} successfully updated',
      error:
        'Product Flags for customer {{ id }} have not been updated, please check rollout.io',
      loading: 'Loading Product Flags',
    },
  },
  twilio: {
    title: 'Edit phone number settings',
    submit: 'Update phone number',
    updateSmsLimit: 'Update SMS limit',
    release: 'Release phone number',
    assign: 'Assign new phone number',
    messages: {
      loading: "Loading {{ customerId }}'s phone number settings",
      success:
        'Phone number settings for {{ customerId }} successfully updated',
    },
  },
  gps: {
    title: 'Edit GPS settings',
    submit: {
      attach: 'Attach GPS Apps',
      create: 'Create & Attach GPS Apps',
      changeStatus: 'Update Status',
    },
    messages: {
      success: 'GPS application for {{ customerId }} successfully updated',
      detach: {
        title: 'Detach GPS Apps from customer?',
        confirmation:
          'Are you sure you want to detach GPS apps from {{customerName}}?',
        detaching: 'Detaching GPS Apps...',
        success: 'GPS Apps successfully detached from customer {{ id }}',
      },
      loading: 'Loading GPS Applications...',
    },
    application: 'GPS Application',
    templateId: 'GPS Application Template',
    name: 'GPS Application Name',
    description: 'GPS Application Description',
    culture: 'Culture',
    language: 'Language',
    measurement: 'Measurement',
    timeZone: 'Time Zone',
    errorDialogTitle: 'Error Occurred',
    gpsApplicationloading: 'Loading GPS Application...',
    deviceType: 'GPS Device Type',
    deviceTypeMapper: 'GPS Device Type Mapper',
    twilioIoT: {
      twilioNetworkAccessProfileId: 'Twilio Network Access Profile',
      twilioFleet: 'Twilio Fleet',
      twilioFleetName: 'Twilio Fleet Name',
      twilioFleetDataLimit: 'Twilio Fleet Data Limit',
      twilioFleetLoading: 'Loading Twilio Fleet',
      iccid: 'Twilio ICCID',
      imei: 'Device IMEI',
    },
    device: {
      createLoading: `Retrieving customer's GPS and Twilio detail...`,
      noGPSAssociation: 'Customer does not have an associated GPS Application',
      submit: {
        create: 'Create GPS Device',
      },
      messages: {
        success: `GPS device {{sim}} activated in Twilio`,
      },
    },
  },
  messages: {
    loading: 'Loading customers',
  },
  twilioWarningDialog: {
    title: 'Phone number release warning',
    message:
      'You are about to release a phone number for {{ id }}. Doing so may result in loss of customer data. Are you sure?',
  },
  settings: {
    title: "{{ name }}'s settings",
    labels: {
      save: 'Update settings',
      global: 'Global',
    },
    messages: {
      confirmSave: 'Are you sure you want to save these changes?',
      parsingError: 'A Problem occured while parsing the input file',
      success: "{{ name }}'s settings successfully updated",
      loading: 'Loading settings',
    },
  },
  FieldLabel: {
    labels: {
      item: '{{ id }}. {{ label }} ',
    },
    messages: {
      noLabels: 'No label',
      noDescription: 'No description',
    },
  },
  SMSUsage: {
    labels: {
      tag: '{{ value }}%',
      title:
        'Received: {{ inbound }}; Sent: {{ outbound }}; Quota: {{ smsLimit }}',
    },
  },
  remove: {
    labels: {
      remove: 'Delete',
    },
    messages: {
      failed: 'Error while deleting customer',
      success: 'Customer {{ id }} successfully removed',
    },
  },
  RefreshCustomer: {
    labels: {
      refresh: 'Refresh',
    },
  },
  total: 'Total customers:',
  tags: {
    expired: 'Expired',
    expiresIn: 'Expires in {{ count }} day',
    expiresIn_plural: 'Expires in {{ count }} days',
    isTwilioAdded: 'SMS',
  },
  salesforce: {
    title: 'Salesforce integration',
    description:
      'Following properties will be synchronized from Admin into Salesforce:',
    success: 'Salesforce data synchronized.',
    error: 'Unable to synchronize to Salesforce: {{- error }}.',
  },
};
