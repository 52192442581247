import Auth, { CognitoUser } from '@aws-amplify/auth';
import { AuthState, ChallangeName } from 'types';

export const signIn = async (
  username: string,
  password: string,
): Promise<{ authState: AuthState; user: CognitoUser }> => {
  try {
    const user = await Auth.signIn(username, password);

    if (user.signInUserSession !== null) {
      return { authState: AuthState.Authenticated, user };
    }

    // If there is a challenge, show appropriate screen
    if ('challengeName' in user) {
      const { challengeName } = user;
      if (challengeName === ChallangeName.NewPasswordRequired) {
        return { authState: AuthState.SetNewPassword, user };
      } else if (challengeName === ChallangeName.MfaSetup) {
        return { authState: AuthState.MfaSetup, user };
      } else if (challengeName === ChallangeName.SoftwareTokenMfa) {
        return { authState: AuthState.ConfirmSignIn, user };
      }
    }

    throw new Error('Invalid response from server');
  } catch (err) {
    if (err.code === 'PasswordResetRequiredException') {
      throw new Error(
        'Password reset required, please continue with "Forgot password"',
      );
    } else {
      throw err;
    }
  }
};

export const forgotPassword = async (username: string): Promise<CognitoUser> =>
  Auth.forgotPassword(username);

export const forgotPasswordSubmit = async ({
  username,
  token,
  password,
}: {
  username?: string;
  token: string;
  password: string;
}): Promise<void> => {
  if (!username) {
    throw new Error('Username is undefined.');
  }

  return Auth.forgotPasswordSubmit(username, token, password);
};

export const signInMFA = async ({
  token,
  user,
}: {
  user: CognitoUser;
  token: string;
}): Promise<CognitoUser> => {
  await Auth.confirmSignIn(user, token, 'SOFTWARE_TOKEN_MFA');

  return Auth.currentAuthenticatedUser();
};

export const newPassword = async ({
  user,
  password,
}: {
  user: CognitoUser;
  password: string;
}): Promise<CognitoUser> => Auth.completeNewPassword(user, password, []);

export const confirmMFASetup = async ({
  user,
  token,
}: {
  user: CognitoUser;
  token: string;
}): Promise<any> => {
  await Auth.verifyTotpToken(user, token);

  return Auth.currentAuthenticatedUser();
};

export const generateMFAQr = async ({
  user,
}: {
  user: CognitoUser;
}): Promise<string> => {
  const code = await Auth.setupTOTP(user);

  return (
    'otpauth://totp/AWSCognito:' +
    user.getUsername() +
    '?secret=' +
    code +
    '&issuer=' +
    process.env.REACT_APP_COGNITO_TOKEN_ISSUER
  );
};
