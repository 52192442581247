export enum AuthState {
  Authenticated = 'authenticated',
  Default = 'default',
  ForgotPassword = 'forgotPassword',
  ForgotPasswordSubmit = 'forgotPasswordSubmit',
  MfaSetup = 'mfaSetup',
  SetNewPassword = 'setNewPassword',
  SignIn = 'signIn',
  SignedOut = 'signedOut',
  ConfirmSignIn = 'token',
}

export enum ChallangeName {
  NewPasswordRequired = 'NEW_PASSWORD_REQUIRED',
  MfaSetup = 'MFA_SETUP',
  SoftwareTokenMfa = 'SOFTWARE_TOKEN_MFA',
}
