import { Steps } from 'antd';
import { get, size } from 'lodash-es';
import React from 'react';
import styled from 'styled-components';
import { array, Schema } from 'yup';

import { useCustom, useStepper } from '../hooks';
import { CheckIcon, EditIcon, ImportIcon, UploadIcon } from '../icons';
import ImportForm from './components/ImportForm';
import ImportLoading from './components/ImportLoading';
import Overview from './components/Overview';
import ResolveInvalid from './components/ResolveInvalid';
import ResultError from './components/ResultError';
import ResultSuccess from './components/ResultSuccess';

const Step = Steps.Step;

interface IProps<TData, TResult> {
  validation: Schema<TData>;
  onSubmit: (data: TData[]) => Promise<TResult>;
  onComplete: () => void;
  tableColumns: object[];
  formFields: JSX.Element[];
}

const Content = styled.div`
  margin-top: 20px;
`;

enum ImportWizardStep {
  Import,
  ResolveInvalid,
  Overview,
  Success,
  Error,
  Loading,
}

export interface IImportWizardError {
  message: string;
  errors: string[];
}

function ImportWizard<TData, TResult>({
  validation,
  onSubmit,
  onComplete,
  formFields,
  tableColumns,
}: IProps<TData, TResult>) {
  const step = useStepper();
  const valid = useCustom<TData[]>(null);
  const invalid = useCustom<TData[]>(null);
  const importWizardError = useCustom<IImportWizardError>(null);

  const handleImportComplete = (data) => {
    valid.setValue(data.valid);
    invalid.setValue(data.invalid);

    if (size(data.invalid)) {
      step.next();
    } else {
      step.setStep(ImportWizardStep.Overview);
    }
  };

  const handleResolveInvalidSubmit = (data: TData[]) => {
    valid.setValue(data);
    invalid.setValue(null);
    step.next();
  };

  const handleOverviewComplete = async (data: TData[]) => {
    try {
      step.setStep(ImportWizardStep.Loading);
      await onSubmit(array().of(validation).cast(data, { stripUnknown: true }));
      step.setStep(ImportWizardStep.Success);
    } catch (e) {
      importWizardError.setValue({
        message: e.message,
        errors: get(e, 'networkError.result.errors', []).map(
          (error) => error.message,
        ),
      });
      step.setStep(ImportWizardStep.Error);
    }
  };

  const handleCancel = () => {
    valid.setValue(null);
    invalid.setValue(null);
    importWizardError.setValue(null);
    step.reset();
  };

  const handleComplete = () => {
    handleCancel();
    onComplete();
  };

  const getStepContent = () => {
    switch (step.value) {
      case ImportWizardStep.Import:
        return (
          <ImportForm<TData>
            validation={validation}
            onComplete={handleImportComplete}
            onCancel={handleCancel}
          />
        );
      case ImportWizardStep.ResolveInvalid:
        return (
          <ResolveInvalid<TData>
            invalidData={invalid.value}
            validation={validation}
            formFields={formFields}
            onCancel={handleCancel}
            onSubmit={handleResolveInvalidSubmit}
          />
        );
      case ImportWizardStep.Overview:
        return (
          <Overview<TData>
            onComplete={handleOverviewComplete}
            tableColumns={tableColumns}
            onCancel={handleCancel}
            data={valid.value}
          />
        );
      case ImportWizardStep.Success:
        return <ResultSuccess onSubmit={handleComplete} />;
      case ImportWizardStep.Error:
        return (
          <ResultError
            error={importWizardError.value}
            onSubmit={handleCancel}
          />
        );
      case ImportWizardStep.Loading:
        return <ImportLoading />;
      default:
        return 'Unknown step';
    }
  };

  return (
    <>
      <Steps current={step.value} size="small" style={{ padding: '0 16px' }}>
        <Step key="Upload File" title="Upload" icon={<UploadIcon />} />
        <Step
          key="Resolve Invalid"
          title="Resolve Invalid"
          icon={<EditIcon />}
        />
        <Step key="Confirm" title="Confirm" icon={<ImportIcon />} />
        <Step key="Result" title="Result" icon={<CheckIcon />} />
      </Steps>
      <Content>{getStepContent()}</Content>
    </>
  );
}

export default ImportWizard;
