import { Spin as AntSpin } from 'antd';
import { SpinProps } from 'antd/es/spin';
import React from 'react';

import { LoadingIcon } from './icons';

interface IProps extends SpinProps {
  size?: 'small' | 'default' | 'large';
  children?: JSX.Element;
}

const spinSize = {
  default: 24,
  large: 36,
  small: 15,
};

const Spin = ({ size = 'default', ...props }: IProps) => (
  <AntSpin
    {...props}
    size={size}
    indicator={<LoadingIcon style={{ fontSize: spinSize[size] }} spin={true} />}
  />
);

export default Spin;
