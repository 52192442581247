export const assetAttachments = {
  title: 'asset attachment',
  title_plural: 'asset attachments',
  fields: {
    id: 'ID',
    isActive: 'Active',
    name: 'Name',
    description: 'Description',
    registrationNumber: 'Registration No.',
    model: 'Model',
    make: 'Make',
    year: 'Year',
    serialNumber: 'Serial number',
    numberReading: 'Number reading',
    chargeRate: 'Charge rate',
    actions: 'Actions',
  },
  edit: {
    loading: 'Loading asset attachment',
    title: 'Edit {{ name }}',
    success: 'Asset attachment {{ name }} successfully updated',
    submit: 'Update attachment',
  },
  create: {
    title: 'Create new asset attachment',
    button: 'Create',
    success: 'Asset attachment {{ name }} successfully created',
    submit: 'Create',
  },
};
