/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SelectEmploymentType
// ====================================================

export interface SelectEmploymentType_employmentTypes {
  id: number;
  name: string;
}

export interface SelectEmploymentType {
  employmentTypes: SelectEmploymentType_employmentTypes[] | null;
}

export interface SelectEmploymentTypeVariables {
  customerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAdminUser
// ====================================================

export interface CreateAdminUser_createAdminUser {
  /**
   * We are using username as ID because Cognito doesn't provide anything better
   */
  id: string;
  username: string;
  firstName: string | null;
  lastName: string | null;
  isActive: boolean;
  status: string | null;
  email: string | null;
  role: AdminUserRole | null;
}

export interface CreateAdminUser {
  createAdminUser: CreateAdminUser_createAdminUser | null;
}

export interface CreateAdminUserVariables {
  username: string;
  email: string;
  role: AdminUserRole;
  firstName?: string | null;
  lastName?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditAdminUser
// ====================================================

export interface EditAdminUser_editAdminUser {
  /**
   * We are using username as ID because Cognito doesn't provide anything better
   */
  id: string;
  username: string;
  firstName: string | null;
  lastName: string | null;
  isActive: boolean;
  status: string | null;
  email: string | null;
  role: AdminUserRole | null;
}

export interface EditAdminUser {
  editAdminUser: EditAdminUser_editAdminUser | null;
}

export interface EditAdminUserVariables {
  username: string;
  role?: AdminUserRole | null;
  firstName?: string | null;
  lastName?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResendConfirmationEmail
// ====================================================

export interface ResendConfirmationEmail_resendConfirmationEmail {
  username: string;
}

export interface ResendConfirmationEmail {
  resendConfirmationEmail: ResendConfirmationEmail_resendConfirmationEmail | null;
}

export interface ResendConfirmationEmailVariables {
  username: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveAdminUser
// ====================================================

export interface RemoveAdminUser_removeAdminUser {
  /**
   * We are using username as ID because Cognito doesn't provide anything better
   */
  id: string;
  username: string;
  firstName: string | null;
  lastName: string | null;
  isActive: boolean;
  status: string | null;
  email: string | null;
  role: AdminUserRole | null;
}

export interface RemoveAdminUser {
  removeAdminUser: RemoveAdminUser_removeAdminUser | null;
}

export interface RemoveAdminUserVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdminUserList
// ====================================================

export interface AdminUserList_adminUsers {
  /**
   * We are using username as ID because Cognito doesn't provide anything better
   */
  id: string;
  username: string;
  firstName: string | null;
  lastName: string | null;
  isActive: boolean;
  status: string | null;
  email: string | null;
  role: AdminUserRole | null;
}

export interface AdminUserList {
  adminUsers: AdminUserList_adminUsers[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnalyticsEmbedUrl
// ====================================================

export interface AnalyticsEmbedUrl {
  analyticsEmbedUrl: string | null;
}

export interface AnalyticsEmbedUrlVariables {
  dashboardId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportAssetAttachments
// ====================================================

export interface ImportAssetAttachments_importAssetAttachments {
  id: number;
}

export interface ImportAssetAttachments {
  importAssetAttachments: (ImportAssetAttachments_importAssetAttachments | null)[] | null;
}

export interface ImportAssetAttachmentsVariables {
  customerId: string;
  data: (AssetAttachmentImport | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveAssetAttachments
// ====================================================

export interface RemoveAssetAttachments {
  removeAssetAttachments: number | null;
}

export interface RemoveAssetAttachmentsVariables {
  customerId: string;
  ids: (number | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveAssetAttachment
// ====================================================

export interface ArchiveAssetAttachment_editAssetAttachment {
  id: number;
  isActive: boolean;
}

export interface ArchiveAssetAttachment {
  editAssetAttachment: ArchiveAssetAttachment_editAssetAttachment | null;
}

export interface ArchiveAssetAttachmentVariables {
  customerId: string;
  id: number;
  isActive: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditAssetAttachment
// ====================================================

export interface EditAssetAttachment_editAssetAttachment {
  id: number;
  chargeRate: number | null;
  description: string | null;
  isActive: boolean;
  make: string | null;
  model: string | null;
  name: string;
  numberReading: number | null;
  registrationNumber: string | null;
  serialNumber: string | null;
  year: string | null;
}

export interface EditAssetAttachment {
  editAssetAttachment: EditAssetAttachment_editAssetAttachment | null;
}

export interface EditAssetAttachmentVariables {
  customerId: string;
  id: number;
  data: AssetAttachmentEdit;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AssetAttachmentList
// ====================================================

export interface AssetAttachmentList_counts {
  assetAttachments: number | null;
}

export interface AssetAttachmentList_assetAttachments {
  id: number;
  chargeRate: number | null;
  description: string | null;
  isActive: boolean;
  make: string | null;
  model: string | null;
  name: string;
  numberReading: number | null;
  registrationNumber: string | null;
  serialNumber: string | null;
  year: string | null;
}

export interface AssetAttachmentList {
  counts: AssetAttachmentList_counts | null;
  assetAttachments: AssetAttachmentList_assetAttachments[] | null;
}

export interface AssetAttachmentListVariables {
  id: string;
  limit?: number | null;
  start?: number | null;
  search?: string | null;
  exact?: AssetAttachmentSearchExact | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportAssetAttachments
// ====================================================

export interface ExportAssetAttachments_exportAssetAttachments {
  name: string;
  description: string | null;
  registrationNumber: string | null;
  serialNumber: string | null;
  make: string | null;
  model: string | null;
  year: string | null;
}

export interface ExportAssetAttachments {
  exportAssetAttachments: ExportAssetAttachments_exportAssetAttachments[] | null;
}

export interface ExportAssetAttachmentsVariables {
  customerId: string;
  ids?: (number | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AssetAttachmentFormData
// ====================================================

export interface AssetAttachmentFormData_assetAttachment {
  id: number;
  chargeRate: number | null;
  description: string | null;
  isActive: boolean;
  make: string | null;
  model: string | null;
  name: string;
  numberReading: number | null;
  registrationNumber: string | null;
  serialNumber: string | null;
  year: string | null;
}

export interface AssetAttachmentFormData {
  assetAttachment: AssetAttachmentFormData_assetAttachment | null;
}

export interface AssetAttachmentFormDataVariables {
  customerId: string;
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportAssetGPSDevices
// ====================================================

export interface ImportAssetGPSDevices_importAssetGPSDevices {
  id: number;
}

export interface ImportAssetGPSDevices {
  importAssetGPSDevices: (ImportAssetGPSDevices_importAssetGPSDevices | null)[] | null;
}

export interface ImportAssetGPSDevicesVariables {
  customerId: string;
  data: (AssetGPSDeviceImport | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveAssetGPSDevice
// ====================================================

export interface ArchiveAssetGPSDevice_editAssetGPSDevice {
  id: number;
  isActive: boolean;
}

export interface ArchiveAssetGPSDevice {
  editAssetGPSDevice: ArchiveAssetGPSDevice_editAssetGPSDevice | null;
}

export interface ArchiveAssetGPSDeviceVariables {
  customerId: string;
  id: number;
  isActive: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditAssetGPSDevice
// ====================================================

export interface EditAssetGPSDevice_editAssetGPSDevice {
  id: number;
  deviceTypeId: number;
  deviceTypeMapperId: number;
  imei: string;
  isActive: boolean;
  modifiedAt: any;
  sim: string;
  vehicleId: number | null;
}

export interface EditAssetGPSDevice {
  editAssetGPSDevice: EditAssetGPSDevice_editAssetGPSDevice | null;
}

export interface EditAssetGPSDeviceVariables {
  customerId: string;
  id: number;
  data: AssetGPSDeviceEdit;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTwilioSim
// ====================================================

export interface UpdateTwilioSim_updateTwilioSim {
  sid: string | null;
  iccid: string | null;
  status: string | null;
  unique_name: string | null;
  account_sid: string | null;
  fleet_sid: string | null;
  date_created: any | null;
  date_updated: any | null;
}

export interface UpdateTwilioSim {
  updateTwilioSim: UpdateTwilioSim_updateTwilioSim | null;
}

export interface UpdateTwilioSimVariables {
  SimSid: string;
  FleetSid: string;
  SimStatus: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AssetGPSDeviceList
// ====================================================

export interface AssetGPSDeviceList_counts {
  assetGPSDevices: number | null;
}

export interface AssetGPSDeviceList_assetGPSDevices {
  id: number;
  deviceTypeId: number;
  deviceTypeMapperId: number;
  imei: string;
  isActive: boolean;
  modifiedAt: any;
  sim: string;
  vehicleId: number | null;
}

export interface AssetGPSDeviceList {
  counts: AssetGPSDeviceList_counts | null;
  assetGPSDevices: AssetGPSDeviceList_assetGPSDevices[] | null;
}

export interface AssetGPSDeviceListVariables {
  id: string;
  limit?: number | null;
  start?: number | null;
  search?: string | null;
  exact?: AssetGPSDeviceSearchExact | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AssetGPSDeviceFormData
// ====================================================

export interface AssetGPSDeviceFormData_assetGPSDevice {
  id: number;
  deviceTypeId: number;
  deviceTypeMapperId: number;
  imei: string;
  isActive: boolean;
  modifiedAt: any;
  sim: string;
  vehicleId: number | null;
}

export interface AssetGPSDeviceFormData {
  assetGPSDevice: AssetGPSDeviceFormData_assetGPSDevice | null;
}

export interface AssetGPSDeviceFormDataVariables {
  customerId: string;
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportAssetGPSDevices
// ====================================================

export interface ExportAssetGPSDevices_exportAssetGPSDevices {
  id: number;
  deviceTypeId: number;
  deviceTypeMapperId: number;
  imei: string;
  isActive: boolean;
  modifiedAt: any;
  sim: string;
  vehicleId: number | null;
}

export interface ExportAssetGPSDevices {
  exportAssetGPSDevices: ExportAssetGPSDevices_exportAssetGPSDevices[] | null;
}

export interface ExportAssetGPSDevicesVariables {
  customerId: string;
  ids?: (number | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AssetGPSDeviceTwilioSidByIccid
// ====================================================

export interface AssetGPSDeviceTwilioSidByIccid_twilioSimByICCID {
  sid: string | null;
  iccid: string | null;
  status: string | null;
  unique_name: string | null;
  account_sid: string | null;
  fleet_sid: string | null;
}

export interface AssetGPSDeviceTwilioSidByIccid {
  twilioSimByICCID: AssetGPSDeviceTwilioSidByIccid_twilioSimByICCID | null;
}

export interface AssetGPSDeviceTwilioSidByIccidVariables {
  iccid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportAssets
// ====================================================

export interface ImportAssets_importAssets {
  id: number;
}

export interface ImportAssets {
  importAssets: (ImportAssets_importAssets | null)[] | null;
}

export interface ImportAssetsVariables {
  customerId: string;
  data: (AssetImport | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveAsset
// ====================================================

export interface ArchiveAsset_editAsset {
  id: number;
  isActive: boolean;
}

export interface ArchiveAsset {
  editAsset: ArchiveAsset_editAsset | null;
}

export interface ArchiveAssetVariables {
  customerId: string;
  id: number;
  isActive: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditAsset
// ====================================================

export interface EditAsset_editAsset_attachments {
  id: number;
  name: string;
}

export interface EditAsset_editAsset {
  id: number;
  isActive: boolean;
  externalId: string | null;
  name: string;
  description: string | null;
  make: string | null;
  model: string | null;
  year: string | null;
  regNumber: string | null;
  serialNumber: string | null;
  attachments: EditAsset_editAsset_attachments[] | null;
}

export interface EditAsset {
  editAsset: EditAsset_editAsset | null;
}

export interface EditAssetVariables {
  customerId: string;
  id: number;
  data: AssetEdit;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveAssets
// ====================================================

export interface RemoveAssets {
  removeAssets: number | null;
}

export interface RemoveAssetsVariables {
  customerId: string;
  ids: (number | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AssetList
// ====================================================

export interface AssetList_counts {
  assets: number | null;
}

export interface AssetList_assets {
  id: number;
  isActive: boolean;
  externalId: string | null;
  name: string;
  description: string | null;
  make: string | null;
  model: string | null;
  year: string | null;
  regNumber: string | null;
  serialNumber: string | null;
}

export interface AssetList {
  counts: AssetList_counts | null;
  assets: AssetList_assets[] | null;
}

export interface AssetListVariables {
  id: string;
  limit?: number | null;
  start?: number | null;
  exact?: AssetSearchExact | null;
  search?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AssetAttachmentsFormData
// ====================================================

export interface AssetAttachmentsFormData_asset_attachments {
  id: number;
}

export interface AssetAttachmentsFormData_asset {
  id: number;
  name: string;
  attachments: AssetAttachmentsFormData_asset_attachments[] | null;
}

export interface AssetAttachmentsFormData_assetAttachments {
  id: number;
  name: string;
}

export interface AssetAttachmentsFormData {
  asset: AssetAttachmentsFormData_asset | null;
  assetAttachments: AssetAttachmentsFormData_assetAttachments[] | null;
}

export interface AssetAttachmentsFormDataVariables {
  customerId: string;
  assetId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AssetFormData
// ====================================================

export interface AssetFormData_asset {
  id: number;
  isActive: boolean;
  externalId: string | null;
  name: string;
  description: string | null;
  make: string | null;
  model: string | null;
  year: string | null;
  regNumber: string | null;
  serialNumber: string | null;
}

export interface AssetFormData {
  asset: AssetFormData_asset | null;
}

export interface AssetFormDataVariables {
  customerId: string;
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportAssets
// ====================================================

export interface ExportAssets_exportAssets {
  name: string;
  description: string | null;
  externalId: string | null;
  regNumber: string | null;
  serialNumber: string | null;
  make: string | null;
  model: string | null;
  year: string | null;
}

export interface ExportAssets {
  exportAssets: ExportAssets_exportAssets[] | null;
}

export interface ExportAssetsVariables {
  customerId: string;
  ids?: (number | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AuditLogList
// ====================================================

export interface AuditLogList_auditLogs {
  modifiedBy: string | null;
  /**
   * Log time in milliseconds
   */
  dateAdded: number | null;
  actionType: string | null;
  module: string | null;
  customerId: string | null;
  itemId: string | null;
  itemsChanged: number | null;
  payload: any | null;
}

export interface AuditLogList {
  auditLogs: AuditLogList_auditLogs[] | null;
}

export interface AuditLogListVariables {
  customerId: string;
  modules?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportChargeCodes
// ====================================================

export interface ImportChargeCodes_importChargeCodes {
  id: number;
}

export interface ImportChargeCodes {
  importChargeCodes: (ImportChargeCodes_importChargeCodes | null)[] | null;
}

export interface ImportChargeCodesVariables {
  customerId: string;
  data: (ChargeCodeImport | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveChargeCodes
// ====================================================

export interface RemoveChargeCodes {
  removeChargeCodes: number | null;
}

export interface RemoveChargeCodesVariables {
  customerId: string;
  ids: (number | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditChargeCode
// ====================================================

export interface EditChargeCode_editChargeCode {
  id: number;
  label: string | null;
  code: string | null;
}

export interface EditChargeCode {
  editChargeCode: EditChargeCode_editChargeCode | null;
}

export interface EditChargeCodeVariables {
  customerId: string;
  id: number;
  data: ChargeCodeEdit;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ChargeCodeList
// ====================================================

export interface ChargeCodeList_counts {
  chargeCodes: number | null;
}

export interface ChargeCodeList_chargeCodes {
  id: number;
  label: string | null;
  code: string | null;
}

export interface ChargeCodeList {
  counts: ChargeCodeList_counts | null;
  chargeCodes: ChargeCodeList_chargeCodes[] | null;
}

export interface ChargeCodeListVariables {
  id: string;
  limit?: number | null;
  start?: number | null;
  search?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportChargeCodes
// ====================================================

export interface ExportChargeCodes_exportChargeCodes {
  label: string | null;
  code: string | null;
}

export interface ExportChargeCodes {
  exportChargeCodes: ExportChargeCodes_exportChargeCodes[] | null;
}

export interface ExportChargeCodesVariables {
  customerId: string;
  ids?: (number | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ChargeCodeFormData
// ====================================================

export interface ChargeCodeFormData_chargeCode {
  id: number;
  label: string | null;
  code: string | null;
}

export interface ChargeCodeFormData {
  chargeCode: ChargeCodeFormData_chargeCode | null;
}

export interface ChargeCodeFormDataVariables {
  customerId: string;
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportClients
// ====================================================

export interface ImportClients_importClients {
  id: number;
}

export interface ImportClients {
  importClients: (ImportClients_importClients | null)[] | null;
}

export interface ImportClientsVariables {
  customerId: string;
  data: (ClientImport | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditClient
// ====================================================

export interface EditClient_editClient_contacts {
  id: number;
  firstName: string | null;
  lastName: string | null;
}

export interface EditClient_editClient {
  id: number;
  isActive: boolean;
  name: string;
  abn: string | null;
  externalId: string | null;
  contacts: EditClient_editClient_contacts[] | null;
}

export interface EditClient {
  editClient: EditClient_editClient | null;
}

export interface EditClientVariables {
  customerId: string;
  id: number;
  data: ClientEdit;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveClient
// ====================================================

export interface ArchiveClient_editClient {
  id: number;
  isActive: boolean;
}

export interface ArchiveClient {
  editClient: ArchiveClient_editClient | null;
}

export interface ArchiveClientVariables {
  customerId: string;
  id: number;
  isActive: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveClients
// ====================================================

export interface RemoveClients {
  removeClients: number | null;
}

export interface RemoveClientsVariables {
  customerId: string;
  ids: (number | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ClientList
// ====================================================

export interface ClientList_counts {
  clients: number | null;
}

export interface ClientList_clients_contacts {
  id: number;
  firstName: string | null;
  lastName: string | null;
}

export interface ClientList_clients {
  id: number;
  isActive: boolean;
  name: string;
  abn: string | null;
  externalId: string | null;
  contacts: ClientList_clients_contacts[] | null;
}

export interface ClientList {
  counts: ClientList_counts | null;
  clients: ClientList_clients[] | null;
}

export interface ClientListVariables {
  id: string;
  limit?: number | null;
  start?: number | null;
  search?: string | null;
  exact?: ClientSearchExact | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ClientContactsFormData
// ====================================================

export interface ClientContactsFormData_contacts {
  id: number;
  firstName: string | null;
  lastName: string | null;
}

export interface ClientContactsFormData_client_contacts {
  id: number;
  firstName: string | null;
  lastName: string | null;
}

export interface ClientContactsFormData_client {
  id: number;
  name: string;
  contacts: ClientContactsFormData_client_contacts[] | null;
}

export interface ClientContactsFormData {
  contacts: ClientContactsFormData_contacts[] | null;
  client: ClientContactsFormData_client | null;
}

export interface ClientContactsFormDataVariables {
  customerId: string;
  clientId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ClientFormData
// ====================================================

export interface ClientFormData_client {
  id: number;
  isActive: boolean;
  name: string;
  abn: string | null;
  externalId: string | null;
}

export interface ClientFormData {
  client: ClientFormData_client | null;
}

export interface ClientFormDataVariables {
  customerId: string;
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportClients
// ====================================================

export interface ExportClients_exportClients {
  name: string;
  abn: string | null;
  externalId: string | null;
}

export interface ExportClients {
  exportClients: ExportClients_exportClients[] | null;
}

export interface ExportClientsVariables {
  customerId: string;
  ids?: (number | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportCompetencies
// ====================================================

export interface ImportCompetencies_importCompetencies {
  id: number;
}

export interface ImportCompetencies {
  importCompetencies: (ImportCompetencies_importCompetencies | null)[] | null;
}

export interface ImportCompetenciesVariables {
  customerId: string;
  data: (CompetencyImport | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveCompetencies
// ====================================================

export interface RemoveCompetencies {
  removeCompetencies: number | null;
}

export interface RemoveCompetenciesVariables {
  customerId: string;
  ids: (number | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditCompetency
// ====================================================

export interface EditCompetency_editCompetency {
  id: number;
  name: string;
  licenseNumber: string | null;
  requiresExpirationDate: boolean;
  requiresIssueDate: boolean;
}

export interface EditCompetency {
  editCompetency: EditCompetency_editCompetency | null;
}

export interface EditCompetencyVariables {
  customerId: string;
  id: number;
  data: CompetencyEdit;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompetencyList
// ====================================================

export interface CompetencyList_counts {
  competencies: number | null;
}

export interface CompetencyList_competencies {
  id: number;
  licenseNumber: string | null;
  requiresExpirationDate: boolean;
  requiresIssueDate: boolean;
  name: string;
}

export interface CompetencyList {
  counts: CompetencyList_counts | null;
  competencies: CompetencyList_competencies[] | null;
}

export interface CompetencyListVariables {
  id: string;
  limit?: number | null;
  start?: number | null;
  exact?: CompetencySearchExact | null;
  search?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompetencyUsersFormData
// ====================================================

export interface CompetencyUsersFormData_users {
  id: number;
  fullName: string | null;
}

export interface CompetencyUsersFormData_competency_competencyUsers {
  id: number | null;
  userId: number | null;
  issueDate: any | null;
  expirationDate: any | null;
  competencyId: number | null;
  description: string | null;
}

export interface CompetencyUsersFormData_competency {
  id: number;
  name: string;
  licenseNumber: string | null;
  requiresExpirationDate: boolean;
  requiresIssueDate: boolean;
  competencyUsers: CompetencyUsersFormData_competency_competencyUsers[] | null;
}

export interface CompetencyUsersFormData {
  users: CompetencyUsersFormData_users[] | null;
  competency: CompetencyUsersFormData_competency | null;
}

export interface CompetencyUsersFormDataVariables {
  customerId: string;
  competencyId: number;
  userTypeId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportCompetencies
// ====================================================

export interface ExportCompetencies_exportCompetencies {
  name: string;
  licenseNumber: string | null;
  requiresExpirationDate: boolean;
  requiresIssueDate: boolean;
}

export interface ExportCompetencies {
  exportCompetencies: ExportCompetencies_exportCompetencies[] | null;
}

export interface ExportCompetenciesVariables {
  customerId: string;
  ids?: (number | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompetencyFormData
// ====================================================

export interface CompetencyFormData_competency {
  id: number;
  name: string;
  licenseNumber: string | null;
  requiresExpirationDate: boolean;
  requiresIssueDate: boolean;
}

export interface CompetencyFormData {
  competency: CompetencyFormData_competency | null;
}

export interface CompetencyFormDataVariables {
  customerId: string;
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportContacts
// ====================================================

export interface ImportContacts_importContacts {
  id: number;
}

export interface ImportContacts {
  importContacts: (ImportContacts_importContacts | null)[] | null;
}

export interface ImportContactsVariables {
  customerId: string;
  data: (ContactImport | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveContacts
// ====================================================

export interface RemoveContacts {
  removeContacts: number | null;
}

export interface RemoveContactsVariables {
  customerId: string;
  ids: (number | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContactList
// ====================================================

export interface ContactList_counts {
  contacts: number | null;
}

export interface ContactList_contacts {
  id: number;
  firstName: string | null;
  lastName: string | null;
  company: string | null;
  jobTitle: string | null;
}

export interface ContactList {
  counts: ContactList_counts | null;
  contacts: ContactList_contacts[] | null;
}

export interface ContactListVariables {
  id: string;
  limit?: number | null;
  start?: number | null;
  search?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContactListDetail
// ====================================================

export interface ContactListDetail_contact {
  id: number;
  officePhone: string | null;
  mobile: string | null;
  email: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  postcode: string | null;
  comments: string | null;
}

export interface ContactListDetail {
  contact: ContactListDetail_contact | null;
}

export interface ContactListDetailVariables {
  customerId: string;
  contactId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportContacts
// ====================================================

export interface ExportContacts_exportContacts {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  mobile: string | null;
  officePhone: string | null;
  jobTitle: string | null;
  company: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  postcode: string | null;
  state: string | null;
  comments: string | null;
}

export interface ExportContacts {
  exportContacts: ExportContacts_exportContacts[] | null;
}

export interface ExportContactsVariables {
  customerId: string;
  ids?: (number | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SalesforceAccount
// ====================================================

export interface SalesforceAccount_salesforceAccount {
  id: string | null;
}

export interface SalesforceAccount {
  salesforceAccount: SalesforceAccount_salesforceAccount | null;
}

export interface SalesforceAccountVariables {
  salesforceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCustomer
// ====================================================

export interface CreateCustomer_createCustomer_insightsViewerUsage_users {
  id: number;
  fullName: string | null;
}

export interface CreateCustomer_createCustomer_insightsViewerUsage {
  /**
   * Number of viewer seats allocated for Insights.
   */
  allocated: number | null;
  /**
   * Number of viewer seats already assigned to users.
   */
  users: CreateCustomer_createCustomer_insightsViewerUsage_users[] | null;
}

export interface CreateCustomer_createCustomer_insightsCreatorUsage_users {
  id: number;
  fullName: string | null;
}

export interface CreateCustomer_createCustomer_insightsCreatorUsage {
  /**
   * Number of creator seats allocated for Insights.
   */
  allocated: number | null;
  /**
   * Number of creator seats already assigned to users.
   */
  users: CreateCustomer_createCustomer_insightsCreatorUsage_users[] | null;
}

export interface CreateCustomer_createCustomer {
  addedDateTime: string | null;
  expirationDateTime: string | null;
  id: string;
  isActive: boolean | null;
  isTwilioAdded: boolean | null;
  loginId: string | null;
  name: string | null;
  subscription: string | null;
  smsLimit: number | null;
  /**
   * Insights Viewer License Usage
   */
  insightsViewerUsage: CreateCustomer_createCustomer_insightsViewerUsage | null;
  /**
   * Insights Viewer License Usage
   */
  insightsCreatorUsage: CreateCustomer_createCustomer_insightsCreatorUsage | null;
  /**
   * Quota for number of users
   */
  userQuota: number | null;
  isTemplate: boolean | null;
  /**
   * Customer creation status
   */
  status: string | null;
  isCustomerMessageEnabled: boolean | null;
  customerMessage: string | null;
  /**
   * Does the customer also use GPS Gate
   */
  isGpsCustomer: boolean | null;
  /**
   * GPS Gate Application Tenant Id
   */
  gpsApplicationID: number | null;
  /**
   * Twilio IoT Fleet SID
   */
  twilioFleetSID: string | null;
  /**
   * Salesforce ID
   */
  salesforceID: string | null;
}

export interface CreateCustomer {
  createCustomer: CreateCustomer_createCustomer | null;
}

export interface CreateCustomerVariables {
  data: CreateCustomerDataInput;
  templateId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditCustomer
// ====================================================

export interface EditCustomer_editCustomer_insightsViewerUsage_users {
  id: number;
  fullName: string | null;
}

export interface EditCustomer_editCustomer_insightsViewerUsage {
  /**
   * Number of viewer seats allocated for Insights.
   */
  allocated: number | null;
  /**
   * Number of viewer seats already assigned to users.
   */
  users: EditCustomer_editCustomer_insightsViewerUsage_users[] | null;
}

export interface EditCustomer_editCustomer_insightsCreatorUsage_users {
  id: number;
  fullName: string | null;
}

export interface EditCustomer_editCustomer_insightsCreatorUsage {
  /**
   * Number of creator seats allocated for Insights.
   */
  allocated: number | null;
  /**
   * Number of creator seats already assigned to users.
   */
  users: EditCustomer_editCustomer_insightsCreatorUsage_users[] | null;
}

export interface EditCustomer_editCustomer {
  addedDateTime: string | null;
  expirationDateTime: string | null;
  id: string;
  isActive: boolean | null;
  isTwilioAdded: boolean | null;
  loginId: string | null;
  name: string | null;
  subscription: string | null;
  smsLimit: number | null;
  /**
   * Insights Viewer License Usage
   */
  insightsViewerUsage: EditCustomer_editCustomer_insightsViewerUsage | null;
  /**
   * Insights Viewer License Usage
   */
  insightsCreatorUsage: EditCustomer_editCustomer_insightsCreatorUsage | null;
  /**
   * Quota for number of users
   */
  userQuota: number | null;
  isTemplate: boolean | null;
  /**
   * Customer creation status
   */
  status: string | null;
  isCustomerMessageEnabled: boolean | null;
  customerMessage: string | null;
  /**
   * Does the customer also use GPS Gate
   */
  isGpsCustomer: boolean | null;
  /**
   * GPS Gate Application Tenant Id
   */
  gpsApplicationID: number | null;
  /**
   * Twilio IoT Fleet SID
   */
  twilioFleetSID: string | null;
  /**
   * Salesforce ID
   */
  salesforceID: string | null;
}

export interface EditCustomer {
  editCustomer: EditCustomer_editCustomer | null;
}

export interface EditCustomerVariables {
  data: EditCustomerDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditCustomerActive
// ====================================================

export interface EditCustomerActive_editCustomer {
  id: string;
  isActive: boolean | null;
}

export interface EditCustomerActive {
  editCustomer: EditCustomerActive_editCustomer | null;
}

export interface EditCustomerActiveVariables {
  customerId: string;
  isActive: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveCustomer
// ====================================================

export interface RemoveCustomer_removeCustomer_insightsViewerUsage_users {
  id: number;
  fullName: string | null;
}

export interface RemoveCustomer_removeCustomer_insightsViewerUsage {
  /**
   * Number of viewer seats allocated for Insights.
   */
  allocated: number | null;
  /**
   * Number of viewer seats already assigned to users.
   */
  users: RemoveCustomer_removeCustomer_insightsViewerUsage_users[] | null;
}

export interface RemoveCustomer_removeCustomer_insightsCreatorUsage_users {
  id: number;
  fullName: string | null;
}

export interface RemoveCustomer_removeCustomer_insightsCreatorUsage {
  /**
   * Number of creator seats allocated for Insights.
   */
  allocated: number | null;
  /**
   * Number of creator seats already assigned to users.
   */
  users: RemoveCustomer_removeCustomer_insightsCreatorUsage_users[] | null;
}

export interface RemoveCustomer_removeCustomer {
  addedDateTime: string | null;
  expirationDateTime: string | null;
  id: string;
  isActive: boolean | null;
  isTwilioAdded: boolean | null;
  loginId: string | null;
  name: string | null;
  subscription: string | null;
  smsLimit: number | null;
  /**
   * Insights Viewer License Usage
   */
  insightsViewerUsage: RemoveCustomer_removeCustomer_insightsViewerUsage | null;
  /**
   * Insights Viewer License Usage
   */
  insightsCreatorUsage: RemoveCustomer_removeCustomer_insightsCreatorUsage | null;
  /**
   * Quota for number of users
   */
  userQuota: number | null;
  isTemplate: boolean | null;
  /**
   * Customer creation status
   */
  status: string | null;
  isCustomerMessageEnabled: boolean | null;
  customerMessage: string | null;
  /**
   * Does the customer also use GPS Gate
   */
  isGpsCustomer: boolean | null;
  /**
   * GPS Gate Application Tenant Id
   */
  gpsApplicationID: number | null;
  /**
   * Twilio IoT Fleet SID
   */
  twilioFleetSID: string | null;
  /**
   * Salesforce ID
   */
  salesforceID: string | null;
}

export interface RemoveCustomer {
  removeCustomer: RemoveCustomer_removeCustomer | null;
}

export interface RemoveCustomerVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditSettings
// ====================================================

export interface EditSettings_editSettings {
  id: string;
  value: any | null;
}

export interface EditSettings {
  editSettings: (EditSettings_editSettings | null)[] | null;
}

export interface EditSettingsVariables {
  customerId: string;
  data: (UpdateSettings | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadImage
// ====================================================

export interface UploadImage {
  uploadImage: string;
}

export interface UploadImageVariables {
  customerId: string;
  imageData: ImageData;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignInToDashboard
// ====================================================

export interface SignInToDashboard {
  signInToDashboard: string;
}

export interface SignInToDashboardVariables {
  customerId: string;
  loginId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignInToFieldworker
// ====================================================

export interface SignInToFieldworker {
  signInToFieldworker: string;
}

export interface SignInToFieldworkerVariables {
  customerId: string;
  loginId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateInsightsTenants
// ====================================================

export interface updateInsightsTenants {
  updateInsightsTenants: string;
}

export interface updateInsightsTenantsVariables {
  tenants?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateGPSApplicationTenant
// ====================================================

export interface CreateGPSApplicationTenant_createGPSApplicationTenant {
  id: number | null;
  applicationTemplateId: number | null;
  name: string | null;
  description: string | null;
  culture: string | null;
  language: string | null;
  measurement: string | null;
  timeZone: string | null;
}

export interface CreateGPSApplicationTenant {
  createGPSApplicationTenant: CreateGPSApplicationTenant_createGPSApplicationTenant;
}

export interface CreateGPSApplicationTenantVariables {
  applicationTemplateId: number;
  name: string;
  description: string;
  culture?: string | null;
  language?: string | null;
  measurement?: string | null;
  timeZone?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MaintainGPSApplication
// ====================================================

export interface MaintainGPSApplication_maintainGPSApplication {
  id: string;
  /**
   * Does the customer also use GPS Gate
   */
  isGpsCustomer: boolean | null;
  /**
   * GPS Gate Application Tenant Id
   */
  gpsApplicationID: number | null;
  /**
   * Twilio IoT Fleet SID
   */
  twilioFleetSID: string | null;
}

export interface MaintainGPSApplication {
  maintainGPSApplication: MaintainGPSApplication_maintainGPSApplication | null;
}

export interface MaintainGPSApplicationVariables {
  customerId: string;
  isGpsCustomer: boolean;
  gpsApplicationID: number;
  twilioFleetSID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTwilioFleet
// ====================================================

export interface CreateTwilioFleet_createTwilioFleet {
  account_sid: string | null;
  sid: string | null;
  unique_name: string | null;
  date_created: any | null;
  date_updated: any | null;
  url: string | null;
  data_enabled: boolean | null;
  data_limit: number | null;
  data_metering: string | null;
  commands_enabled: boolean | null;
  commands_url: string | null;
  commands_method: string | null;
  sms_commands_enabled: boolean | null;
  sms_commands_url: string | null;
  sms_commands_method: string | null;
  network_access_profile_sid: string | null;
}

export interface CreateTwilioFleet {
  createTwilioFleet: CreateTwilioFleet_createTwilioFleet | null;
}

export interface CreateTwilioFleetVariables {
  NetworkAccessProfile: string;
  UniqueName: string;
  DataLimit?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: counts
// ====================================================

export interface counts_counts {
  /**
   * Total number of templates
   */
  total_templates: number | null;
  /**
   * Total number of templates with Active set to 1
   */
  active_templates: number | null;
  /**
   * Total number of customers
   */
  total_customers: number | null;
  /**
   * Total number of customers with Active set to 1
   */
  active_customers: number | null;
}

export interface counts {
  counts: counts_counts | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CustomerCreateFormData
// ====================================================

export interface CustomerCreateFormData_customers {
  loginId: string | null;
  id: string;
  isTemplate: boolean | null;
  name: string | null;
}

export interface CustomerCreateFormData {
  customers: CustomerCreateFormData_customers[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CustomerEditFormData
// ====================================================

export interface CustomerEditFormData_customers {
  loginId: string | null;
  id: string;
  isTemplate: boolean | null;
  name: string | null;
}

export interface CustomerEditFormData_customer_insightsViewerUsage_users {
  id: number;
  fullName: string | null;
}

export interface CustomerEditFormData_customer_insightsViewerUsage {
  /**
   * Number of viewer seats allocated for Insights.
   */
  allocated: number | null;
  /**
   * Number of viewer seats already assigned to users.
   */
  users: CustomerEditFormData_customer_insightsViewerUsage_users[] | null;
}

export interface CustomerEditFormData_customer_insightsCreatorUsage_users {
  id: number;
  fullName: string | null;
}

export interface CustomerEditFormData_customer_insightsCreatorUsage {
  /**
   * Number of creator seats allocated for Insights.
   */
  allocated: number | null;
  /**
   * Number of creator seats already assigned to users.
   */
  users: CustomerEditFormData_customer_insightsCreatorUsage_users[] | null;
}

export interface CustomerEditFormData_customer {
  addedDateTime: string | null;
  expirationDateTime: string | null;
  id: string;
  isActive: boolean | null;
  isTwilioAdded: boolean | null;
  loginId: string | null;
  name: string | null;
  subscription: string | null;
  smsLimit: number | null;
  /**
   * Insights Viewer License Usage
   */
  insightsViewerUsage: CustomerEditFormData_customer_insightsViewerUsage | null;
  /**
   * Insights Viewer License Usage
   */
  insightsCreatorUsage: CustomerEditFormData_customer_insightsCreatorUsage | null;
  /**
   * Quota for number of users
   */
  userQuota: number | null;
  isTemplate: boolean | null;
  /**
   * Customer creation status
   */
  status: string | null;
  isCustomerMessageEnabled: boolean | null;
  customerMessage: string | null;
  /**
   * Does the customer also use GPS Gate
   */
  isGpsCustomer: boolean | null;
  /**
   * GPS Gate Application Tenant Id
   */
  gpsApplicationID: number | null;
  /**
   * Twilio IoT Fleet SID
   */
  twilioFleetSID: string | null;
  /**
   * Salesforce ID
   */
  salesforceID: string | null;
}

export interface CustomerEditFormData {
  customers: CustomerEditFormData_customers[] | null;
  customer: CustomerEditFormData_customer | null;
}

export interface CustomerEditFormDataVariables {
  customerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CustomerList
// ====================================================

export interface CustomerList_counts {
  /**
   * Total available results for current search
   */
  customers: number | null;
}

export interface CustomerList_customers_insightsViewerUsage_users {
  id: number;
  fullName: string | null;
}

export interface CustomerList_customers_insightsViewerUsage {
  /**
   * Number of viewer seats allocated for Insights.
   */
  allocated: number | null;
  /**
   * Number of viewer seats already assigned to users.
   */
  users: CustomerList_customers_insightsViewerUsage_users[] | null;
}

export interface CustomerList_customers_insightsCreatorUsage_users {
  id: number;
  fullName: string | null;
}

export interface CustomerList_customers_insightsCreatorUsage {
  /**
   * Number of creator seats allocated for Insights.
   */
  allocated: number | null;
  /**
   * Number of creator seats already assigned to users.
   */
  users: CustomerList_customers_insightsCreatorUsage_users[] | null;
}

export interface CustomerList_customers {
  addedDateTime: string | null;
  expirationDateTime: string | null;
  id: string;
  isActive: boolean | null;
  isTwilioAdded: boolean | null;
  loginId: string | null;
  name: string | null;
  subscription: string | null;
  smsLimit: number | null;
  /**
   * Insights Viewer License Usage
   */
  insightsViewerUsage: CustomerList_customers_insightsViewerUsage | null;
  /**
   * Insights Viewer License Usage
   */
  insightsCreatorUsage: CustomerList_customers_insightsCreatorUsage | null;
  /**
   * Quota for number of users
   */
  userQuota: number | null;
  isTemplate: boolean | null;
  /**
   * Customer creation status
   */
  status: string | null;
  isCustomerMessageEnabled: boolean | null;
  customerMessage: string | null;
  /**
   * Does the customer also use GPS Gate
   */
  isGpsCustomer: boolean | null;
  /**
   * GPS Gate Application Tenant Id
   */
  gpsApplicationID: number | null;
  /**
   * Twilio IoT Fleet SID
   */
  twilioFleetSID: string | null;
  /**
   * Salesforce ID
   */
  salesforceID: string | null;
}

export interface CustomerList {
  counts: CustomerList_counts | null;
  customers: CustomerList_customers[] | null;
}

export interface CustomerListVariables {
  limit: number;
  start?: number | null;
  search?: string | null;
  exact?: CustomerSearchExact | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CustomerStatus
// ====================================================

export interface CustomerStatus_customer {
  id: string;
  /**
   * Customer creation status
   */
  status: string | null;
}

export interface CustomerStatus {
  customer: CustomerStatus_customer | null;
}

export interface CustomerStatusVariables {
  customerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SMSUsage
// ====================================================

export interface SMSUsage_customer_smsUsage {
  /**
   * Inbound message this month
   */
  inbound: number | null;
  /**
   * Outbound message this month
   */
  outbound: number | null;
  /**
   * (Inbound + Outbound / smsLimit) * 100. Add a % to it and you are good to go.
   */
  percentQuotaUsed: number | null;
}

export interface SMSUsage_customer {
  id: string;
  smsLimit: number | null;
  /**
   * SMS Usage of current month
   */
  smsUsage: SMSUsage_customer_smsUsage | null;
}

export interface SMSUsage {
  customer: SMSUsage_customer | null;
}

export interface SMSUsageVariables {
  customerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: settings
// ====================================================

export interface settings_settings {
  id: string;
  name: string | null;
  label: string | null;
  type: number | null;
  isUserEditable: boolean | null;
  value: any | null;
  description: string | null;
}

export interface settings {
  settings: settings_settings[] | null;
}

export interface settingsVariables {
  customerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InsigthsTargetGroup
// ====================================================

export interface InsigthsTargetGroup_insightsTenants {
  tenants: any | null;
}

export interface InsigthsTargetGroup {
  insightsTenants: InsigthsTargetGroup_insightsTenants;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SelectGPSApplicationTemplates
// ====================================================

export interface SelectGPSApplicationTemplates_gpsApplicationTemplates {
  id: number | null;
  name: string | null;
  description: string | null;
}

export interface SelectGPSApplicationTemplates {
  gpsApplicationTemplates: SelectGPSApplicationTemplates_gpsApplicationTemplates[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SelectGPSApplicationTenants
// ====================================================

export interface SelectGPSApplicationTenants_gpsApplicationTenants {
  id: number | null;
  name: string | null;
  description: string | null;
  userCount: number | null;
  maxUsers: number | null;
  licensesUsed: number | null;
  expire: string | null;
  expired: boolean | null;
  created: string | null;
}

export interface SelectGPSApplicationTenants {
  gpsApplicationTenants: SelectGPSApplicationTenants_gpsApplicationTenants[] | null;
}

export interface SelectGPSApplicationTenantsVariables {
  id?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SelectGPSApplicationDeviceTypes
// ====================================================

export interface SelectGPSApplicationDeviceTypes_gpsApplicationDeviceTypes {
  name: string | null;
  description: string | null;
  id: number | null;
}

export interface SelectGPSApplicationDeviceTypes {
  gpsApplicationDeviceTypes: SelectGPSApplicationDeviceTypes_gpsApplicationDeviceTypes[] | null;
}

export interface SelectGPSApplicationDeviceTypesVariables {
  applicationId: number;
  deviceTypeId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SelectGPSApplicationDeviceTypeMappers
// ====================================================

export interface SelectGPSApplicationDeviceTypeMappers_gpsApplicationDeviceTypeMappers {
  name: string | null;
  id: number | null;
}

export interface SelectGPSApplicationDeviceTypeMappers {
  gpsApplicationDeviceTypeMappers: SelectGPSApplicationDeviceTypeMappers_gpsApplicationDeviceTypeMappers[] | null;
}

export interface SelectGPSApplicationDeviceTypeMappersVariables {
  applicationId: number;
  deviceTypeId: number;
  deviceTypeMapperId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SelectTwilioFleets
// ====================================================

export interface SelectTwilioFleets_twilioFleetList {
  account_sid: string | null;
  sid: string | null;
  unique_name: string | null;
  date_created: any | null;
  date_updated: any | null;
  url: string | null;
  data_enabled: boolean | null;
  data_limit: number | null;
  data_metering: string | null;
  commands_enabled: boolean | null;
  commands_url: string | null;
  commands_method: string | null;
  sms_commands_enabled: boolean | null;
  sms_commands_url: string | null;
  sms_commands_method: string | null;
  network_access_profile_sid: string | null;
}

export interface SelectTwilioFleets {
  twilioFleetList: (SelectTwilioFleets_twilioFleetList | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SelectTwilioNetworkAccessProfiles
// ====================================================

export interface SelectTwilioNetworkAccessProfiles_twilioNetworkAccessProfiles_network_access_profiles {
  sid: string | null;
  unique_name: string | null;
}

export interface SelectTwilioNetworkAccessProfiles_twilioNetworkAccessProfiles {
  network_access_profiles: (SelectTwilioNetworkAccessProfiles_twilioNetworkAccessProfiles_network_access_profiles | null)[] | null;
}

export interface SelectTwilioNetworkAccessProfiles {
  twilioNetworkAccessProfiles: SelectTwilioNetworkAccessProfiles_twilioNetworkAccessProfiles;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TwilioFleetBySid
// ====================================================

export interface TwilioFleetBySid_twilioFleetBySid_network_access_profile {
  sid: string | null;
  unique_name: string | null;
  account_sid: string | null;
  date_created: any | null;
  date_updated: any | null;
  url: string | null;
}

export interface TwilioFleetBySid_twilioFleetBySid {
  account_sid: string | null;
  sid: string | null;
  unique_name: string | null;
  date_created: any | null;
  date_updated: any | null;
  url: string | null;
  data_enabled: boolean | null;
  data_limit: number | null;
  data_metering: string | null;
  commands_enabled: boolean | null;
  commands_url: string | null;
  commands_method: string | null;
  sms_commands_enabled: boolean | null;
  sms_commands_url: string | null;
  sms_commands_method: string | null;
  network_access_profile: TwilioFleetBySid_twilioFleetBySid_network_access_profile | null;
}

export interface TwilioFleetBySid {
  twilioFleetBySid: TwilioFleetBySid_twilioFleetBySid | null;
}

export interface TwilioFleetBySidVariables {
  sid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SyncSalesforce
// ====================================================

export interface SyncSalesforce {
  synchronizeSalesforce: boolean;
}

export interface SyncSalesforceVariables {
  customerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AssignPhoneNumber
// ====================================================

export interface AssignPhoneNumber_assignPhoneNumber {
  id: string | null;
}

export interface AssignPhoneNumber {
  assignPhoneNumber: AssignPhoneNumber_assignPhoneNumber | null;
}

export interface AssignPhoneNumberVariables {
  customerId: string;
  smsLimit?: number | null;
  countryCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReleasePhoneNumber
// ====================================================

export interface ReleasePhoneNumber_releasePhoneNumber {
  id: string | null;
}

export interface ReleasePhoneNumber {
  releasePhoneNumber: ReleasePhoneNumber_releasePhoneNumber | null;
}

export interface ReleasePhoneNumberVariables {
  customerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePhoneNumberLimit
// ====================================================

export interface UpdatePhoneNumberLimit_updatePhoneNumberLimit {
  id: string | null;
  smsLimit: number | null;
}

export interface UpdatePhoneNumberLimit {
  updatePhoneNumberLimit: UpdatePhoneNumberLimit_updatePhoneNumberLimit | null;
}

export interface UpdatePhoneNumberLimitVariables {
  customerId: string;
  smsLimit: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PhoneNumberDetail
// ====================================================

export interface PhoneNumberDetail_customer {
  id: string;
  isTwilioAdded: boolean | null;
}

export interface PhoneNumberDetail_phoneNumber_smsProvider {
  value: string | null;
  label: string | null;
}

export interface PhoneNumberDetail_phoneNumber_smsSenderId {
  value: string | null;
  label: string | null;
}

export interface PhoneNumberDetail_phoneNumber_smsCountryCode {
  value: string | null;
  label: string | null;
}

export interface PhoneNumberDetail_phoneNumber_smsReplyCallback {
  value: string | null;
  label: string | null;
}

export interface PhoneNumberDetail_phoneNumber_smsSenderSid {
  value: string | null;
  label: string | null;
}

export interface PhoneNumberDetail_phoneNumber {
  id: string | null;
  smsLimit: number | null;
  country: string | null;
  defaultCountry: string | null;
  smsProvider: PhoneNumberDetail_phoneNumber_smsProvider | null;
  smsSenderId: PhoneNumberDetail_phoneNumber_smsSenderId | null;
  smsCountryCode: PhoneNumberDetail_phoneNumber_smsCountryCode | null;
  smsReplyCallback: PhoneNumberDetail_phoneNumber_smsReplyCallback | null;
  smsSenderSid: PhoneNumberDetail_phoneNumber_smsSenderSid | null;
}

export interface PhoneNumberDetail {
  customer: PhoneNumberDetail_customer | null;
  phoneNumber: PhoneNumberDetail_phoneNumber | null;
}

export interface PhoneNumberDetailVariables {
  customerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DocumentTypeList
// ====================================================

export interface DocumentTypeList_counts {
  documentTypes: number | null;
}

export interface DocumentTypeList_documentTypes {
  id: number;
  type: string;
  label: string;
  description: string;
}

export interface DocumentTypeList {
  counts: DocumentTypeList_counts | null;
  documentTypes: DocumentTypeList_documentTypes[] | null;
}

export interface DocumentTypeListVariables {
  id: string;
  limit?: number | null;
  start?: number | null;
  search?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportDocumentTypes
// ====================================================

export interface ExportDocumentTypes_exportDocumentTypes {
  type: string;
  label: string;
  description: string;
}

export interface ExportDocumentTypes {
  exportDocumentTypes: ExportDocumentTypes_exportDocumentTypes[] | null;
}

export interface ExportDocumentTypesVariables {
  customerId: string;
  ids?: (number | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportEmploymentTypes
// ====================================================

export interface ImportEmploymentTypes_importEmploymentTypes {
  id: number;
}

export interface ImportEmploymentTypes {
  importEmploymentTypes: (ImportEmploymentTypes_importEmploymentTypes | null)[] | null;
}

export interface ImportEmploymentTypesVariables {
  customerId: string;
  data: (EmploymentTypeImport | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveEmploymentTypes
// ====================================================

export interface RemoveEmploymentTypes {
  removeEmploymentTypes: number | null;
}

export interface RemoveEmploymentTypesVariables {
  customerId: string;
  ids: (number | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditEmploymentType
// ====================================================

export interface EditEmploymentType_editEmploymentType {
  id: number;
  name: string;
}

export interface EditEmploymentType {
  editEmploymentType: EditEmploymentType_editEmploymentType | null;
}

export interface EditEmploymentTypeVariables {
  customerId: string;
  id: number;
  data: EmploymentTypeEdit;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EmploymentTypeList
// ====================================================

export interface EmploymentTypeList_counts {
  employmentTypes: number | null;
}

export interface EmploymentTypeList_employmentTypes {
  id: number;
  name: string;
}

export interface EmploymentTypeList {
  counts: EmploymentTypeList_counts | null;
  employmentTypes: EmploymentTypeList_employmentTypes[] | null;
}

export interface EmploymentTypeListVariables {
  id: string;
  limit?: number | null;
  start?: number | null;
  search?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportEmploymentTypes
// ====================================================

export interface ExportEmploymentTypes_exportEmploymentTypes {
  name: string;
}

export interface ExportEmploymentTypes {
  exportEmploymentTypes: ExportEmploymentTypes_exportEmploymentTypes[] | null;
}

export interface ExportEmploymentTypesVariables {
  customerId: string;
  ids?: (number | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EmploymentTypeFormData
// ====================================================

export interface EmploymentTypeFormData_employmentType {
  id: number;
  name: string;
}

export interface EmploymentTypeFormData {
  employmentType: EmploymentTypeFormData_employmentType | null;
}

export interface EmploymentTypeFormDataVariables {
  customerId: string;
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportForms
// ====================================================

export interface ImportForms_importForms {
  id: number;
}

export interface ImportForms {
  importForms: (ImportForms_importForms | null)[] | null;
}

export interface ImportFormsVariables {
  customerId: string;
  data: (FormImport | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleFormTemplate
// ====================================================

export interface ToggleFormTemplate_editForm {
  id: number;
  isTemplate: boolean;
}

export interface ToggleFormTemplate {
  editForm: ToggleFormTemplate_editForm | null;
}

export interface ToggleFormTemplateVariables {
  customerId: string;
  id: number;
  value: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleFormResultsEditable
// ====================================================

export interface ToggleFormResultsEditable_editForm {
  id: number;
  areResultsEditable: boolean | null;
}

export interface ToggleFormResultsEditable {
  editForm: ToggleFormResultsEditable_editForm | null;
}

export interface ToggleFormResultsEditableVariables {
  customerId: string;
  id: number;
  value: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveForms
// ====================================================

export interface RemoveForms {
  removeForms: number | null;
}

export interface RemoveFormsVariables {
  customerId: string;
  ids: (number | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditFormActive
// ====================================================

export interface EditFormActive_editForm {
  id: number;
  isActive: boolean | null;
}

export interface EditFormActive {
  editForm: EditFormActive_editForm | null;
}

export interface EditFormActiveVariables {
  customerId: string;
  userId: number;
  isActive: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FormList
// ====================================================

export interface FormList_counts {
  forms: number | null;
}

export interface FormList_forms {
  id: number;
  title: string;
  description: string | null;
  printTemplate: string | null;
  isActive: boolean | null;
  isArchived: boolean | null;
  isTemplate: boolean;
  areResultsEditable: boolean | null;
  requireClient: boolean | null;
  requireForTimesheets: boolean | null;
  requireGeolocation: boolean | null;
  requireMachine: boolean | null;
  requireProject: boolean | null;
  requireSupplier: boolean | null;
  requireWorker: boolean | null;
}

export interface FormList {
  counts: FormList_counts | null;
  forms: FormList_forms[] | null;
}

export interface FormListVariables {
  id: string;
  limit?: number | null;
  start?: number | null;
  exact?: FormSearchExact | null;
  search?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FormListDetail
// ====================================================

export interface FormListDetail_form {
  id: number;
  displayMeta: string | null;
  settings: string | null;
}

export interface FormListDetail {
  form: FormListDetail_form | null;
}

export interface FormListDetailVariables {
  customerId: string;
  formId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportForms
// ====================================================

export interface ExportForms_exportForms {
  title: string;
  description: string | null;
  isActive: boolean | null;
  isArchived: boolean | null;
  isTemplate: boolean;
  areResultsEditable: boolean | null;
  requireClient: boolean | null;
  requireForTimesheets: boolean | null;
  requireMachine: boolean | null;
  requireProject: boolean | null;
  requireSupplier: boolean | null;
  requireWorker: boolean | null;
  displayMeta: string | null;
  settings: string | null;
  printTemplate: string | null;
  submitButtonLabel: string | null;
  confirmationMessage: string | null;
  defaultStatusId: number | null;
}

export interface ExportForms {
  exportForms: ExportForms_exportForms[] | null;
}

export interface ExportFormsVariables {
  customerId: string;
  ids?: (number | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportInductions
// ====================================================

export interface ImportInductions_importInductions {
  id: number;
}

export interface ImportInductions {
  importInductions: (ImportInductions_importInductions | null)[] | null;
}

export interface ImportInductionsVariables {
  customerId: string;
  data: (InductionImport | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveInductions
// ====================================================

export interface RemoveInductions {
  removeInductions: number | null;
}

export interface RemoveInductionsVariables {
  customerId: string;
  ids: (number | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InductionList
// ====================================================

export interface InductionList_counts {
  inductions: number | null;
}

export interface InductionList_inductions {
  id: number;
  name: string;
  requiresExpirationDate: boolean | null;
  requiresIssueDate: boolean | null;
  clientId: number | null;
  projectId: number | null;
}

export interface InductionList {
  counts: InductionList_counts | null;
  inductions: InductionList_inductions[] | null;
}

export interface InductionListVariables {
  id: string;
  limit?: number | null;
  start?: number | null;
  search?: string | null;
  exact?: InductionSearchExact | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InductionUsersFormData
// ====================================================

export interface InductionUsersFormData_users {
  id: number;
  fullName: string | null;
}

export interface InductionUsersFormData_induction_inductionUsers {
  id: number | null;
  userId: number | null;
  issueDate: any | null;
  expirationDate: any | null;
  inductionId: number | null;
}

export interface InductionUsersFormData_induction {
  id: number;
  name: string;
  requiresExpirationDate: boolean | null;
  requiresIssueDate: boolean | null;
  inductionUsers: InductionUsersFormData_induction_inductionUsers[] | null;
}

export interface InductionUsersFormData {
  users: InductionUsersFormData_users[] | null;
  induction: InductionUsersFormData_induction | null;
}

export interface InductionUsersFormDataVariables {
  customerId: string;
  inductionId: number;
  userTypeId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportInductions
// ====================================================

export interface ExportInductions_exportInductions {
  name: string;
  clientId: number | null;
  projectId: number | null;
  requiresExpirationDate: boolean | null;
  requiresIssueDate: boolean | null;
}

export interface ExportInductions {
  exportInductions: ExportInductions_exportInductions[] | null;
}

export interface ExportInductionsVariables {
  customerId: string;
  ids?: (number | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PhoneNumberList
// ====================================================

export interface PhoneNumberList_phoneNumbers {
  id: string | null;
  friendlyName: string | null;
  phoneNumber: string | null;
  hasCustomer: boolean | null;
  createdDateTime: string | null;
  modifiedDateTime: string | null;
}

export interface PhoneNumberList {
  phoneNumbers: (PhoneNumberList_phoneNumbers | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PhoneNumberCount
// ====================================================

export interface PhoneNumberCount_counts {
  /**
   * Total number of phone numbers
   */
  total_phone_numbers: number | null;
  /**
   * Total number of phone numbers which are assigned to customer
   */
  active_phone_numbers: number | null;
}

export interface PhoneNumberCount {
  counts: PhoneNumberCount_counts | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditProject
// ====================================================

export interface EditProject_editProject_contacts {
  id: number;
  firstName: string | null;
  lastName: string | null;
}

export interface EditProject_editProject {
  id: number;
  contacts: EditProject_editProject_contacts[] | null;
}

export interface EditProject {
  editProject: EditProject_editProject | null;
}

export interface EditProjectVariables {
  customerId: string;
  id: number;
  data: ProjectEdit;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportProjects
// ====================================================

export interface ImportProjects_importProjects {
  id: number;
}

export interface ImportProjects {
  importProjects: (ImportProjects_importProjects | null)[] | null;
}

export interface ImportProjectsVariables {
  customerId: string;
  data: (ProjectImport | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveProjects
// ====================================================

export interface RemoveProjects {
  removeProjects: number | null;
}

export interface RemoveProjectsVariables {
  customerId: string;
  ids: (number | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectList
// ====================================================

export interface ProjectList_counts {
  projects: number | null;
}

export interface ProjectList_projects_client {
  name: string;
}

export interface ProjectList_projects_contacts {
  id: number;
  firstName: string | null;
  lastName: string | null;
}

export interface ProjectList_projects {
  id: number;
  isActive: boolean;
  name: string;
  companyId: number | null;
  email: string | null;
  externalId: string | null;
  client: ProjectList_projects_client | null;
  contacts: ProjectList_projects_contacts[] | null;
}

export interface ProjectList {
  counts: ProjectList_counts | null;
  projects: ProjectList_projects[] | null;
}

export interface ProjectListVariables {
  id: string;
  limit?: number | null;
  start?: number | null;
  search?: string | null;
  exact?: ProjectSearchExact | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectContactsFormData
// ====================================================

export interface ProjectContactsFormData_contacts {
  id: number;
  firstName: string | null;
  lastName: string | null;
}

export interface ProjectContactsFormData_project_contacts {
  id: number;
  firstName: string | null;
  lastName: string | null;
}

export interface ProjectContactsFormData_project {
  id: number;
  name: string;
  contacts: ProjectContactsFormData_project_contacts[] | null;
}

export interface ProjectContactsFormData {
  contacts: ProjectContactsFormData_contacts[] | null;
  project: ProjectContactsFormData_project | null;
}

export interface ProjectContactsFormDataVariables {
  customerId: string;
  projectId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectListDetail
// ====================================================

export interface ProjectListDetail_project {
  id: number;
  address: string | null;
  state: string | null;
  suburb: string | null;
  postcode: string | null;
}

export interface ProjectListDetail {
  project: ProjectListDetail_project | null;
}

export interface ProjectListDetailVariables {
  customerId: string;
  projectId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportProjects
// ====================================================

export interface ExportProjects_exportProjects {
  name: string;
  companyId: number | null;
  externalId: string | null;
  email: string | null;
  address: string | null;
  postcode: string | null;
  state: string | null;
  suburb: string | null;
}

export interface ExportProjects {
  exportProjects: ExportProjects_exportProjects[] | null;
}

export interface ExportProjectsVariables {
  customerId: string;
  ids?: (number | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportSuppliers
// ====================================================

export interface ImportSuppliers_importSuppliers {
  id: number;
}

export interface ImportSuppliers {
  importSuppliers: (ImportSuppliers_importSuppliers | null)[] | null;
}

export interface ImportSuppliersVariables {
  customerId: string;
  data: (SupplierImport | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveSuppliers
// ====================================================

export interface RemoveSuppliers {
  removeSuppliers: number | null;
}

export interface RemoveSuppliersVariables {
  customerId: string;
  ids: (number | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditSupplier
// ====================================================

export interface EditSupplier_editSupplier_contacts {
  id: number;
  firstName: string | null;
  lastName: string | null;
}

export interface EditSupplier_editSupplier {
  id: number;
  contacts: EditSupplier_editSupplier_contacts[] | null;
}

export interface EditSupplier {
  editSupplier: EditSupplier_editSupplier | null;
}

export interface EditSupplierVariables {
  customerId: string;
  id: number;
  data: SupplierEdit;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SupplierList
// ====================================================

export interface SupplierList_counts {
  suppliers: number | null;
}

export interface SupplierList_suppliers {
  id: number;
  name: string;
  isActive: boolean | null;
  abn: string | null;
  externalId: string | null;
}

export interface SupplierList {
  counts: SupplierList_counts | null;
  suppliers: SupplierList_suppliers[] | null;
}

export interface SupplierListVariables {
  id: string;
  limit?: number | null;
  start?: number | null;
  search?: string | null;
  exact?: SupplierSearchExact | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SupplierListDetail
// ====================================================

export interface SupplierListDetail_supplier {
  id: number;
  email: string | null;
  officePhone: string | null;
  mobile: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  postcode: string | null;
  state: string | null;
  comments: string | null;
}

export interface SupplierListDetail {
  supplier: SupplierListDetail_supplier | null;
}

export interface SupplierListDetailVariables {
  customerId: string;
  supplierId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportSuppliers
// ====================================================

export interface ExportSuppliers_exportSuppliers {
  name: string;
  isActive: boolean | null;
  abn: string | null;
  externalId: string | null;
  email: string | null;
  mobile: string | null;
  officePhone: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  postcode: string | null;
  state: string | null;
  comments: string | null;
}

export interface ExportSuppliers {
  exportSuppliers: ExportSuppliers_exportSuppliers[] | null;
}

export interface ExportSuppliersVariables {
  customerId: string;
  ids?: (number | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SupplierContactsFormData
// ====================================================

export interface SupplierContactsFormData_contacts {
  id: number;
  firstName: string | null;
  lastName: string | null;
}

export interface SupplierContactsFormData_supplier_contacts {
  id: number;
  firstName: string | null;
  lastName: string | null;
}

export interface SupplierContactsFormData_supplier {
  id: number;
  name: string;
  contacts: SupplierContactsFormData_supplier_contacts[] | null;
}

export interface SupplierContactsFormData {
  contacts: SupplierContactsFormData_contacts[] | null;
  supplier: SupplierContactsFormData_supplier | null;
}

export interface SupplierContactsFormDataVariables {
  customerId: string;
  supplierId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportTasks
// ====================================================

export interface ImportTasks_importTasks {
  id: number | null;
}

export interface ImportTasks {
  importTasks: (ImportTasks_importTasks | null)[] | null;
}

export interface ImportTasksVariables {
  customerId: string;
  data: (TaskImport | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveTasks
// ====================================================

export interface RemoveTasks {
  removeTasks: number | null;
}

export interface RemoveTasksVariables {
  customerId: string;
  ids: (number | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TaskList
// ====================================================

export interface TaskList_counts {
  tasks: number | null;
}

export interface TaskList_tasks_chargeCode {
  code: string | null;
  label: string | null;
}

export interface TaskList_tasks {
  id: number | null;
  isActive: boolean | null;
  requiresAssets: boolean | null;
  chargeCode: TaskList_tasks_chargeCode | null;
  chargeCodeId: number | null;
  name: string | null;
  payLevel: string | null;
  payrollAccount: string | null;
  isSupervisor: boolean | null;
}

export interface TaskList {
  counts: TaskList_counts | null;
  tasks: TaskList_tasks[] | null;
}

export interface TaskListVariables {
  id: string;
  limit?: number | null;
  start?: number | null;
  search?: string | null;
  exact?: TaskSearchExact | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportTasks
// ====================================================

export interface ExportTasks_exportTasks {
  name: string | null;
  isSupervisor: boolean | null;
  requiresAssets: boolean | null;
}

export interface ExportTasks {
  exportTasks: ExportTasks_exportTasks[] | null;
}

export interface ExportTasksVariables {
  customerId: string;
  ids?: (number | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportUsers
// ====================================================

export interface ImportUsers_importUsers {
  id: number;
}

export interface ImportUsers {
  importUsers: (ImportUsers_importUsers | null)[] | null;
}

export interface ImportUsersVariables {
  customerId: string;
  data: (UserImport | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditUser
// ====================================================

export interface EditUser_editUser_employmentType {
  name: string;
}

export interface EditUser_editUser {
  isActive: boolean;
  isExpired: boolean | null;
  id: number;
  employeeId: string | null;
  username: string;
  fullName: string | null;
  usi: string | null;
  dob: any | null;
  employmentType: EditUser_editUser_employmentType | null;
  email: string | null;
  address: string | null;
  suburb: string | null;
  state: string | null;
  postcode: string | null;
  contact: string | null;
  emergencyContact: string | null;
  emergencyContactName: string | null;
  appVersion: string | null;
  deviceOs: string | null;
  deviceOsVersion: string | null;
}

export interface EditUser {
  editUser: EditUser_editUser | null;
}

export interface EditUserVariables {
  customerId: string;
  userId: number;
  user: UserEdit;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveUser
// ====================================================

export interface ArchiveUser_editUser {
  id: number;
  isActive: boolean;
}

export interface ArchiveUser {
  editUser: ArchiveUser_editUser | null;
}

export interface ArchiveUserVariables {
  customerId: string;
  id: number;
  isActive: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditUserCompetencies
// ====================================================

export interface EditUserCompetencies_saveUserCompetencies {
  id: number | null;
}

export interface EditUserCompetencies {
  saveUserCompetencies: EditUserCompetencies_saveUserCompetencies[] | null;
  removeUserCompetencies: number | null;
}

export interface EditUserCompetenciesVariables {
  customerId: string;
  userCompetencies: (UserCompetencyInput | null)[];
  removed: (number | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditUserInductions
// ====================================================

export interface EditUserInductions_saveUserInductions {
  id: number | null;
}

export interface EditUserInductions {
  saveUserInductions: EditUserInductions_saveUserInductions[] | null;
  removeUserInductions: number | null;
}

export interface EditUserInductionsVariables {
  customerId: string;
  userInductions: (UserInductionInput | null)[];
  removed: (number | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateTemporaryUser
// ====================================================

export interface CreateTemporaryUser_createTemporaryUser_userType {
  label: string | null;
}

export interface CreateTemporaryUser_createTemporaryUser {
  expirationTime: any | null;
  username: string;
  password: string;
  userType: CreateTemporaryUser_createTemporaryUser_userType | null;
}

export interface CreateTemporaryUser {
  createTemporaryUser: CreateTemporaryUser_createTemporaryUser | null;
}

export interface CreateTemporaryUserVariables {
  customerId: string;
  userTypeId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateApiUser
// ====================================================

export interface CreateApiUser_createApiUser_userType {
  label: string | null;
}

export interface CreateApiUser_createApiUser {
  expirationTime: any | null;
  username: string;
  password: string;
  userType: CreateApiUser_createApiUser_userType | null;
}

export interface CreateApiUser {
  createApiUser: CreateApiUser_createApiUser | null;
}

export interface CreateApiUserVariables {
  customerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveUsers
// ====================================================

export interface RemoveUsers {
  removeUsers: number | null;
}

export interface RemoveUsersVariables {
  customerId: string;
  ids: (number | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserList
// ====================================================

export interface UserList_counts {
  users: number | null;
}

export interface UserList_users_employmentType {
  name: string;
}

export interface UserList_users {
  isActive: boolean;
  isExpired: boolean | null;
  id: number;
  employeeId: string | null;
  username: string;
  fullName: string | null;
  usi: string | null;
  dob: any | null;
  employmentType: UserList_users_employmentType | null;
}

export interface UserList {
  counts: UserList_counts | null;
  users: UserList_users[] | null;
}

export interface UserListVariables {
  id: string;
  limit?: number | null;
  start?: number | null;
  search?: string | null;
  exact?: UserSearchExact | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportUsers
// ====================================================

export interface ExportUsers_exportUsers {
  username: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  contact: string | null;
  emergencyContact: string | null;
  emergencyContactName: string | null;
  employeeId: string | null;
  employmentTypeId: number | null;
  address: string | null;
  postcode: string | null;
  state: string | null;
  suburb: string | null;
  usi: string | null;
  dob: any | null;
}

export interface ExportUsers {
  exportUsers: ExportUsers_exportUsers[] | null;
}

export interface ExportUsersVariables {
  customerId: string;
  ids?: (number | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserListDetail
// ====================================================

export interface UserListDetail_user {
  id: number;
  email: string | null;
  address: string | null;
  suburb: string | null;
  state: string | null;
  postcode: string | null;
  contact: string | null;
  emergencyContact: string | null;
  emergencyContactName: string | null;
  appVersion: string | null;
  deviceOs: string | null;
  deviceOsVersion: string | null;
}

export interface UserListDetail {
  user: UserListDetail_user | null;
}

export interface UserListDetailVariables {
  customerId: string;
  userId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserEditForm
// ====================================================

export interface UserEditForm_user_rights {
  id: number;
  restrictive: boolean | null;
}

export interface UserEditForm_user {
  id: number;
  isActive: boolean;
  username: string;
  firstName: string | null;
  dob: any | null;
  lastName: string | null;
  fullName: string | null;
  employmentTypeId: number | null;
  address: string | null;
  state: string | null;
  suburb: string | null;
  postcode: string | null;
  contact: string | null;
  emergencyContact: string | null;
  userTypeId: number;
  emergencyContactName: string | null;
  rights: UserEditForm_user_rights[] | null;
}

export interface UserEditForm {
  user: UserEditForm_user | null;
}

export interface UserEditFormVariables {
  customerId: string;
  id?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserRoles
// ====================================================

export interface UserRoles_userRoles {
  id: number;
  name: string;
  restrictive: boolean | null;
}

export interface UserRoles {
  userRoles: UserRoles_userRoles[] | null;
}

export interface UserRolesVariables {
  customerId: string;
  dashboardUser?: boolean | null;
  fieldworker?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserCompetenciesFormData
// ====================================================

export interface UserCompetenciesFormData_user_userCompetencies {
  id: number | null;
  issueDate: any | null;
  expirationDate: any | null;
  description: string | null;
  competencyId: number | null;
}

export interface UserCompetenciesFormData_user {
  id: number;
  username: string;
  userCompetencies: UserCompetenciesFormData_user_userCompetencies[] | null;
}

export interface UserCompetenciesFormData_competencies {
  id: number;
  requiresExpirationDate: boolean;
  requiresIssueDate: boolean;
  name: string;
}

export interface UserCompetenciesFormData {
  user: UserCompetenciesFormData_user | null;
  competencies: UserCompetenciesFormData_competencies[] | null;
}

export interface UserCompetenciesFormDataVariables {
  customerId: string;
  userId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserInductionsFormData
// ====================================================

export interface UserInductionsFormData_user_userInductions {
  id: number | null;
  expirationDate: any | null;
  issueDate: any | null;
  inductionId: number | null;
}

export interface UserInductionsFormData_user {
  id: number;
  username: string;
  userInductions: UserInductionsFormData_user_userInductions[] | null;
}

export interface UserInductionsFormData_inductions {
  id: number;
  requiresExpirationDate: boolean | null;
  requiresIssueDate: boolean | null;
  name: string;
}

export interface UserInductionsFormData {
  user: UserInductionsFormData_user | null;
  inductions: UserInductionsFormData_inductions[] | null;
}

export interface UserInductionsFormDataVariables {
  customerId: string;
  userId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportWebhookModules
// ====================================================

export interface ImportWebhookModules_importWebhookModules {
  id: number;
}

export interface ImportWebhookModules {
  importWebhookModules: (ImportWebhookModules_importWebhookModules | null)[] | null;
}

export interface ImportWebhookModulesVariables {
  customerId: string;
  data: (WebhookModuleImport | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveWebhookModules
// ====================================================

export interface RemoveWebhookModules {
  removeWebhookModules: number | null;
}

export interface RemoveWebhookModulesVariables {
  customerId: string;
  ids: (number | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditWebhookModule
// ====================================================

export interface EditWebhookModule_editWebhookModule {
  id: number;
  moduleName: string;
  methodName: string;
  version: number;
  deprecationDate: any | null;
}

export interface EditWebhookModule {
  editWebhookModule: EditWebhookModule_editWebhookModule | null;
}

export interface EditWebhookModuleVariables {
  customerId: string;
  id: number;
  data: WebhookModuleEdit;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: WebhookModuleList
// ====================================================

export interface WebhookModuleList_counts {
  webhookModules: number | null;
}

export interface WebhookModuleList_webhookModules {
  id: number;
  moduleName: string;
  methodName: string;
  version: number;
  deprecationDate: any | null;
}

export interface WebhookModuleList {
  counts: WebhookModuleList_counts | null;
  webhookModules: WebhookModuleList_webhookModules[] | null;
}

export interface WebhookModuleListVariables {
  id: string;
  limit?: number | null;
  start?: number | null;
  search?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportWebhookModules
// ====================================================

export interface ExportWebhookModules_exportWebhookModules {
  moduleName: string;
  methodName: string;
  version: number;
  deprecationDate: any | null;
}

export interface ExportWebhookModules {
  exportWebhookModules: ExportWebhookModules_exportWebhookModules[] | null;
}

export interface ExportWebhookModulesVariables {
  customerId: string;
  ids?: (number | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: WebhookModuleFormData
// ====================================================

export interface WebhookModuleFormData_webhookModule {
  id: number;
  moduleName: string;
  methodName: string;
  version: number;
  deprecationDate: any | null;
}

export interface WebhookModuleFormData {
  webhookModule: WebhookModuleFormData_webhookModule | null;
}

export interface WebhookModuleFormDataVariables {
  customerId: string;
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportWebhooks
// ====================================================

export interface ImportWebhooks_importWebhooks {
  id: number;
}

export interface ImportWebhooks {
  importWebhooks: (ImportWebhooks_importWebhooks | null)[] | null;
}

export interface ImportWebhooksVariables {
  customerId: string;
  data: (WebhookImport | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveWebhooks
// ====================================================

export interface RemoveWebhooks {
  removeWebhooks: number | null;
}

export interface RemoveWebhooksVariables {
  customerId: string;
  ids: (number | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditWebhook
// ====================================================

export interface EditWebhook_editWebhook {
  id: number;
  name: string;
  secretKey: string | null;
  url: string;
  webhookModuleId: number;
  isActive: boolean;
}

export interface EditWebhook {
  editWebhook: EditWebhook_editWebhook | null;
}

export interface EditWebhookVariables {
  customerId: string;
  id: number;
  data: WebhookEdit;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveWebhook
// ====================================================

export interface ArchiveWebhook_editWebhook {
  id: number;
  isActive: boolean;
}

export interface ArchiveWebhook {
  editWebhook: ArchiveWebhook_editWebhook | null;
}

export interface ArchiveWebhookVariables {
  customerId: string;
  id: number;
  isActive: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: WebhookList
// ====================================================

export interface WebhookList_counts {
  webhooks: number | null;
}

export interface WebhookList_webhooks_createdBy {
  username: string;
}

export interface WebhookList_webhooks_webhookModule {
  id: number;
  moduleName: string;
  methodName: string;
  version: number;
}

export interface WebhookList_webhooks {
  id: number;
  name: string;
  secretKey: string | null;
  url: string;
  createdBy: WebhookList_webhooks_createdBy | null;
  createdTime: any;
  isActive: boolean;
  webhookModuleId: number;
  webhookModule: WebhookList_webhooks_webhookModule | null;
}

export interface WebhookList {
  counts: WebhookList_counts | null;
  webhooks: WebhookList_webhooks[] | null;
}

export interface WebhookListVariables {
  id: string;
  limit?: number | null;
  start?: number | null;
  search?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportWebhooks
// ====================================================

export interface ExportWebhooks_exportWebhooks {
  name: string;
  secretKey: string | null;
  url: string;
  webhookModuleId: number;
  isActive: boolean;
}

export interface ExportWebhooks {
  exportWebhooks: ExportWebhooks_exportWebhooks[] | null;
}

export interface ExportWebhooksVariables {
  customerId: string;
  ids?: (number | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: WebhookFormData
// ====================================================

export interface WebhookFormData_webhook {
  id: number;
  name: string;
  secretKey: string | null;
  url: string;
  webhookModuleId: number;
}

export interface WebhookFormData {
  webhook: WebhookFormData_webhook | null;
}

export interface WebhookFormDataVariables {
  customerId: string;
  id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ImportWorkItems
// ====================================================

export interface ImportWorkItems_importWorkItems {
  id: number | null;
}

export interface ImportWorkItems {
  importWorkItems: (ImportWorkItems_importWorkItems | null)[] | null;
}

export interface ImportWorkItemsVariables {
  customerId: string;
  data: (WorkItemImport | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveWorkItems
// ====================================================

export interface RemoveWorkItems {
  removeWorkItems: number | null;
}

export interface RemoveWorkItemsVariables {
  customerId: string;
  ids: (number | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: WorkItemList
// ====================================================

export interface WorkItemList_counts {
  workItems: number | null;
}

export interface WorkItemList_workItems {
  id: number | null;
  code: string | null;
  label: string | null;
}

export interface WorkItemList {
  counts: WorkItemList_counts | null;
  workItems: WorkItemList_workItems[] | null;
}

export interface WorkItemListVariables {
  id: string;
  limit?: number | null;
  start?: number | null;
  search?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportWorkItems
// ====================================================

export interface ExportWorkItems_exportWorkItems {
  code: string | null;
  label: string | null;
}

export interface ExportWorkItems {
  exportWorkItems: ExportWorkItems_exportWorkItems[] | null;
}

export interface ExportWorkItemsVariables {
  customerId: string;
  ids?: (number | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdminUserData
// ====================================================

export interface AdminUserData {
  /**
   * We are using username as ID because Cognito doesn't provide anything better
   */
  id: string;
  username: string;
  firstName: string | null;
  lastName: string | null;
  isActive: boolean;
  status: string | null;
  email: string | null;
  role: AdminUserRole | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: assetAttachment
// ====================================================

export interface assetAttachment {
  id: number;
  chargeRate: number | null;
  description: string | null;
  isActive: boolean;
  make: string | null;
  model: string | null;
  name: string;
  numberReading: number | null;
  registrationNumber: string | null;
  serialNumber: string | null;
  year: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: assetGPSDevice
// ====================================================

export interface assetGPSDevice {
  id: number;
  deviceTypeId: number;
  deviceTypeMapperId: number;
  imei: string;
  isActive: boolean;
  modifiedAt: any;
  sim: string;
  vehicleId: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: assetAttachments
// ====================================================

export interface assetAttachments_attachments {
  id: number;
  name: string;
}

export interface assetAttachments {
  attachments: assetAttachments_attachments[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: asset
// ====================================================

export interface asset {
  id: number;
  isActive: boolean;
  externalId: string | null;
  name: string;
  description: string | null;
  make: string | null;
  model: string | null;
  year: string | null;
  regNumber: string | null;
  serialNumber: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: chargeCode
// ====================================================

export interface chargeCode {
  id: number;
  label: string | null;
  code: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: clientContacts
// ====================================================

export interface clientContacts_contacts {
  id: number;
  firstName: string | null;
  lastName: string | null;
}

export interface clientContacts {
  contacts: clientContacts_contacts[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: client
// ====================================================

export interface client {
  id: number;
  isActive: boolean;
  name: string;
  abn: string | null;
  externalId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: competency
// ====================================================

export interface competency {
  id: number;
  name: string;
  licenseNumber: string | null;
  requiresExpirationDate: boolean;
  requiresIssueDate: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CustomerData
// ====================================================

export interface CustomerData_insightsViewerUsage_users {
  id: number;
  fullName: string | null;
}

export interface CustomerData_insightsViewerUsage {
  /**
   * Number of viewer seats allocated for Insights.
   */
  allocated: number | null;
  /**
   * Number of viewer seats already assigned to users.
   */
  users: CustomerData_insightsViewerUsage_users[] | null;
}

export interface CustomerData_insightsCreatorUsage_users {
  id: number;
  fullName: string | null;
}

export interface CustomerData_insightsCreatorUsage {
  /**
   * Number of creator seats allocated for Insights.
   */
  allocated: number | null;
  /**
   * Number of creator seats already assigned to users.
   */
  users: CustomerData_insightsCreatorUsage_users[] | null;
}

export interface CustomerData {
  addedDateTime: string | null;
  expirationDateTime: string | null;
  id: string;
  isActive: boolean | null;
  isTwilioAdded: boolean | null;
  loginId: string | null;
  name: string | null;
  subscription: string | null;
  smsLimit: number | null;
  /**
   * Insights Viewer License Usage
   */
  insightsViewerUsage: CustomerData_insightsViewerUsage | null;
  /**
   * Insights Viewer License Usage
   */
  insightsCreatorUsage: CustomerData_insightsCreatorUsage | null;
  /**
   * Quota for number of users
   */
  userQuota: number | null;
  isTemplate: boolean | null;
  /**
   * Customer creation status
   */
  status: string | null;
  isCustomerMessageEnabled: boolean | null;
  customerMessage: string | null;
  /**
   * Does the customer also use GPS Gate
   */
  isGpsCustomer: boolean | null;
  /**
   * GPS Gate Application Tenant Id
   */
  gpsApplicationID: number | null;
  /**
   * Twilio IoT Fleet SID
   */
  twilioFleetSID: string | null;
  /**
   * Salesforce ID
   */
  salesforceID: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InductionUsers
// ====================================================

export interface InductionUsers_inductionUsers {
  id: number | null;
  userId: number | null;
  issueDate: any | null;
  expirationDate: any | null;
  inductionId: number | null;
}

export interface InductionUsers {
  inductionUsers: InductionUsers_inductionUsers[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: supplierContacts
// ====================================================

export interface supplierContacts_contacts {
  id: number;
  firstName: string | null;
  lastName: string | null;
}

export interface supplierContacts {
  contacts: supplierContacts_contacts[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserListData
// ====================================================

export interface UserListData_employmentType {
  name: string;
}

export interface UserListData {
  isActive: boolean;
  isExpired: boolean | null;
  id: number;
  employeeId: string | null;
  username: string;
  fullName: string | null;
  usi: string | null;
  dob: any | null;
  employmentType: UserListData_employmentType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserListDetailData
// ====================================================

export interface UserListDetailData {
  id: number;
  email: string | null;
  address: string | null;
  suburb: string | null;
  state: string | null;
  postcode: string | null;
  contact: string | null;
  emergencyContact: string | null;
  emergencyContactName: string | null;
  appVersion: string | null;
  deviceOs: string | null;
  deviceOsVersion: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserEditForm
// ====================================================

export interface UserEditForm_rights {
  id: number;
  restrictive: boolean | null;
}

export interface UserEditForm {
  id: number;
  isActive: boolean;
  username: string;
  firstName: string | null;
  dob: any | null;
  lastName: string | null;
  fullName: string | null;
  employmentTypeId: number | null;
  address: string | null;
  state: string | null;
  suburb: string | null;
  postcode: string | null;
  contact: string | null;
  emergencyContact: string | null;
  userTypeId: number;
  emergencyContactName: string | null;
  rights: UserEditForm_rights[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserInductions
// ====================================================

export interface UserInductions_userInductions {
  id: number | null;
  expirationDate: any | null;
  issueDate: any | null;
  inductionId: number | null;
}

export interface UserInductions {
  userInductions: UserInductions_userInductions[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserCompetencies
// ====================================================

export interface UserCompetencies_userCompetencies {
  id: number | null;
  issueDate: any | null;
  expirationDate: any | null;
  description: string | null;
  competencyId: number | null;
}

export interface UserCompetencies {
  userCompetencies: UserCompetencies_userCompetencies[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: webhookModule
// ====================================================

export interface webhookModule_webhookModule {
  id: number;
  moduleName: string;
  methodName: string;
  version: number;
}

export interface webhookModule {
  webhookModule: webhookModule_webhookModule | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: webhook
// ====================================================

export interface webhook_createdBy {
  username: string;
}

export interface webhook_webhookModule {
  id: number;
  moduleName: string;
  methodName: string;
  version: number;
}

export interface webhook {
  id: number;
  name: string;
  secretKey: string | null;
  url: string;
  createdBy: webhook_createdBy | null;
  createdTime: any;
  isActive: boolean;
  webhookModuleId: number;
  webhookModule: webhook_webhookModule | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AdminUserRole {
  admin = "admin",
  cs = "cs",
  developer = "developer",
  sales = "sales",
}

export interface AssetAttachmentEdit {
  id?: number | null;
  chargeRate?: number | null;
  description?: string | null;
  isActive?: boolean | null;
  make?: string | null;
  model?: string | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  name?: string | null;
  numberReading?: number | null;
  registrationNumber?: string | null;
  serialNumber?: string | null;
  year?: string | null;
}

export interface AssetAttachmentImport {
  chargeRate?: number | null;
  description?: string | null;
  isActive?: boolean | null;
  make?: string | null;
  model?: string | null;
  name?: string | null;
  numberReading?: number | null;
  registrationNumber?: string | null;
  serialNumber?: string | null;
  year?: string | null;
}

export interface AssetAttachmentSearchExact {
  id?: number | null;
  chargeRate?: number | null;
  isActive?: boolean | null;
  numberReading?: number | null;
}

export interface AssetEdit {
  id?: number | null;
  attachments?: AssetEditAssetAttachment[] | null;
  chargeRate?: number | null;
  description?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  make?: string | null;
  model?: string | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  name?: string | null;
  numberReading?: number | null;
  regNumber?: string | null;
  serialNumber?: string | null;
  year?: string | null;
}

export interface AssetEditAssetAttachment {
  id?: number | null;
  name?: string | null;
  description?: string | null;
  registrationNumber?: string | null;
  year?: string | null;
  make?: string | null;
  model?: string | null;
  serialNumber?: string | null;
  chargeRate?: number | null;
  numberReading?: number | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
}

export interface AssetExactAssetAttachment {
  id?: number | null;
  chargeRate?: number | null;
  numberReading?: number | null;
  isActive?: boolean | null;
}

export interface AssetGPSDeviceEdit {
  id?: number | null;
  deviceTypeId?: number | null;
  deviceTypeMapperId?: number | null;
  imei?: string | null;
  isActive?: boolean | null;
  modifiedAt?: any | null;
  sim?: string | null;
  vehicleId?: number | null;
}

export interface AssetGPSDeviceImport {
  deviceTypeId?: number | null;
  deviceTypeMapperId?: number | null;
  imei?: string | null;
  isActive?: boolean | null;
  modifiedAt?: any | null;
  sim?: string | null;
  vehicleId?: number | null;
}

export interface AssetGPSDeviceSearchExact {
  id?: number | null;
  deviceTypeId?: number | null;
  deviceTypeMapperId?: number | null;
  isActive?: boolean | null;
  vehicleId?: number | null;
}

export interface AssetImport {
  attachments?: AssetImportAssetAttachment[] | null;
  chargeRate?: number | null;
  description?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  make?: string | null;
  model?: string | null;
  name?: string | null;
  numberReading?: number | null;
  regNumber?: string | null;
  serialNumber?: string | null;
  year?: string | null;
}

export interface AssetImportAssetAttachment {
  id?: number | null;
  name?: string | null;
  description?: string | null;
  registrationNumber?: string | null;
  year?: string | null;
  make?: string | null;
  model?: string | null;
  serialNumber?: string | null;
  chargeRate?: number | null;
  numberReading?: number | null;
  isActive?: boolean | null;
}

export interface AssetSearchExact {
  id?: number | null;
  attachments?: AssetExactAssetAttachment[] | null;
  chargeRate?: number | null;
  isActive?: boolean | null;
  numberReading?: number | null;
}

export interface ChargeCodeEdit {
  id?: number | null;
  code?: string | null;
  label?: string | null;
  tasks?: ChargeCodeEditTask[] | null;
}

export interface ChargeCodeEditTask {
  id?: number | null;
  name?: string | null;
  isActive?: boolean | null;
  externalId?: string | null;
  requiresAssets?: boolean | null;
  payLevel?: string | null;
  payrollAccount?: string | null;
  isSupervisor?: boolean | null;
  chargeCodeId?: number | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  chargeCode?: ChargeCodeEditTaskChargeCode | null;
}

export interface ChargeCodeEditTaskChargeCode {
  id?: number | null;
  code?: string | null;
  label?: string | null;
}

export interface ChargeCodeImport {
  code?: string | null;
  label?: string | null;
  tasks?: ChargeCodeImportTask[] | null;
}

export interface ChargeCodeImportTask {
  id?: number | null;
  name?: string | null;
  isActive?: boolean | null;
  externalId?: string | null;
  requiresAssets?: boolean | null;
  payLevel?: string | null;
  payrollAccount?: string | null;
  isSupervisor?: boolean | null;
  chargeCodeId?: number | null;
  chargeCode?: ChargeCodeImportTaskChargeCode | null;
}

export interface ChargeCodeImportTaskChargeCode {
  id?: number | null;
  code?: string | null;
  label?: string | null;
}

export interface ClientEdit {
  id?: number | null;
  abn?: string | null;
  contacts?: ClientEditContact[] | null;
  externalId?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  name?: string | null;
  projects?: ClientEditProject[] | null;
}

export interface ClientEditContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface ClientEditProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  companyId?: number | null;
  client?: ClientEditProjectClient | null;
  contacts?: ClientEditProjectContact[] | null;
}

export interface ClientEditProjectClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  contacts?: ClientEditProjectClientContact[] | null;
}

export interface ClientEditProjectClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface ClientEditProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface ClientExactContact {
  id?: number | null;
}

export interface ClientExactProject {
  id?: number | null;
  isActive?: boolean | null;
  companyId?: number | null;
  client?: ClientExactProjectClient | null;
  contacts?: ClientExactProjectContact[] | null;
}

export interface ClientExactProjectClient {
  id?: number | null;
  isActive?: boolean | null;
  contacts?: ClientExactProjectClientContact[] | null;
}

export interface ClientExactProjectClientContact {
  id?: number | null;
}

export interface ClientExactProjectContact {
  id?: number | null;
}

export interface ClientImport {
  abn?: string | null;
  contacts?: ClientImportContact[] | null;
  externalId?: string | null;
  isActive?: boolean | null;
  name?: string | null;
  projects?: ClientImportProject[] | null;
}

export interface ClientImportContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface ClientImportProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  companyId?: number | null;
  client?: ClientImportProjectClient | null;
  contacts?: ClientImportProjectContact[] | null;
}

export interface ClientImportProjectClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  contacts?: ClientImportProjectClientContact[] | null;
}

export interface ClientImportProjectClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface ClientImportProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface ClientSearchExact {
  id?: number | null;
  contacts?: ClientExactContact[] | null;
  isActive?: boolean | null;
  projects?: ClientExactProject[] | null;
}

export interface CompetencyEdit {
  id?: number | null;
  competencyUsers?: CompetencyEditUserCompetency[] | null;
  licenseNumber?: string | null;
  name?: string | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
}

export interface CompetencyEditUserCompetency {
  id?: number | null;
  description?: string | null;
  competencyId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  createdTime?: any | null;
  userCompetencyUser?: CompetencyEditUserCompetencyUser | null;
  userCompetencyCompetency?: CompetencyEditUserCompetencyCompetency | null;
}

export interface CompetencyEditUserCompetencyCompetency {
  id?: number | null;
  name?: string | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  licenseNumber?: string | null;
}

export interface CompetencyEditUserCompetencyUser {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  username?: string | null;
  isActive?: boolean | null;
  employeeId?: string | null;
  address?: string | null;
  suburb?: string | null;
  state?: string | null;
  postcode?: string | null;
  contact?: string | null;
  dob?: any | null;
  emergencyContact?: string | null;
  emergencyContactName?: string | null;
  usi?: string | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  expirationTime?: any | null;
  employmentTypeId?: number | null;
  userTypeId?: number | null;
  cryptedPassword?: string | null;
  isSystemUser?: boolean | null;
  appVersion?: string | null;
  deviceOs?: string | null;
  deviceOsVersion?: string | null;
  employmentType?: CompetencyEditUserCompetencyUserEmploymentType | null;
  userType?: CompetencyEditUserCompetencyUserUserType | null;
  rights?: CompetencyEditUserCompetencyUserUserRole[] | null;
  userInductions?: CompetencyEditUserCompetencyUserUserInduction[] | null;
}

export interface CompetencyEditUserCompetencyUserEmploymentType {
  id?: number | null;
  name?: string | null;
}

export interface CompetencyEditUserCompetencyUserUserInduction {
  id?: number | null;
  inductionId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  createdTime?: any | null;
  userInductionInduction?: CompetencyEditUserCompetencyUserUserInductionInduction | null;
}

export interface CompetencyEditUserCompetencyUserUserInductionInduction {
  id?: number | null;
  name?: string | null;
  clientId?: number | null;
  projectId?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  client?: CompetencyEditUserCompetencyUserUserInductionInductionClient | null;
  project?: CompetencyEditUserCompetencyUserUserInductionInductionProject | null;
}

export interface CompetencyEditUserCompetencyUserUserInductionInductionClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  projects?: CompetencyEditUserCompetencyUserUserInductionInductionClientProject[] | null;
  contacts?: CompetencyEditUserCompetencyUserUserInductionInductionClientContact[] | null;
}

export interface CompetencyEditUserCompetencyUserUserInductionInductionClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface CompetencyEditUserCompetencyUserUserInductionInductionClientProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  companyId?: number | null;
  contacts?: CompetencyEditUserCompetencyUserUserInductionInductionClientProjectContact[] | null;
}

export interface CompetencyEditUserCompetencyUserUserInductionInductionClientProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface CompetencyEditUserCompetencyUserUserInductionInductionProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  companyId?: number | null;
  client?: CompetencyEditUserCompetencyUserUserInductionInductionProjectClient | null;
  contacts?: CompetencyEditUserCompetencyUserUserInductionInductionProjectContact[] | null;
}

export interface CompetencyEditUserCompetencyUserUserInductionInductionProjectClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  contacts?: CompetencyEditUserCompetencyUserUserInductionInductionProjectClientContact[] | null;
}

export interface CompetencyEditUserCompetencyUserUserInductionInductionProjectClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface CompetencyEditUserCompetencyUserUserInductionInductionProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface CompetencyEditUserCompetencyUserUserRole {
  id?: number | null;
  name?: string | null;
  route?: string | null;
  isProtected?: boolean | null;
  isActive?: boolean | null;
  description?: string | null;
  dashboardUser?: boolean | null;
  fieldworker?: boolean | null;
  restrictive?: boolean | null;
}

export interface CompetencyEditUserCompetencyUserUserType {
  id?: number | null;
  userType?: string | null;
  label?: string | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
}

export interface CompetencyExactUserCompetency {
  id?: number | null;
  competencyId?: number | null;
  userId?: number | null;
  userCompetencyUser?: CompetencyExactUserCompetencyUser | null;
  userCompetencyCompetency?: CompetencyExactUserCompetencyCompetency | null;
}

export interface CompetencyExactUserCompetencyCompetency {
  id?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
}

export interface CompetencyExactUserCompetencyUser {
  id?: number | null;
  isActive?: boolean | null;
  employmentTypeId?: number | null;
  userTypeId?: number | null;
  isSystemUser?: boolean | null;
  employmentType?: CompetencyExactUserCompetencyUserEmploymentType | null;
  userType?: CompetencyExactUserCompetencyUserUserType | null;
  rights?: CompetencyExactUserCompetencyUserUserRole[] | null;
  userInductions?: CompetencyExactUserCompetencyUserUserInduction[] | null;
}

export interface CompetencyExactUserCompetencyUserEmploymentType {
  id?: number | null;
}

export interface CompetencyExactUserCompetencyUserUserInduction {
  id?: number | null;
  inductionId?: number | null;
  userId?: number | null;
  userInductionInduction?: CompetencyExactUserCompetencyUserUserInductionInduction | null;
}

export interface CompetencyExactUserCompetencyUserUserInductionInduction {
  id?: number | null;
  clientId?: number | null;
  projectId?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  client?: CompetencyExactUserCompetencyUserUserInductionInductionClient | null;
  project?: CompetencyExactUserCompetencyUserUserInductionInductionProject | null;
}

export interface CompetencyExactUserCompetencyUserUserInductionInductionClient {
  id?: number | null;
  isActive?: boolean | null;
  projects?: CompetencyExactUserCompetencyUserUserInductionInductionClientProject[] | null;
  contacts?: CompetencyExactUserCompetencyUserUserInductionInductionClientContact[] | null;
}

export interface CompetencyExactUserCompetencyUserUserInductionInductionClientContact {
  id?: number | null;
}

export interface CompetencyExactUserCompetencyUserUserInductionInductionClientProject {
  id?: number | null;
  isActive?: boolean | null;
  companyId?: number | null;
  contacts?: CompetencyExactUserCompetencyUserUserInductionInductionClientProjectContact[] | null;
}

export interface CompetencyExactUserCompetencyUserUserInductionInductionClientProjectContact {
  id?: number | null;
}

export interface CompetencyExactUserCompetencyUserUserInductionInductionProject {
  id?: number | null;
  isActive?: boolean | null;
  companyId?: number | null;
  client?: CompetencyExactUserCompetencyUserUserInductionInductionProjectClient | null;
  contacts?: CompetencyExactUserCompetencyUserUserInductionInductionProjectContact[] | null;
}

export interface CompetencyExactUserCompetencyUserUserInductionInductionProjectClient {
  id?: number | null;
  isActive?: boolean | null;
  contacts?: CompetencyExactUserCompetencyUserUserInductionInductionProjectClientContact[] | null;
}

export interface CompetencyExactUserCompetencyUserUserInductionInductionProjectClientContact {
  id?: number | null;
}

export interface CompetencyExactUserCompetencyUserUserInductionInductionProjectContact {
  id?: number | null;
}

export interface CompetencyExactUserCompetencyUserUserRole {
  id?: number | null;
  isProtected?: boolean | null;
  isActive?: boolean | null;
  dashboardUser?: boolean | null;
  fieldworker?: boolean | null;
  restrictive?: boolean | null;
}

export interface CompetencyExactUserCompetencyUserUserType {
  id?: number | null;
}

export interface CompetencyImport {
  competencyUsers?: CompetencyImportUserCompetency[] | null;
  licenseNumber?: string | null;
  name?: string | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
}

export interface CompetencyImportUserCompetency {
  id?: number | null;
  description?: string | null;
  competencyId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  userCompetencyUser?: CompetencyImportUserCompetencyUser | null;
  userCompetencyCompetency?: CompetencyImportUserCompetencyCompetency | null;
}

export interface CompetencyImportUserCompetencyCompetency {
  id?: number | null;
  name?: string | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  licenseNumber?: string | null;
}

export interface CompetencyImportUserCompetencyUser {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  username?: string | null;
  isActive?: boolean | null;
  employeeId?: string | null;
  address?: string | null;
  suburb?: string | null;
  state?: string | null;
  postcode?: string | null;
  contact?: string | null;
  dob?: any | null;
  emergencyContact?: string | null;
  emergencyContactName?: string | null;
  usi?: string | null;
  expirationTime?: any | null;
  employmentTypeId?: number | null;
  userTypeId?: number | null;
  isSystemUser?: boolean | null;
  appVersion?: string | null;
  deviceOs?: string | null;
  deviceOsVersion?: string | null;
  employmentType?: CompetencyImportUserCompetencyUserEmploymentType | null;
  userType?: CompetencyImportUserCompetencyUserUserType | null;
  rights?: CompetencyImportUserCompetencyUserUserRole[] | null;
  userInductions?: CompetencyImportUserCompetencyUserUserInduction[] | null;
}

export interface CompetencyImportUserCompetencyUserEmploymentType {
  id?: number | null;
  name?: string | null;
}

export interface CompetencyImportUserCompetencyUserUserInduction {
  id?: number | null;
  inductionId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  userInductionInduction?: CompetencyImportUserCompetencyUserUserInductionInduction | null;
}

export interface CompetencyImportUserCompetencyUserUserInductionInduction {
  id?: number | null;
  name?: string | null;
  clientId?: number | null;
  projectId?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  client?: CompetencyImportUserCompetencyUserUserInductionInductionClient | null;
  project?: CompetencyImportUserCompetencyUserUserInductionInductionProject | null;
}

export interface CompetencyImportUserCompetencyUserUserInductionInductionClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  projects?: CompetencyImportUserCompetencyUserUserInductionInductionClientProject[] | null;
  contacts?: CompetencyImportUserCompetencyUserUserInductionInductionClientContact[] | null;
}

export interface CompetencyImportUserCompetencyUserUserInductionInductionClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface CompetencyImportUserCompetencyUserUserInductionInductionClientProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  companyId?: number | null;
  contacts?: CompetencyImportUserCompetencyUserUserInductionInductionClientProjectContact[] | null;
}

export interface CompetencyImportUserCompetencyUserUserInductionInductionClientProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface CompetencyImportUserCompetencyUserUserInductionInductionProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  companyId?: number | null;
  client?: CompetencyImportUserCompetencyUserUserInductionInductionProjectClient | null;
  contacts?: CompetencyImportUserCompetencyUserUserInductionInductionProjectContact[] | null;
}

export interface CompetencyImportUserCompetencyUserUserInductionInductionProjectClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  contacts?: CompetencyImportUserCompetencyUserUserInductionInductionProjectClientContact[] | null;
}

export interface CompetencyImportUserCompetencyUserUserInductionInductionProjectClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface CompetencyImportUserCompetencyUserUserInductionInductionProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface CompetencyImportUserCompetencyUserUserRole {
  id?: number | null;
  name?: string | null;
  route?: string | null;
  isProtected?: boolean | null;
  isActive?: boolean | null;
  description?: string | null;
  dashboardUser?: boolean | null;
  fieldworker?: boolean | null;
  restrictive?: boolean | null;
}

export interface CompetencyImportUserCompetencyUserUserType {
  id?: number | null;
  userType?: string | null;
  label?: string | null;
}

export interface CompetencySearchExact {
  id?: number | null;
  competencyUsers?: CompetencyExactUserCompetency[] | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
}

export interface ContactImport {
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  comments?: string | null;
  company?: string | null;
  email?: string | null;
  firstName?: string | null;
  jobTitle?: string | null;
  lastName?: string | null;
  mobile?: string | null;
  officePhone?: string | null;
  postcode?: string | null;
  state?: string | null;
}

export interface CreateCustomerDataInput {
  id: string;
  loginId: string;
  name: string;
  isActive?: boolean | null;
  subscription?: string | null;
  isTemplate?: boolean | null;
  expirationDateTime?: number | null;
  userQuota?: number | null;
  insightsViewerQuota?: number | null;
  insightsCreatorQuota?: number | null;
  isCustomerMessageEnabled?: boolean | null;
  customerMessage?: string | null;
  isGpsCustomer?: boolean | null;
  gpsApplicationID?: number | null;
  twilioFleetSID?: string | null;
  salesforceID?: string | null;
}

export interface CustomerSearchExact {
  isActive?: boolean | null;
  isTwilioAdded?: boolean | null;
  isTemplate?: boolean | null;
  isInsightsEnabled?: boolean | null;
}

export interface EditCustomerDataInput {
  id: string;
  isActive?: boolean | null;
  loginId?: string | null;
  name?: string | null;
  subscription?: string | null;
  isTemplate?: boolean | null;
  expirationDateTime?: number | null;
  userQuota?: number | null;
  insightsViewerQuota?: number | null;
  insightsCreatorQuota?: number | null;
  isCustomerMessageEnabled?: boolean | null;
  customerMessage?: string | null;
  isGpsCustomer?: boolean | null;
  gpsApplicationID?: number | null;
  twilioFleetSID?: string | null;
  salesforceID?: string | null;
}

export interface EmploymentTypeEdit {
  id?: number | null;
  name?: string | null;
}

export interface EmploymentTypeImport {
  name?: string | null;
}

export interface FormImport {
  areResultsEditable?: boolean | null;
  confirmationMessage?: string | null;
  defaultStatusId?: number | null;
  description?: string | null;
  displayMeta?: string | null;
  isActive?: boolean | null;
  isArchived?: boolean | null;
  isTemplate?: boolean | null;
  notification?: string | null;
  printTemplate?: string | null;
  prompt?: string | null;
  requireClient?: boolean | null;
  requireForTimesheets?: boolean | null;
  requireGeolocation?: boolean | null;
  requireMachine?: boolean | null;
  requireProject?: boolean | null;
  requireSupplier?: boolean | null;
  requireWorker?: boolean | null;
  settings?: string | null;
  submitButtonLabel?: string | null;
  title?: string | null;
}

export interface FormSearchExact {
  id?: number | null;
  defaultStatusId?: number | null;
  isActive?: boolean | null;
  isArchived?: boolean | null;
  isTemplate?: boolean | null;
  requireClient?: boolean | null;
  requireForTimesheets?: boolean | null;
  requireGeolocation?: boolean | null;
  requireMachine?: boolean | null;
  requireProject?: boolean | null;
  requireSupplier?: boolean | null;
  requireWorker?: boolean | null;
}

export interface ImageData {
  name?: string | null;
  data?: string | null;
  fileName?: string | null;
}

export interface InductionExactClient {
  id?: number | null;
  isActive?: boolean | null;
  projects?: InductionExactClientProject[] | null;
  contacts?: InductionExactClientContact[] | null;
}

export interface InductionExactClientContact {
  id?: number | null;
}

export interface InductionExactClientProject {
  id?: number | null;
  isActive?: boolean | null;
  companyId?: number | null;
  contacts?: InductionExactClientProjectContact[] | null;
}

export interface InductionExactClientProjectContact {
  id?: number | null;
}

export interface InductionExactProject {
  id?: number | null;
  isActive?: boolean | null;
  companyId?: number | null;
  client?: InductionExactProjectClient | null;
  contacts?: InductionExactProjectContact[] | null;
}

export interface InductionExactProjectClient {
  id?: number | null;
  isActive?: boolean | null;
  contacts?: InductionExactProjectClientContact[] | null;
}

export interface InductionExactProjectClientContact {
  id?: number | null;
}

export interface InductionExactProjectContact {
  id?: number | null;
}

export interface InductionExactUserInduction {
  id?: number | null;
  inductionId?: number | null;
  userId?: number | null;
  userInductionUser?: InductionExactUserInductionUser | null;
  userInductionInduction?: InductionExactUserInductionInduction | null;
}

export interface InductionExactUserInductionInduction {
  id?: number | null;
  clientId?: number | null;
  projectId?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  client?: InductionExactUserInductionInductionClient | null;
  project?: InductionExactUserInductionInductionProject | null;
}

export interface InductionExactUserInductionInductionClient {
  id?: number | null;
  isActive?: boolean | null;
  projects?: InductionExactUserInductionInductionClientProject[] | null;
  contacts?: InductionExactUserInductionInductionClientContact[] | null;
}

export interface InductionExactUserInductionInductionClientContact {
  id?: number | null;
}

export interface InductionExactUserInductionInductionClientProject {
  id?: number | null;
  isActive?: boolean | null;
  companyId?: number | null;
  contacts?: InductionExactUserInductionInductionClientProjectContact[] | null;
}

export interface InductionExactUserInductionInductionClientProjectContact {
  id?: number | null;
}

export interface InductionExactUserInductionInductionProject {
  id?: number | null;
  isActive?: boolean | null;
  companyId?: number | null;
  client?: InductionExactUserInductionInductionProjectClient | null;
  contacts?: InductionExactUserInductionInductionProjectContact[] | null;
}

export interface InductionExactUserInductionInductionProjectClient {
  id?: number | null;
  isActive?: boolean | null;
  contacts?: InductionExactUserInductionInductionProjectClientContact[] | null;
}

export interface InductionExactUserInductionInductionProjectClientContact {
  id?: number | null;
}

export interface InductionExactUserInductionInductionProjectContact {
  id?: number | null;
}

export interface InductionExactUserInductionUser {
  id?: number | null;
  isActive?: boolean | null;
  employmentTypeId?: number | null;
  userTypeId?: number | null;
  isSystemUser?: boolean | null;
  employmentType?: InductionExactUserInductionUserEmploymentType | null;
  userType?: InductionExactUserInductionUserUserType | null;
  rights?: InductionExactUserInductionUserUserRole[] | null;
  userCompetencies?: InductionExactUserInductionUserUserCompetency[] | null;
}

export interface InductionExactUserInductionUserEmploymentType {
  id?: number | null;
}

export interface InductionExactUserInductionUserUserCompetency {
  id?: number | null;
  competencyId?: number | null;
  userId?: number | null;
  userCompetencyCompetency?: InductionExactUserInductionUserUserCompetencyCompetency | null;
}

export interface InductionExactUserInductionUserUserCompetencyCompetency {
  id?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
}

export interface InductionExactUserInductionUserUserRole {
  id?: number | null;
  isProtected?: boolean | null;
  isActive?: boolean | null;
  dashboardUser?: boolean | null;
  fieldworker?: boolean | null;
  restrictive?: boolean | null;
}

export interface InductionExactUserInductionUserUserType {
  id?: number | null;
}

export interface InductionImport {
  client?: InductionImportClient | null;
  clientId?: number | null;
  inductionUsers?: InductionImportUserInduction[] | null;
  name?: string | null;
  project?: InductionImportProject | null;
  projectId?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
}

export interface InductionImportClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  projects?: InductionImportClientProject[] | null;
  contacts?: InductionImportClientContact[] | null;
}

export interface InductionImportClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface InductionImportClientProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  companyId?: number | null;
  contacts?: InductionImportClientProjectContact[] | null;
}

export interface InductionImportClientProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface InductionImportProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  companyId?: number | null;
  client?: InductionImportProjectClient | null;
  contacts?: InductionImportProjectContact[] | null;
}

export interface InductionImportProjectClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  contacts?: InductionImportProjectClientContact[] | null;
}

export interface InductionImportProjectClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface InductionImportProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface InductionImportUserInduction {
  id?: number | null;
  inductionId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  userInductionUser?: InductionImportUserInductionUser | null;
  userInductionInduction?: InductionImportUserInductionInduction | null;
}

export interface InductionImportUserInductionInduction {
  id?: number | null;
  name?: string | null;
  clientId?: number | null;
  projectId?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  client?: InductionImportUserInductionInductionClient | null;
  project?: InductionImportUserInductionInductionProject | null;
}

export interface InductionImportUserInductionInductionClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  projects?: InductionImportUserInductionInductionClientProject[] | null;
  contacts?: InductionImportUserInductionInductionClientContact[] | null;
}

export interface InductionImportUserInductionInductionClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface InductionImportUserInductionInductionClientProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  companyId?: number | null;
  contacts?: InductionImportUserInductionInductionClientProjectContact[] | null;
}

export interface InductionImportUserInductionInductionClientProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface InductionImportUserInductionInductionProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  companyId?: number | null;
  client?: InductionImportUserInductionInductionProjectClient | null;
  contacts?: InductionImportUserInductionInductionProjectContact[] | null;
}

export interface InductionImportUserInductionInductionProjectClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  contacts?: InductionImportUserInductionInductionProjectClientContact[] | null;
}

export interface InductionImportUserInductionInductionProjectClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface InductionImportUserInductionInductionProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface InductionImportUserInductionUser {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  username?: string | null;
  isActive?: boolean | null;
  employeeId?: string | null;
  address?: string | null;
  suburb?: string | null;
  state?: string | null;
  postcode?: string | null;
  contact?: string | null;
  dob?: any | null;
  emergencyContact?: string | null;
  emergencyContactName?: string | null;
  usi?: string | null;
  expirationTime?: any | null;
  employmentTypeId?: number | null;
  userTypeId?: number | null;
  isSystemUser?: boolean | null;
  appVersion?: string | null;
  deviceOs?: string | null;
  deviceOsVersion?: string | null;
  employmentType?: InductionImportUserInductionUserEmploymentType | null;
  userType?: InductionImportUserInductionUserUserType | null;
  rights?: InductionImportUserInductionUserUserRole[] | null;
  userCompetencies?: InductionImportUserInductionUserUserCompetency[] | null;
}

export interface InductionImportUserInductionUserEmploymentType {
  id?: number | null;
  name?: string | null;
}

export interface InductionImportUserInductionUserUserCompetency {
  id?: number | null;
  description?: string | null;
  competencyId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  userCompetencyCompetency?: InductionImportUserInductionUserUserCompetencyCompetency | null;
}

export interface InductionImportUserInductionUserUserCompetencyCompetency {
  id?: number | null;
  name?: string | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  licenseNumber?: string | null;
}

export interface InductionImportUserInductionUserUserRole {
  id?: number | null;
  name?: string | null;
  route?: string | null;
  isProtected?: boolean | null;
  isActive?: boolean | null;
  description?: string | null;
  dashboardUser?: boolean | null;
  fieldworker?: boolean | null;
  restrictive?: boolean | null;
}

export interface InductionImportUserInductionUserUserType {
  id?: number | null;
  userType?: string | null;
  label?: string | null;
}

export interface InductionSearchExact {
  id?: number | null;
  client?: InductionExactClient | null;
  clientId?: number | null;
  inductionUsers?: InductionExactUserInduction[] | null;
  project?: InductionExactProject | null;
  projectId?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
}

export interface ProjectEdit {
  id?: number | null;
  address?: string | null;
  client?: ProjectEditClient | null;
  companyId?: number | null;
  contacts?: ProjectEditContact[] | null;
  email?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  name?: string | null;
  postcode?: string | null;
  state?: string | null;
  suburb?: string | null;
}

export interface ProjectEditClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  projects?: ProjectEditClientProject[] | null;
  contacts?: ProjectEditClientContact[] | null;
}

export interface ProjectEditClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface ProjectEditClientProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  companyId?: number | null;
  contacts?: ProjectEditClientProjectContact[] | null;
}

export interface ProjectEditClientProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface ProjectEditContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface ProjectExactClient {
  id?: number | null;
  isActive?: boolean | null;
  projects?: ProjectExactClientProject[] | null;
  contacts?: ProjectExactClientContact[] | null;
}

export interface ProjectExactClientContact {
  id?: number | null;
}

export interface ProjectExactClientProject {
  id?: number | null;
  isActive?: boolean | null;
  companyId?: number | null;
  contacts?: ProjectExactClientProjectContact[] | null;
}

export interface ProjectExactClientProjectContact {
  id?: number | null;
}

export interface ProjectExactContact {
  id?: number | null;
}

export interface ProjectImport {
  address?: string | null;
  client?: ProjectImportClient | null;
  companyId?: number | null;
  contacts?: ProjectImportContact[] | null;
  email?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  name?: string | null;
  postcode?: string | null;
  state?: string | null;
  suburb?: string | null;
}

export interface ProjectImportClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  projects?: ProjectImportClientProject[] | null;
  contacts?: ProjectImportClientContact[] | null;
}

export interface ProjectImportClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface ProjectImportClientProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  companyId?: number | null;
  contacts?: ProjectImportClientProjectContact[] | null;
}

export interface ProjectImportClientProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface ProjectImportContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface ProjectSearchExact {
  id?: number | null;
  client?: ProjectExactClient | null;
  companyId?: number | null;
  contacts?: ProjectExactContact[] | null;
  isActive?: boolean | null;
}

export interface SupplierEdit {
  id?: number | null;
  abn?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  comments?: string | null;
  contacts?: SupplierEditContact[] | null;
  email?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  mobile?: string | null;
  name?: string | null;
  officePhone?: string | null;
  postcode?: string | null;
  state?: string | null;
}

export interface SupplierEditContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface SupplierExactContact {
  id?: number | null;
}

export interface SupplierImport {
  abn?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  comments?: string | null;
  contacts?: SupplierImportContact[] | null;
  email?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  mobile?: string | null;
  name?: string | null;
  officePhone?: string | null;
  postcode?: string | null;
  state?: string | null;
}

export interface SupplierImportContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface SupplierSearchExact {
  id?: number | null;
  contacts?: SupplierExactContact[] | null;
  isActive?: boolean | null;
}

export interface TaskExactChargeCode {
  id?: number | null;
  tasks?: TaskExactChargeCodeTask[] | null;
}

export interface TaskExactChargeCodeTask {
  id?: number | null;
  isActive?: boolean | null;
  requiresAssets?: boolean | null;
  isSupervisor?: boolean | null;
  chargeCodeId?: number | null;
}

export interface TaskImport {
  chargeCode?: TaskImportChargeCode | null;
  chargeCodeId?: number | null;
  externalId?: string | null;
  isActive?: boolean | null;
  isSupervisor?: boolean | null;
  name?: string | null;
  payLevel?: string | null;
  payrollAccount?: string | null;
  requiresAssets?: boolean | null;
}

export interface TaskImportChargeCode {
  id?: number | null;
  code?: string | null;
  label?: string | null;
  tasks?: TaskImportChargeCodeTask[] | null;
}

export interface TaskImportChargeCodeTask {
  id?: number | null;
  name?: string | null;
  isActive?: boolean | null;
  externalId?: string | null;
  requiresAssets?: boolean | null;
  payLevel?: string | null;
  payrollAccount?: string | null;
  isSupervisor?: boolean | null;
  chargeCodeId?: number | null;
}

export interface TaskSearchExact {
  id?: number | null;
  chargeCode?: TaskExactChargeCode | null;
  chargeCodeId?: number | null;
  isActive?: boolean | null;
  isSupervisor?: boolean | null;
  requiresAssets?: boolean | null;
}

export interface UpdateSettings {
  id: string;
  name: string;
  value?: any | null;
}

export interface UserCompetencyInput {
  id?: number | null;
  competencyId?: number | null;
  createdTime?: any | null;
  description?: string | null;
  expirationDate?: any | null;
  issueDate?: any | null;
  userCompetencyCompetency?: UserCompetencyInputCompetency | null;
  userCompetencyUser?: UserCompetencyInputUser | null;
  userId?: number | null;
}

export interface UserCompetencyInputCompetency {
  id?: number | null;
  name?: string | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  licenseNumber?: string | null;
  competencyUsers?: UserCompetencyInputCompetencyUserCompetency[] | null;
}

export interface UserCompetencyInputCompetencyUserCompetency {
  id?: number | null;
  description?: string | null;
  competencyId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  createdTime?: any | null;
  userCompetencyUser?: UserCompetencyInputCompetencyUserCompetencyUser | null;
}

export interface UserCompetencyInputCompetencyUserCompetencyUser {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  username?: string | null;
  isActive?: boolean | null;
  employeeId?: string | null;
  address?: string | null;
  suburb?: string | null;
  state?: string | null;
  postcode?: string | null;
  contact?: string | null;
  dob?: any | null;
  emergencyContact?: string | null;
  emergencyContactName?: string | null;
  usi?: string | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  expirationTime?: any | null;
  employmentTypeId?: number | null;
  userTypeId?: number | null;
  cryptedPassword?: string | null;
  isSystemUser?: boolean | null;
  appVersion?: string | null;
  deviceOs?: string | null;
  deviceOsVersion?: string | null;
  employmentType?: UserCompetencyInputCompetencyUserCompetencyUserEmploymentType | null;
  userType?: UserCompetencyInputCompetencyUserCompetencyUserUserType | null;
  rights?: UserCompetencyInputCompetencyUserCompetencyUserUserRole[] | null;
  userInductions?: UserCompetencyInputCompetencyUserCompetencyUserUserInduction[] | null;
}

export interface UserCompetencyInputCompetencyUserCompetencyUserEmploymentType {
  id?: number | null;
  name?: string | null;
}

export interface UserCompetencyInputCompetencyUserCompetencyUserUserInduction {
  id?: number | null;
  inductionId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  createdTime?: any | null;
  userInductionInduction?: UserCompetencyInputCompetencyUserCompetencyUserUserInductionInduction | null;
}

export interface UserCompetencyInputCompetencyUserCompetencyUserUserInductionInduction {
  id?: number | null;
  name?: string | null;
  clientId?: number | null;
  projectId?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  client?: UserCompetencyInputCompetencyUserCompetencyUserUserInductionInductionClient | null;
  project?: UserCompetencyInputCompetencyUserCompetencyUserUserInductionInductionProject | null;
}

export interface UserCompetencyInputCompetencyUserCompetencyUserUserInductionInductionClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  projects?: UserCompetencyInputCompetencyUserCompetencyUserUserInductionInductionClientProject[] | null;
  contacts?: UserCompetencyInputCompetencyUserCompetencyUserUserInductionInductionClientContact[] | null;
}

export interface UserCompetencyInputCompetencyUserCompetencyUserUserInductionInductionClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserCompetencyInputCompetencyUserCompetencyUserUserInductionInductionClientProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  companyId?: number | null;
  contacts?: UserCompetencyInputCompetencyUserCompetencyUserUserInductionInductionClientProjectContact[] | null;
}

export interface UserCompetencyInputCompetencyUserCompetencyUserUserInductionInductionClientProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserCompetencyInputCompetencyUserCompetencyUserUserInductionInductionProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  companyId?: number | null;
  client?: UserCompetencyInputCompetencyUserCompetencyUserUserInductionInductionProjectClient | null;
  contacts?: UserCompetencyInputCompetencyUserCompetencyUserUserInductionInductionProjectContact[] | null;
}

export interface UserCompetencyInputCompetencyUserCompetencyUserUserInductionInductionProjectClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  contacts?: UserCompetencyInputCompetencyUserCompetencyUserUserInductionInductionProjectClientContact[] | null;
}

export interface UserCompetencyInputCompetencyUserCompetencyUserUserInductionInductionProjectClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserCompetencyInputCompetencyUserCompetencyUserUserInductionInductionProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserCompetencyInputCompetencyUserCompetencyUserUserRole {
  id?: number | null;
  name?: string | null;
  route?: string | null;
  isProtected?: boolean | null;
  isActive?: boolean | null;
  description?: string | null;
  dashboardUser?: boolean | null;
  fieldworker?: boolean | null;
  restrictive?: boolean | null;
}

export interface UserCompetencyInputCompetencyUserCompetencyUserUserType {
  id?: number | null;
  userType?: string | null;
  label?: string | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
}

export interface UserCompetencyInputUser {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  username?: string | null;
  isActive?: boolean | null;
  employeeId?: string | null;
  address?: string | null;
  suburb?: string | null;
  state?: string | null;
  postcode?: string | null;
  contact?: string | null;
  dob?: any | null;
  emergencyContact?: string | null;
  emergencyContactName?: string | null;
  usi?: string | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  expirationTime?: any | null;
  employmentTypeId?: number | null;
  userTypeId?: number | null;
  cryptedPassword?: string | null;
  isSystemUser?: boolean | null;
  appVersion?: string | null;
  deviceOs?: string | null;
  deviceOsVersion?: string | null;
  employmentType?: UserCompetencyInputUserEmploymentType | null;
  userType?: UserCompetencyInputUserUserType | null;
  rights?: UserCompetencyInputUserUserRole[] | null;
  userInductions?: UserCompetencyInputUserUserInduction[] | null;
  userCompetencies?: UserCompetencyInputUserUserCompetency[] | null;
}

export interface UserCompetencyInputUserEmploymentType {
  id?: number | null;
  name?: string | null;
}

export interface UserCompetencyInputUserUserCompetency {
  id?: number | null;
  description?: string | null;
  competencyId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  createdTime?: any | null;
  userCompetencyCompetency?: UserCompetencyInputUserUserCompetencyCompetency | null;
}

export interface UserCompetencyInputUserUserCompetencyCompetency {
  id?: number | null;
  name?: string | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  licenseNumber?: string | null;
}

export interface UserCompetencyInputUserUserInduction {
  id?: number | null;
  inductionId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  createdTime?: any | null;
  userInductionInduction?: UserCompetencyInputUserUserInductionInduction | null;
}

export interface UserCompetencyInputUserUserInductionInduction {
  id?: number | null;
  name?: string | null;
  clientId?: number | null;
  projectId?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  client?: UserCompetencyInputUserUserInductionInductionClient | null;
  project?: UserCompetencyInputUserUserInductionInductionProject | null;
}

export interface UserCompetencyInputUserUserInductionInductionClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  projects?: UserCompetencyInputUserUserInductionInductionClientProject[] | null;
  contacts?: UserCompetencyInputUserUserInductionInductionClientContact[] | null;
}

export interface UserCompetencyInputUserUserInductionInductionClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserCompetencyInputUserUserInductionInductionClientProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  companyId?: number | null;
  contacts?: UserCompetencyInputUserUserInductionInductionClientProjectContact[] | null;
}

export interface UserCompetencyInputUserUserInductionInductionClientProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserCompetencyInputUserUserInductionInductionProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  companyId?: number | null;
  client?: UserCompetencyInputUserUserInductionInductionProjectClient | null;
  contacts?: UserCompetencyInputUserUserInductionInductionProjectContact[] | null;
}

export interface UserCompetencyInputUserUserInductionInductionProjectClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  contacts?: UserCompetencyInputUserUserInductionInductionProjectClientContact[] | null;
}

export interface UserCompetencyInputUserUserInductionInductionProjectClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserCompetencyInputUserUserInductionInductionProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserCompetencyInputUserUserRole {
  id?: number | null;
  name?: string | null;
  route?: string | null;
  isProtected?: boolean | null;
  isActive?: boolean | null;
  description?: string | null;
  dashboardUser?: boolean | null;
  fieldworker?: boolean | null;
  restrictive?: boolean | null;
}

export interface UserCompetencyInputUserUserType {
  id?: number | null;
  userType?: string | null;
  label?: string | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
}

export interface UserEdit {
  id?: number | null;
  address?: string | null;
  appVersion?: string | null;
  contact?: string | null;
  cryptedPassword?: string | null;
  deviceOs?: string | null;
  deviceOsVersion?: string | null;
  dob?: any | null;
  email?: string | null;
  emergencyContact?: string | null;
  emergencyContactName?: string | null;
  employeeId?: string | null;
  employmentType?: UserEditEmploymentType | null;
  employmentTypeId?: number | null;
  expirationTime?: any | null;
  firstName?: string | null;
  isActive?: boolean | null;
  isSystemUser?: boolean | null;
  lastName?: string | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  postcode?: string | null;
  rights?: UserEditUserRole[] | null;
  state?: string | null;
  suburb?: string | null;
  userCompetencies?: UserEditUserCompetency[] | null;
  userInductions?: UserEditUserInduction[] | null;
  userType?: UserEditUserType | null;
  userTypeId?: number | null;
  username?: string | null;
  usi?: string | null;
}

export interface UserEditEmploymentType {
  id?: number | null;
  name?: string | null;
}

export interface UserEditUserCompetency {
  id?: number | null;
  description?: string | null;
  competencyId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  createdTime?: any | null;
  userCompetencyUser?: UserEditUserCompetencyUser | null;
  userCompetencyCompetency?: UserEditUserCompetencyCompetency | null;
}

export interface UserEditUserCompetencyCompetency {
  id?: number | null;
  name?: string | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  licenseNumber?: string | null;
}

export interface UserEditUserCompetencyUser {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  username?: string | null;
  isActive?: boolean | null;
  employeeId?: string | null;
  address?: string | null;
  suburb?: string | null;
  state?: string | null;
  postcode?: string | null;
  contact?: string | null;
  dob?: any | null;
  emergencyContact?: string | null;
  emergencyContactName?: string | null;
  usi?: string | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  expirationTime?: any | null;
  employmentTypeId?: number | null;
  userTypeId?: number | null;
  cryptedPassword?: string | null;
  isSystemUser?: boolean | null;
  appVersion?: string | null;
  deviceOs?: string | null;
  deviceOsVersion?: string | null;
  employmentType?: UserEditUserCompetencyUserEmploymentType | null;
  userType?: UserEditUserCompetencyUserUserType | null;
  rights?: UserEditUserCompetencyUserUserRole[] | null;
  userInductions?: UserEditUserCompetencyUserUserInduction[] | null;
}

export interface UserEditUserCompetencyUserEmploymentType {
  id?: number | null;
  name?: string | null;
}

export interface UserEditUserCompetencyUserUserInduction {
  id?: number | null;
  inductionId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  createdTime?: any | null;
  userInductionInduction?: UserEditUserCompetencyUserUserInductionInduction | null;
}

export interface UserEditUserCompetencyUserUserInductionInduction {
  id?: number | null;
  name?: string | null;
  clientId?: number | null;
  projectId?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  client?: UserEditUserCompetencyUserUserInductionInductionClient | null;
  project?: UserEditUserCompetencyUserUserInductionInductionProject | null;
}

export interface UserEditUserCompetencyUserUserInductionInductionClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  projects?: UserEditUserCompetencyUserUserInductionInductionClientProject[] | null;
  contacts?: UserEditUserCompetencyUserUserInductionInductionClientContact[] | null;
}

export interface UserEditUserCompetencyUserUserInductionInductionClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserEditUserCompetencyUserUserInductionInductionClientProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  companyId?: number | null;
  contacts?: UserEditUserCompetencyUserUserInductionInductionClientProjectContact[] | null;
}

export interface UserEditUserCompetencyUserUserInductionInductionClientProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserEditUserCompetencyUserUserInductionInductionProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  companyId?: number | null;
  client?: UserEditUserCompetencyUserUserInductionInductionProjectClient | null;
  contacts?: UserEditUserCompetencyUserUserInductionInductionProjectContact[] | null;
}

export interface UserEditUserCompetencyUserUserInductionInductionProjectClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  contacts?: UserEditUserCompetencyUserUserInductionInductionProjectClientContact[] | null;
}

export interface UserEditUserCompetencyUserUserInductionInductionProjectClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserEditUserCompetencyUserUserInductionInductionProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserEditUserCompetencyUserUserRole {
  id?: number | null;
  name?: string | null;
  route?: string | null;
  isProtected?: boolean | null;
  isActive?: boolean | null;
  description?: string | null;
  dashboardUser?: boolean | null;
  fieldworker?: boolean | null;
  restrictive?: boolean | null;
}

export interface UserEditUserCompetencyUserUserType {
  id?: number | null;
  userType?: string | null;
  label?: string | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
}

export interface UserEditUserInduction {
  id?: number | null;
  inductionId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  createdTime?: any | null;
  userInductionUser?: UserEditUserInductionUser | null;
  userInductionInduction?: UserEditUserInductionInduction | null;
}

export interface UserEditUserInductionInduction {
  id?: number | null;
  name?: string | null;
  clientId?: number | null;
  projectId?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  client?: UserEditUserInductionInductionClient | null;
  project?: UserEditUserInductionInductionProject | null;
}

export interface UserEditUserInductionInductionClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  projects?: UserEditUserInductionInductionClientProject[] | null;
  contacts?: UserEditUserInductionInductionClientContact[] | null;
}

export interface UserEditUserInductionInductionClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserEditUserInductionInductionClientProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  companyId?: number | null;
  contacts?: UserEditUserInductionInductionClientProjectContact[] | null;
}

export interface UserEditUserInductionInductionClientProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserEditUserInductionInductionProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  companyId?: number | null;
  client?: UserEditUserInductionInductionProjectClient | null;
  contacts?: UserEditUserInductionInductionProjectContact[] | null;
}

export interface UserEditUserInductionInductionProjectClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  contacts?: UserEditUserInductionInductionProjectClientContact[] | null;
}

export interface UserEditUserInductionInductionProjectClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserEditUserInductionInductionProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserEditUserInductionUser {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  username?: string | null;
  isActive?: boolean | null;
  employeeId?: string | null;
  address?: string | null;
  suburb?: string | null;
  state?: string | null;
  postcode?: string | null;
  contact?: string | null;
  dob?: any | null;
  emergencyContact?: string | null;
  emergencyContactName?: string | null;
  usi?: string | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  expirationTime?: any | null;
  employmentTypeId?: number | null;
  userTypeId?: number | null;
  cryptedPassword?: string | null;
  isSystemUser?: boolean | null;
  appVersion?: string | null;
  deviceOs?: string | null;
  deviceOsVersion?: string | null;
  employmentType?: UserEditUserInductionUserEmploymentType | null;
  userType?: UserEditUserInductionUserUserType | null;
  rights?: UserEditUserInductionUserUserRole[] | null;
  userCompetencies?: UserEditUserInductionUserUserCompetency[] | null;
}

export interface UserEditUserInductionUserEmploymentType {
  id?: number | null;
  name?: string | null;
}

export interface UserEditUserInductionUserUserCompetency {
  id?: number | null;
  description?: string | null;
  competencyId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  createdTime?: any | null;
  userCompetencyCompetency?: UserEditUserInductionUserUserCompetencyCompetency | null;
}

export interface UserEditUserInductionUserUserCompetencyCompetency {
  id?: number | null;
  name?: string | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  licenseNumber?: string | null;
}

export interface UserEditUserInductionUserUserRole {
  id?: number | null;
  name?: string | null;
  route?: string | null;
  isProtected?: boolean | null;
  isActive?: boolean | null;
  description?: string | null;
  dashboardUser?: boolean | null;
  fieldworker?: boolean | null;
  restrictive?: boolean | null;
}

export interface UserEditUserInductionUserUserType {
  id?: number | null;
  userType?: string | null;
  label?: string | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
}

export interface UserEditUserRole {
  id?: number | null;
  name?: string | null;
  route?: string | null;
  isProtected?: boolean | null;
  isActive?: boolean | null;
  description?: string | null;
  dashboardUser?: boolean | null;
  fieldworker?: boolean | null;
  restrictive?: boolean | null;
}

export interface UserEditUserType {
  id?: number | null;
  userType?: string | null;
  label?: string | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
}

export interface UserExactEmploymentType {
  id?: number | null;
}

export interface UserExactUserCompetency {
  id?: number | null;
  competencyId?: number | null;
  userId?: number | null;
  userCompetencyUser?: UserExactUserCompetencyUser | null;
  userCompetencyCompetency?: UserExactUserCompetencyCompetency | null;
}

export interface UserExactUserCompetencyCompetency {
  id?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
}

export interface UserExactUserCompetencyUser {
  id?: number | null;
  isActive?: boolean | null;
  employmentTypeId?: number | null;
  userTypeId?: number | null;
  isSystemUser?: boolean | null;
  employmentType?: UserExactUserCompetencyUserEmploymentType | null;
  userType?: UserExactUserCompetencyUserUserType | null;
  rights?: UserExactUserCompetencyUserUserRole[] | null;
  userInductions?: UserExactUserCompetencyUserUserInduction[] | null;
}

export interface UserExactUserCompetencyUserEmploymentType {
  id?: number | null;
}

export interface UserExactUserCompetencyUserUserInduction {
  id?: number | null;
  inductionId?: number | null;
  userId?: number | null;
  userInductionInduction?: UserExactUserCompetencyUserUserInductionInduction | null;
}

export interface UserExactUserCompetencyUserUserInductionInduction {
  id?: number | null;
  clientId?: number | null;
  projectId?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  client?: UserExactUserCompetencyUserUserInductionInductionClient | null;
  project?: UserExactUserCompetencyUserUserInductionInductionProject | null;
}

export interface UserExactUserCompetencyUserUserInductionInductionClient {
  id?: number | null;
  isActive?: boolean | null;
  projects?: UserExactUserCompetencyUserUserInductionInductionClientProject[] | null;
  contacts?: UserExactUserCompetencyUserUserInductionInductionClientContact[] | null;
}

export interface UserExactUserCompetencyUserUserInductionInductionClientContact {
  id?: number | null;
}

export interface UserExactUserCompetencyUserUserInductionInductionClientProject {
  id?: number | null;
  isActive?: boolean | null;
  companyId?: number | null;
  contacts?: UserExactUserCompetencyUserUserInductionInductionClientProjectContact[] | null;
}

export interface UserExactUserCompetencyUserUserInductionInductionClientProjectContact {
  id?: number | null;
}

export interface UserExactUserCompetencyUserUserInductionInductionProject {
  id?: number | null;
  isActive?: boolean | null;
  companyId?: number | null;
  client?: UserExactUserCompetencyUserUserInductionInductionProjectClient | null;
  contacts?: UserExactUserCompetencyUserUserInductionInductionProjectContact[] | null;
}

export interface UserExactUserCompetencyUserUserInductionInductionProjectClient {
  id?: number | null;
  isActive?: boolean | null;
  contacts?: UserExactUserCompetencyUserUserInductionInductionProjectClientContact[] | null;
}

export interface UserExactUserCompetencyUserUserInductionInductionProjectClientContact {
  id?: number | null;
}

export interface UserExactUserCompetencyUserUserInductionInductionProjectContact {
  id?: number | null;
}

export interface UserExactUserCompetencyUserUserRole {
  id?: number | null;
  isProtected?: boolean | null;
  isActive?: boolean | null;
  dashboardUser?: boolean | null;
  fieldworker?: boolean | null;
  restrictive?: boolean | null;
}

export interface UserExactUserCompetencyUserUserType {
  id?: number | null;
}

export interface UserExactUserInduction {
  id?: number | null;
  inductionId?: number | null;
  userId?: number | null;
  userInductionUser?: UserExactUserInductionUser | null;
  userInductionInduction?: UserExactUserInductionInduction | null;
}

export interface UserExactUserInductionInduction {
  id?: number | null;
  clientId?: number | null;
  projectId?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  client?: UserExactUserInductionInductionClient | null;
  project?: UserExactUserInductionInductionProject | null;
}

export interface UserExactUserInductionInductionClient {
  id?: number | null;
  isActive?: boolean | null;
  projects?: UserExactUserInductionInductionClientProject[] | null;
  contacts?: UserExactUserInductionInductionClientContact[] | null;
}

export interface UserExactUserInductionInductionClientContact {
  id?: number | null;
}

export interface UserExactUserInductionInductionClientProject {
  id?: number | null;
  isActive?: boolean | null;
  companyId?: number | null;
  contacts?: UserExactUserInductionInductionClientProjectContact[] | null;
}

export interface UserExactUserInductionInductionClientProjectContact {
  id?: number | null;
}

export interface UserExactUserInductionInductionProject {
  id?: number | null;
  isActive?: boolean | null;
  companyId?: number | null;
  client?: UserExactUserInductionInductionProjectClient | null;
  contacts?: UserExactUserInductionInductionProjectContact[] | null;
}

export interface UserExactUserInductionInductionProjectClient {
  id?: number | null;
  isActive?: boolean | null;
  contacts?: UserExactUserInductionInductionProjectClientContact[] | null;
}

export interface UserExactUserInductionInductionProjectClientContact {
  id?: number | null;
}

export interface UserExactUserInductionInductionProjectContact {
  id?: number | null;
}

export interface UserExactUserInductionUser {
  id?: number | null;
  isActive?: boolean | null;
  employmentTypeId?: number | null;
  userTypeId?: number | null;
  isSystemUser?: boolean | null;
  employmentType?: UserExactUserInductionUserEmploymentType | null;
  userType?: UserExactUserInductionUserUserType | null;
  rights?: UserExactUserInductionUserUserRole[] | null;
  userCompetencies?: UserExactUserInductionUserUserCompetency[] | null;
}

export interface UserExactUserInductionUserEmploymentType {
  id?: number | null;
}

export interface UserExactUserInductionUserUserCompetency {
  id?: number | null;
  competencyId?: number | null;
  userId?: number | null;
  userCompetencyCompetency?: UserExactUserInductionUserUserCompetencyCompetency | null;
}

export interface UserExactUserInductionUserUserCompetencyCompetency {
  id?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
}

export interface UserExactUserInductionUserUserRole {
  id?: number | null;
  isProtected?: boolean | null;
  isActive?: boolean | null;
  dashboardUser?: boolean | null;
  fieldworker?: boolean | null;
  restrictive?: boolean | null;
}

export interface UserExactUserInductionUserUserType {
  id?: number | null;
}

export interface UserExactUserRole {
  id?: number | null;
  isProtected?: boolean | null;
  isActive?: boolean | null;
  dashboardUser?: boolean | null;
  fieldworker?: boolean | null;
  restrictive?: boolean | null;
}

export interface UserExactUserType {
  id?: number | null;
}

export interface UserImport {
  address?: string | null;
  appVersion?: string | null;
  contact?: string | null;
  deviceOs?: string | null;
  deviceOsVersion?: string | null;
  dob?: any | null;
  email?: string | null;
  emergencyContact?: string | null;
  emergencyContactName?: string | null;
  employeeId?: string | null;
  employmentType?: UserImportEmploymentType | null;
  employmentTypeId?: number | null;
  expirationTime?: any | null;
  firstName?: string | null;
  isActive?: boolean | null;
  isSystemUser?: boolean | null;
  lastName?: string | null;
  postcode?: string | null;
  rights?: UserImportUserRole[] | null;
  state?: string | null;
  suburb?: string | null;
  userCompetencies?: UserImportUserCompetency[] | null;
  userInductions?: UserImportUserInduction[] | null;
  userType?: UserImportUserType | null;
  userTypeId?: number | null;
  username?: string | null;
  usi?: string | null;
}

export interface UserImportEmploymentType {
  id?: number | null;
  name?: string | null;
}

export interface UserImportUserCompetency {
  id?: number | null;
  description?: string | null;
  competencyId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  userCompetencyUser?: UserImportUserCompetencyUser | null;
  userCompetencyCompetency?: UserImportUserCompetencyCompetency | null;
}

export interface UserImportUserCompetencyCompetency {
  id?: number | null;
  name?: string | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  licenseNumber?: string | null;
}

export interface UserImportUserCompetencyUser {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  username?: string | null;
  isActive?: boolean | null;
  employeeId?: string | null;
  address?: string | null;
  suburb?: string | null;
  state?: string | null;
  postcode?: string | null;
  contact?: string | null;
  dob?: any | null;
  emergencyContact?: string | null;
  emergencyContactName?: string | null;
  usi?: string | null;
  expirationTime?: any | null;
  employmentTypeId?: number | null;
  userTypeId?: number | null;
  isSystemUser?: boolean | null;
  appVersion?: string | null;
  deviceOs?: string | null;
  deviceOsVersion?: string | null;
  employmentType?: UserImportUserCompetencyUserEmploymentType | null;
  userType?: UserImportUserCompetencyUserUserType | null;
  rights?: UserImportUserCompetencyUserUserRole[] | null;
  userInductions?: UserImportUserCompetencyUserUserInduction[] | null;
}

export interface UserImportUserCompetencyUserEmploymentType {
  id?: number | null;
  name?: string | null;
}

export interface UserImportUserCompetencyUserUserInduction {
  id?: number | null;
  inductionId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  userInductionInduction?: UserImportUserCompetencyUserUserInductionInduction | null;
}

export interface UserImportUserCompetencyUserUserInductionInduction {
  id?: number | null;
  name?: string | null;
  clientId?: number | null;
  projectId?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  client?: UserImportUserCompetencyUserUserInductionInductionClient | null;
  project?: UserImportUserCompetencyUserUserInductionInductionProject | null;
}

export interface UserImportUserCompetencyUserUserInductionInductionClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  projects?: UserImportUserCompetencyUserUserInductionInductionClientProject[] | null;
  contacts?: UserImportUserCompetencyUserUserInductionInductionClientContact[] | null;
}

export interface UserImportUserCompetencyUserUserInductionInductionClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserImportUserCompetencyUserUserInductionInductionClientProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  companyId?: number | null;
  contacts?: UserImportUserCompetencyUserUserInductionInductionClientProjectContact[] | null;
}

export interface UserImportUserCompetencyUserUserInductionInductionClientProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserImportUserCompetencyUserUserInductionInductionProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  companyId?: number | null;
  client?: UserImportUserCompetencyUserUserInductionInductionProjectClient | null;
  contacts?: UserImportUserCompetencyUserUserInductionInductionProjectContact[] | null;
}

export interface UserImportUserCompetencyUserUserInductionInductionProjectClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  contacts?: UserImportUserCompetencyUserUserInductionInductionProjectClientContact[] | null;
}

export interface UserImportUserCompetencyUserUserInductionInductionProjectClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserImportUserCompetencyUserUserInductionInductionProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserImportUserCompetencyUserUserRole {
  id?: number | null;
  name?: string | null;
  route?: string | null;
  isProtected?: boolean | null;
  isActive?: boolean | null;
  description?: string | null;
  dashboardUser?: boolean | null;
  fieldworker?: boolean | null;
  restrictive?: boolean | null;
}

export interface UserImportUserCompetencyUserUserType {
  id?: number | null;
  userType?: string | null;
  label?: string | null;
}

export interface UserImportUserInduction {
  id?: number | null;
  inductionId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  userInductionUser?: UserImportUserInductionUser | null;
  userInductionInduction?: UserImportUserInductionInduction | null;
}

export interface UserImportUserInductionInduction {
  id?: number | null;
  name?: string | null;
  clientId?: number | null;
  projectId?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  client?: UserImportUserInductionInductionClient | null;
  project?: UserImportUserInductionInductionProject | null;
}

export interface UserImportUserInductionInductionClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  projects?: UserImportUserInductionInductionClientProject[] | null;
  contacts?: UserImportUserInductionInductionClientContact[] | null;
}

export interface UserImportUserInductionInductionClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserImportUserInductionInductionClientProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  companyId?: number | null;
  contacts?: UserImportUserInductionInductionClientProjectContact[] | null;
}

export interface UserImportUserInductionInductionClientProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserImportUserInductionInductionProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  companyId?: number | null;
  client?: UserImportUserInductionInductionProjectClient | null;
  contacts?: UserImportUserInductionInductionProjectContact[] | null;
}

export interface UserImportUserInductionInductionProjectClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  contacts?: UserImportUserInductionInductionProjectClientContact[] | null;
}

export interface UserImportUserInductionInductionProjectClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserImportUserInductionInductionProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserImportUserInductionUser {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  username?: string | null;
  isActive?: boolean | null;
  employeeId?: string | null;
  address?: string | null;
  suburb?: string | null;
  state?: string | null;
  postcode?: string | null;
  contact?: string | null;
  dob?: any | null;
  emergencyContact?: string | null;
  emergencyContactName?: string | null;
  usi?: string | null;
  expirationTime?: any | null;
  employmentTypeId?: number | null;
  userTypeId?: number | null;
  isSystemUser?: boolean | null;
  appVersion?: string | null;
  deviceOs?: string | null;
  deviceOsVersion?: string | null;
  employmentType?: UserImportUserInductionUserEmploymentType | null;
  userType?: UserImportUserInductionUserUserType | null;
  rights?: UserImportUserInductionUserUserRole[] | null;
  userCompetencies?: UserImportUserInductionUserUserCompetency[] | null;
}

export interface UserImportUserInductionUserEmploymentType {
  id?: number | null;
  name?: string | null;
}

export interface UserImportUserInductionUserUserCompetency {
  id?: number | null;
  description?: string | null;
  competencyId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  userCompetencyCompetency?: UserImportUserInductionUserUserCompetencyCompetency | null;
}

export interface UserImportUserInductionUserUserCompetencyCompetency {
  id?: number | null;
  name?: string | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  licenseNumber?: string | null;
}

export interface UserImportUserInductionUserUserRole {
  id?: number | null;
  name?: string | null;
  route?: string | null;
  isProtected?: boolean | null;
  isActive?: boolean | null;
  description?: string | null;
  dashboardUser?: boolean | null;
  fieldworker?: boolean | null;
  restrictive?: boolean | null;
}

export interface UserImportUserInductionUserUserType {
  id?: number | null;
  userType?: string | null;
  label?: string | null;
}

export interface UserImportUserRole {
  id?: number | null;
  name?: string | null;
  route?: string | null;
  isProtected?: boolean | null;
  isActive?: boolean | null;
  description?: string | null;
  dashboardUser?: boolean | null;
  fieldworker?: boolean | null;
  restrictive?: boolean | null;
}

export interface UserImportUserType {
  id?: number | null;
  userType?: string | null;
  label?: string | null;
}

export interface UserInductionInput {
  id?: number | null;
  createdTime?: any | null;
  expirationDate?: any | null;
  inductionId?: number | null;
  issueDate?: any | null;
  userId?: number | null;
  userInductionInduction?: UserInductionInputInduction | null;
  userInductionUser?: UserInductionInputUser | null;
}

export interface UserInductionInputInduction {
  id?: number | null;
  name?: string | null;
  clientId?: number | null;
  projectId?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  client?: UserInductionInputInductionClient | null;
  project?: UserInductionInputInductionProject | null;
  inductionUsers?: UserInductionInputInductionUserInduction[] | null;
}

export interface UserInductionInputInductionClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  projects?: UserInductionInputInductionClientProject[] | null;
  contacts?: UserInductionInputInductionClientContact[] | null;
}

export interface UserInductionInputInductionClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserInductionInputInductionClientProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  companyId?: number | null;
  contacts?: UserInductionInputInductionClientProjectContact[] | null;
}

export interface UserInductionInputInductionClientProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserInductionInputInductionProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  companyId?: number | null;
  client?: UserInductionInputInductionProjectClient | null;
  contacts?: UserInductionInputInductionProjectContact[] | null;
}

export interface UserInductionInputInductionProjectClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  contacts?: UserInductionInputInductionProjectClientContact[] | null;
}

export interface UserInductionInputInductionProjectClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserInductionInputInductionProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserInductionInputInductionUserInduction {
  id?: number | null;
  inductionId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  createdTime?: any | null;
  userInductionUser?: UserInductionInputInductionUserInductionUser | null;
}

export interface UserInductionInputInductionUserInductionUser {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  username?: string | null;
  isActive?: boolean | null;
  employeeId?: string | null;
  address?: string | null;
  suburb?: string | null;
  state?: string | null;
  postcode?: string | null;
  contact?: string | null;
  dob?: any | null;
  emergencyContact?: string | null;
  emergencyContactName?: string | null;
  usi?: string | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  expirationTime?: any | null;
  employmentTypeId?: number | null;
  userTypeId?: number | null;
  cryptedPassword?: string | null;
  isSystemUser?: boolean | null;
  appVersion?: string | null;
  deviceOs?: string | null;
  deviceOsVersion?: string | null;
  employmentType?: UserInductionInputInductionUserInductionUserEmploymentType | null;
  userType?: UserInductionInputInductionUserInductionUserUserType | null;
  rights?: UserInductionInputInductionUserInductionUserUserRole[] | null;
  userCompetencies?: UserInductionInputInductionUserInductionUserUserCompetency[] | null;
}

export interface UserInductionInputInductionUserInductionUserEmploymentType {
  id?: number | null;
  name?: string | null;
}

export interface UserInductionInputInductionUserInductionUserUserCompetency {
  id?: number | null;
  description?: string | null;
  competencyId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  createdTime?: any | null;
  userCompetencyCompetency?: UserInductionInputInductionUserInductionUserUserCompetencyCompetency | null;
}

export interface UserInductionInputInductionUserInductionUserUserCompetencyCompetency {
  id?: number | null;
  name?: string | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  licenseNumber?: string | null;
}

export interface UserInductionInputInductionUserInductionUserUserRole {
  id?: number | null;
  name?: string | null;
  route?: string | null;
  isProtected?: boolean | null;
  isActive?: boolean | null;
  description?: string | null;
  dashboardUser?: boolean | null;
  fieldworker?: boolean | null;
  restrictive?: boolean | null;
}

export interface UserInductionInputInductionUserInductionUserUserType {
  id?: number | null;
  userType?: string | null;
  label?: string | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
}

export interface UserInductionInputUser {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  username?: string | null;
  isActive?: boolean | null;
  employeeId?: string | null;
  address?: string | null;
  suburb?: string | null;
  state?: string | null;
  postcode?: string | null;
  contact?: string | null;
  dob?: any | null;
  emergencyContact?: string | null;
  emergencyContactName?: string | null;
  usi?: string | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  expirationTime?: any | null;
  employmentTypeId?: number | null;
  userTypeId?: number | null;
  cryptedPassword?: string | null;
  isSystemUser?: boolean | null;
  appVersion?: string | null;
  deviceOs?: string | null;
  deviceOsVersion?: string | null;
  employmentType?: UserInductionInputUserEmploymentType | null;
  userType?: UserInductionInputUserUserType | null;
  rights?: UserInductionInputUserUserRole[] | null;
  userInductions?: UserInductionInputUserUserInduction[] | null;
  userCompetencies?: UserInductionInputUserUserCompetency[] | null;
}

export interface UserInductionInputUserEmploymentType {
  id?: number | null;
  name?: string | null;
}

export interface UserInductionInputUserUserCompetency {
  id?: number | null;
  description?: string | null;
  competencyId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  createdTime?: any | null;
  userCompetencyCompetency?: UserInductionInputUserUserCompetencyCompetency | null;
}

export interface UserInductionInputUserUserCompetencyCompetency {
  id?: number | null;
  name?: string | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  licenseNumber?: string | null;
}

export interface UserInductionInputUserUserInduction {
  id?: number | null;
  inductionId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  createdTime?: any | null;
  userInductionInduction?: UserInductionInputUserUserInductionInduction | null;
}

export interface UserInductionInputUserUserInductionInduction {
  id?: number | null;
  name?: string | null;
  clientId?: number | null;
  projectId?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  client?: UserInductionInputUserUserInductionInductionClient | null;
  project?: UserInductionInputUserUserInductionInductionProject | null;
}

export interface UserInductionInputUserUserInductionInductionClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  projects?: UserInductionInputUserUserInductionInductionClientProject[] | null;
  contacts?: UserInductionInputUserUserInductionInductionClientContact[] | null;
}

export interface UserInductionInputUserUserInductionInductionClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserInductionInputUserUserInductionInductionClientProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  companyId?: number | null;
  contacts?: UserInductionInputUserUserInductionInductionClientProjectContact[] | null;
}

export interface UserInductionInputUserUserInductionInductionClientProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserInductionInputUserUserInductionInductionProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  companyId?: number | null;
  client?: UserInductionInputUserUserInductionInductionProjectClient | null;
  contacts?: UserInductionInputUserUserInductionInductionProjectContact[] | null;
}

export interface UserInductionInputUserUserInductionInductionProjectClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  contacts?: UserInductionInputUserUserInductionInductionProjectClientContact[] | null;
}

export interface UserInductionInputUserUserInductionInductionProjectClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserInductionInputUserUserInductionInductionProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface UserInductionInputUserUserRole {
  id?: number | null;
  name?: string | null;
  route?: string | null;
  isProtected?: boolean | null;
  isActive?: boolean | null;
  description?: string | null;
  dashboardUser?: boolean | null;
  fieldworker?: boolean | null;
  restrictive?: boolean | null;
}

export interface UserInductionInputUserUserType {
  id?: number | null;
  userType?: string | null;
  label?: string | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
}

export interface UserSearchExact {
  id?: number | null;
  employmentType?: UserExactEmploymentType | null;
  employmentTypeId?: number | null;
  isActive?: boolean | null;
  isSystemUser?: boolean | null;
  rights?: UserExactUserRole[] | null;
  userCompetencies?: UserExactUserCompetency[] | null;
  userInductions?: UserExactUserInduction[] | null;
  userType?: UserExactUserType | null;
  userTypeId?: number | null;
  isInsightsUser?: boolean | null;
}

export interface WebhookEdit {
  id?: number | null;
  createdBy?: WebhookEditUser | null;
  createdById?: number | null;
  createdTime?: any | null;
  isActive?: boolean | null;
  name?: string | null;
  secretKey?: string | null;
  url?: string | null;
  webhookModule?: WebhookEditWebhookModule | null;
  webhookModuleId?: number | null;
}

export interface WebhookEditUser {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  username?: string | null;
  isActive?: boolean | null;
  employeeId?: string | null;
  address?: string | null;
  suburb?: string | null;
  state?: string | null;
  postcode?: string | null;
  contact?: string | null;
  dob?: any | null;
  emergencyContact?: string | null;
  emergencyContactName?: string | null;
  usi?: string | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  expirationTime?: any | null;
  employmentTypeId?: number | null;
  userTypeId?: number | null;
  cryptedPassword?: string | null;
  isSystemUser?: boolean | null;
  appVersion?: string | null;
  deviceOs?: string | null;
  deviceOsVersion?: string | null;
  employmentType?: WebhookEditUserEmploymentType | null;
  userType?: WebhookEditUserUserType | null;
  rights?: WebhookEditUserUserRole[] | null;
  userInductions?: WebhookEditUserUserInduction[] | null;
  userCompetencies?: WebhookEditUserUserCompetency[] | null;
}

export interface WebhookEditUserEmploymentType {
  id?: number | null;
  name?: string | null;
}

export interface WebhookEditUserUserCompetency {
  id?: number | null;
  description?: string | null;
  competencyId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  createdTime?: any | null;
  userCompetencyCompetency?: WebhookEditUserUserCompetencyCompetency | null;
}

export interface WebhookEditUserUserCompetencyCompetency {
  id?: number | null;
  name?: string | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  licenseNumber?: string | null;
}

export interface WebhookEditUserUserInduction {
  id?: number | null;
  inductionId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  createdTime?: any | null;
  userInductionInduction?: WebhookEditUserUserInductionInduction | null;
}

export interface WebhookEditUserUserInductionInduction {
  id?: number | null;
  name?: string | null;
  clientId?: number | null;
  projectId?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  client?: WebhookEditUserUserInductionInductionClient | null;
  project?: WebhookEditUserUserInductionInductionProject | null;
}

export interface WebhookEditUserUserInductionInductionClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  projects?: WebhookEditUserUserInductionInductionClientProject[] | null;
  contacts?: WebhookEditUserUserInductionInductionClientContact[] | null;
}

export interface WebhookEditUserUserInductionInductionClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface WebhookEditUserUserInductionInductionClientProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  companyId?: number | null;
  contacts?: WebhookEditUserUserInductionInductionClientProjectContact[] | null;
}

export interface WebhookEditUserUserInductionInductionClientProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface WebhookEditUserUserInductionInductionProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  companyId?: number | null;
  client?: WebhookEditUserUserInductionInductionProjectClient | null;
  contacts?: WebhookEditUserUserInductionInductionProjectContact[] | null;
}

export interface WebhookEditUserUserInductionInductionProjectClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
  contacts?: WebhookEditUserUserInductionInductionProjectClientContact[] | null;
}

export interface WebhookEditUserUserInductionInductionProjectClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface WebhookEditUserUserInductionInductionProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface WebhookEditUserUserRole {
  id?: number | null;
  name?: string | null;
  route?: string | null;
  isProtected?: boolean | null;
  isActive?: boolean | null;
  description?: string | null;
  dashboardUser?: boolean | null;
  fieldworker?: boolean | null;
  restrictive?: boolean | null;
}

export interface WebhookEditUserUserType {
  id?: number | null;
  userType?: string | null;
  label?: string | null;
  modifiedBy?: string | null;
  modifiedTime?: any | null;
}

export interface WebhookEditWebhookModule {
  id?: number | null;
  moduleName?: string | null;
  methodName?: string | null;
  version?: number | null;
  deprecationDate?: any | null;
}

export interface WebhookImport {
  createdBy?: WebhookImportUser | null;
  createdById?: number | null;
  createdTime?: any | null;
  isActive?: boolean | null;
  name?: string | null;
  secretKey?: string | null;
  url?: string | null;
  webhookModule?: WebhookImportWebhookModule | null;
  webhookModuleId?: number | null;
}

export interface WebhookImportUser {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  username?: string | null;
  isActive?: boolean | null;
  employeeId?: string | null;
  address?: string | null;
  suburb?: string | null;
  state?: string | null;
  postcode?: string | null;
  contact?: string | null;
  dob?: any | null;
  emergencyContact?: string | null;
  emergencyContactName?: string | null;
  usi?: string | null;
  expirationTime?: any | null;
  employmentTypeId?: number | null;
  userTypeId?: number | null;
  isSystemUser?: boolean | null;
  appVersion?: string | null;
  deviceOs?: string | null;
  deviceOsVersion?: string | null;
  employmentType?: WebhookImportUserEmploymentType | null;
  userType?: WebhookImportUserUserType | null;
  rights?: WebhookImportUserUserRole[] | null;
  userInductions?: WebhookImportUserUserInduction[] | null;
  userCompetencies?: WebhookImportUserUserCompetency[] | null;
}

export interface WebhookImportUserEmploymentType {
  id?: number | null;
  name?: string | null;
}

export interface WebhookImportUserUserCompetency {
  id?: number | null;
  description?: string | null;
  competencyId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  userCompetencyCompetency?: WebhookImportUserUserCompetencyCompetency | null;
}

export interface WebhookImportUserUserCompetencyCompetency {
  id?: number | null;
  name?: string | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  licenseNumber?: string | null;
}

export interface WebhookImportUserUserInduction {
  id?: number | null;
  inductionId?: number | null;
  userId?: number | null;
  issueDate?: any | null;
  expirationDate?: any | null;
  userInductionInduction?: WebhookImportUserUserInductionInduction | null;
}

export interface WebhookImportUserUserInductionInduction {
  id?: number | null;
  name?: string | null;
  clientId?: number | null;
  projectId?: number | null;
  requiresExpirationDate?: boolean | null;
  requiresIssueDate?: boolean | null;
  client?: WebhookImportUserUserInductionInductionClient | null;
  project?: WebhookImportUserUserInductionInductionProject | null;
}

export interface WebhookImportUserUserInductionInductionClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  projects?: WebhookImportUserUserInductionInductionClientProject[] | null;
  contacts?: WebhookImportUserUserInductionInductionClientContact[] | null;
}

export interface WebhookImportUserUserInductionInductionClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface WebhookImportUserUserInductionInductionClientProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  companyId?: number | null;
  contacts?: WebhookImportUserUserInductionInductionClientProjectContact[] | null;
}

export interface WebhookImportUserUserInductionInductionClientProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface WebhookImportUserUserInductionInductionProject {
  id?: number | null;
  email?: string | null;
  address?: string | null;
  state?: string | null;
  postcode?: string | null;
  suburb?: string | null;
  externalId?: string | null;
  name?: string | null;
  isActive?: boolean | null;
  companyId?: number | null;
  client?: WebhookImportUserUserInductionInductionProjectClient | null;
  contacts?: WebhookImportUserUserInductionInductionProjectContact[] | null;
}

export interface WebhookImportUserUserInductionInductionProjectClient {
  id?: number | null;
  name?: string | null;
  abn?: string | null;
  externalId?: string | null;
  isActive?: boolean | null;
  contacts?: WebhookImportUserUserInductionInductionProjectClientContact[] | null;
}

export interface WebhookImportUserUserInductionInductionProjectClientContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface WebhookImportUserUserInductionInductionProjectContact {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
  officePhone?: string | null;
  mobile?: string | null;
  email?: string | null;
  jobTitle?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  comments?: string | null;
}

export interface WebhookImportUserUserRole {
  id?: number | null;
  name?: string | null;
  route?: string | null;
  isProtected?: boolean | null;
  isActive?: boolean | null;
  description?: string | null;
  dashboardUser?: boolean | null;
  fieldworker?: boolean | null;
  restrictive?: boolean | null;
}

export interface WebhookImportUserUserType {
  id?: number | null;
  userType?: string | null;
  label?: string | null;
}

export interface WebhookImportWebhookModule {
  id?: number | null;
  moduleName?: string | null;
  methodName?: string | null;
  version?: number | null;
  deprecationDate?: any | null;
}

export interface WebhookModuleEdit {
  id?: number | null;
  deprecationDate?: any | null;
  methodName?: string | null;
  moduleName?: string | null;
  version?: number | null;
}

export interface WebhookModuleImport {
  deprecationDate?: any | null;
  methodName?: string | null;
  moduleName?: string | null;
  version?: number | null;
}

export interface WorkItemImport {
  code?: string | null;
  label?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
