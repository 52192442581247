import { Input, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  idToRemove: string;
  isVisible: boolean;
  itemTypeLabel: string;
  onCancel: () => void;
  onOk: () => void;
  confirmLoading: boolean;
}

const ConfirmDeletionDialog = ({
  idToRemove,
  isVisible,
  itemTypeLabel,
  onCancel,
  onOk,
  confirmLoading,
}: IProps) => {
  const { t } = useTranslation('components');
  const [input, updateInput] = React.useState('');

  const handleOnInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateInput(e.target.value);

  return (
    <Modal
      title={t('ConfirmDeletionDialog.labels.title', { what: itemTypeLabel })}
      visible={isVisible}
      okButtonProps={{ disabled: !(input === idToRemove) }}
      onOk={onOk}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
    >
      <span>
        {t('ConfirmDeletionDialog.messages.confirm', { what: itemTypeLabel })}
        <strong> {idToRemove}</strong>. {t('common:messages.sure')}
      </span>
      <Input
        placeholder={t('ConfirmDeletionDialog.labels.placeholder', {
          what: itemTypeLabel,
        })}
        onChange={handleOnInputChange}
      />
    </Modal>
  );
};

export default ConfirmDeletionDialog;
