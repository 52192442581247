import { Button } from 'antd';
import { Field, getIn } from 'formik';
import { isString } from 'lodash-es';
import React from 'react';
import styled from 'styled-components';

import { PlusIcon } from '../../../icons';
import ErrorMessage from '../../components/ErrorMessage';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 10px 0;
`;

interface IAddButtonFieldProps {
  name: string;
  onClick: (o: object) => void;
  label: string;
}

interface IAddButtonProps extends IAddButtonFieldProps {
  error?: Error | string;
}

// pure button component
export const AddButton = ({ name, onClick, label, error }: IAddButtonProps) => {
  const handleClick = () => onClick({});
  const hasError = error && isString(error);

  return (
    <>
      <Button
        type="dashed"
        style={hasError ? { borderColor: 'red', color: 'red' } : undefined}
        onClick={handleClick}
      >
        <PlusIcon />
        {label}
      </Button>
      {hasError && <ErrorMessage name={name} />}
    </>
  );
};

// field wrapper
const AddButtonField = ({ name, onClick, label }: IAddButtonFieldProps) => (
  <Wrapper>
    <Field name={name}>
      {({ form }) => {
        const error = getIn(form.errors, name);

        return (
          <AddButton
            error={error}
            name={name}
            onClick={onClick}
            label={label}
          />
        );
      }}
    </Field>
  </Wrapper>
);

export default AddButtonField;
