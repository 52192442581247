import { message } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthState } from 'types';

import { Form, IFormFunc, Input } from '../../components';
import { signInMFA } from '../api';
import { confirmSignInValidationSchema } from '../validations';
import AuthPage from './AuthPage';
import AuthPageActions from './AuthPageActions';
import { LoginWizardContext } from './LoginWizard';

interface IConfirmSignInFormValues {
  code: string;
}

const ConfirmSignIn = () => {
  const { t } = useTranslation('auth');
  const loginWizard = useContext(LoginWizardContext);

  const handleSubmit = async (
    values: IConfirmSignInFormValues,
    func: IFormFunc,
  ) => {
    try {
      const { code } = values;
      const user = await signInMFA({ user: loginWizard.user, token: code });

      loginWizard.setState({ user, authState: AuthState.Authenticated });
    } catch (e) {
      message.error(e.message);
    } finally {
      func.setSubmitting(false);
    }
  };

  return (
    <AuthPage title={t('ConfirmSignIn.labels.title')}>
      <Form
        onSubmit={handleSubmit}
        validationSchema={confirmSignInValidationSchema}
      >
        <Input name="code" placeholder={t('ConfirmSignIn.labels.code')} />
        <AuthPageActions />
      </Form>
    </AuthPage>
  );
};

export default ConfirmSignIn;
