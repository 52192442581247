import { Select, SelectProps } from 'formik-antd';
import React from 'react';

import Spin from '../Spin';
import FieldHoc from './components/FieldHoc';

interface IProps extends SelectProps {
  name: string;
  placeholder?: string;
  style?: object;
  loading?: boolean;
  showSearch?: boolean;
  allowClear?: boolean;
}

const SelectField = ({
  name,
  placeholder,
  style,
  loading,
  ...props
}: IProps) => (
  <Select
    showSearch={true}
    allowClear={true}
    loading={loading}
    name={name}
    placeholder={placeholder}
    notFoundContent={loading ? <Spin /> : undefined}
    style={{ width: '100%', ...style }}
    {...props}
  />
);

export default FieldHoc(SelectField);
