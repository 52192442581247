import { size } from 'lodash-es';
import React from 'react';

import ModalFieldLayout from './ModalFieldLayout';
import RowFieldLayout from './RowFieldLayout';

interface IProps<T> {
  onRemove: (index: number) => void;
  fields: any[];
  index: number;
  name: string;
}

function Fields<T>(props: IProps<T>) {
  if (size(props.fields) > 10) {
    return <ModalFieldLayout<T> {...props} />;
  }

  return <RowFieldLayout<T> {...props} />;
}

export default Fields;
