export const forms = {
  title: 'form',
  title_plural: 'forms',
  fields: {
    areResultsEditable: 'Results editable',
    description: 'Description',
    displayMeta: 'Definition',
    defaultStatusId: 'Default status ID',
    isActive: 'Active',
    id: 'ID',
    isTemplate: 'Template',
    isArchived: 'Archived',
    printTemplate: 'Print template',
    settings: 'Webmerge',
    title: 'Title',
    requireClient: 'Client required',
    requireMachine: 'Machine required',
    requireProject: 'Project required',
    requireSupplier: 'Supplier required',
    requireWorker: 'Worker required',
    requireGeolocation: 'Geolocation required',
    requireForTimesheets: 'Require for timesheets',
    confirmationMessage: 'Confirmation message',
    submitButtonLabel: 'Submit button label',
    actions: 'Actions',
    required: 'Requires',
  },
  detail: {
    settings: 'Webmerge Settings',
    displayMeta: 'Form Definition',
  },
  actions: {
    disableResultsEditable: 'Lock results',
    enableResultsEditable: 'Enable editing results',
    disableTemplate: 'Hide as Template',
    enableTemplate: 'Set as Template',
  },
  tags: {
    client: 'Client',
    project: 'Project',
    asset: 'Asset',
    supplier: 'Supplier',
    worker: 'Worker',
    geolocation: 'Geo',
    timesheet: 'For Timesheet',
  },
};
