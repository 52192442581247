import { Button, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { HomeIcon } from '.';

const RootWrapper = styled.div`
  display: grid;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  display: grid;
  grid-row-gap: 10px;
  width: 40vw;
`;

const ButtonWrapper = styled.div`
  justify-self: center;
`;

interface IProps {
  title: string;
  error?: Error | string;
  onButtonClick: () => void;
}

const ErrorPage = ({ title, error, onButtonClick }: IProps) => {
  const { t } = useTranslation('components');

  return (
    <RootWrapper>
      <Content>
        <Typography.Title style={{ textAlign: 'center' }} level={3}>
          {title}
        </Typography.Title>
        {error && (
          <Typography.Paragraph
            code={true}
            copyable={true}
            ellipsis={{ rows: 3, expandable: true }}
          >
            {JSON.stringify(error)}
          </Typography.Paragraph>
        )}
        <ButtonWrapper>
          <Button icon={<HomeIcon />} onClick={onButtonClick}>
            {t('ErrorPage.labels.button')}
          </Button>
        </ButtonWrapper>
      </Content>
    </RootWrapper>
  );
};

export default ErrorPage;
