export const chargeCodes = {
  title: 'charge code',
  title_plural: 'charge codes',
  fields: {
    id: 'ID',
    label: 'Label',
    code: 'Code',
    actions: 'Actions',
  },
  edit: {
    title: 'Edit {{ code }}',
    loading: 'Loading charge code',
    success: 'Charge code {{ code }} successfully updated',
    submit: 'Update charge code',
  },
};
