import { message } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthState } from 'types';

import { Form, IFormFunc, Input } from '../../components';
import { newPassword } from '../api';
import { setNewPasswordValidationSchema } from '../validations';
import AuthPage from './AuthPage';
import AuthPageActions from './AuthPageActions';
import { LoginWizardContext } from './LoginWizard';

interface ISetNewPasswordFormValues {
  password: string;
}

const SetNewPassword = () => {
  const { t } = useTranslation('auth');
  const loginWizard = useContext(LoginWizardContext);

  const handleSubmit = async (
    values: ISetNewPasswordFormValues,
    func: IFormFunc,
  ) => {
    try {
      const { password } = values;
      const user = await newPassword({ user: loginWizard.user, password });

      loginWizard.setState({ user, authState: AuthState.MfaSetup });
      message.success(t('SetNewPassword.messages.success'));
    } catch (e) {
      message.error(e.message);
    } finally {
      func.setSubmitting(false);
    }
  };

  return (
    <AuthPage title={t('SetNewPassword.labels.title')}>
      <Form
        onSubmit={handleSubmit}
        validationSchema={setNewPasswordValidationSchema}
      >
        <Input
          type="password"
          name="password"
          placeholder={t('SetNewPassword.labels.password')}
        />
        <AuthPageActions />
      </Form>
    </AuthPage>
  );
};

export default SetNewPassword;
