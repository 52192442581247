import { UploadFile } from 'antd/lib/upload/interface';
import * as XLSX from 'xlsx';

import { readAsBinaryString } from './fileReader';

const STRING_ROW_KEYS = ['contact', 'abn'];
const getExtension = (file: UploadFile) => file.name.split('.').pop();

interface IStrIndex<T> {
  [key: string]: T;
}

export const removeNullValues = <T>(obj: T) => {
  const object = obj as unknown as IStrIndex<T>;

  return Object.keys(obj)
    .filter((e) => object[e] !== null)
    .reduce((o: IStrIndex<T>, e) => {
      o[e] = object[e];

      return o;
    }, {});
};

export const mapSheetData = <T>(data: T[]) =>
  data.map((row: T) => {
    const d = { ...row };
    STRING_ROW_KEYS.forEach((stringRowKey: string) => {
      if (row[stringRowKey]) {
        d[stringRowKey] = `${d[stringRowKey]}`;
      }
    });

    return d;
  });

async function importSheet<T>(file: UploadFile) {
  const result = await readAsBinaryString(file);
  const wb = XLSX.read(result, { type: 'binary' });
  const sheetName = wb.SheetNames[0];
  const sheet = wb.Sheets[sheetName];
  const jsonSheet = XLSX.utils.sheet_to_json(sheet) as T[];

  return mapSheetData<T>(jsonSheet);
}

async function importJson<T>(file: UploadFile) {
  const result = (await readAsBinaryString(file)) as string;

  return JSON.parse(result).map((row: T) => removeNullValues<T>({ ...row }));
}

export default async <T>(file: UploadFile): Promise<T[]> => {
  const extension = getExtension(file);
  if (extension === 'json') {
    return importJson<T>(file);
  } else {
    return importSheet<T>(file);
  }
};
