import { Affix, Button } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { MenuFoldIcon, MenuUnfoldIcon } from '..';
import { Logo } from './Menu';

interface IProps {
  isDrawerVisible: boolean;
  toggleDrawerVisible: () => void;
}

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  width: 100%;
`;

const Trigger = ({ isDrawerVisible, toggleDrawerVisible }: IProps) => (
  <Affix offsetTop={1}>
    <Content>
      <Logo src="./logo-dark.png" style={{ width: '25px' }} />
      <Button
        type="ghost"
        size="large"
        icon={isDrawerVisible ? <MenuUnfoldIcon /> : <MenuFoldIcon />}
        onClick={toggleDrawerVisible}
      />
    </Content>
  </Affix>
);

export default Trigger;
