export const users = {
  title: 'user',
  title_plural: 'users',
  fields: {
    id: 'ID',
    employeeId: 'Employee ID',
    username: 'Username',
    email: 'Email',
    fullName: 'Name',
    isActive: 'Active',
    isExpired: 'Expired',
    isSystemUser: 'System User',
    isInsightsUser: 'Insights User',
    userTypeName: 'User type',
    employmentTypeName: 'Employment type',
    contact: 'Contact',
    address: 'Address',
    actions: 'Actions',
    usi: 'USI',
    firstName: 'First name',
    lastName: 'Last name',
    state: 'State',
    suburb: 'Suburb',
    postcode: 'Post code',
    emergencyContact: 'Emergency contact',
    emergencyContactName: 'Emergency contact name',
    dob: 'DOB',
    rights: {
      id: 'User role',
    },
    tags: '',
  },
  create: {
    title: 'Create',
    submit: 'Create user',
    messages: {
      success: 'User {{ username }} successfully created',
    },
  },
  edit: {
    userTab: 'User',
    rightsTab: 'Rights',
    title: 'Update {{ name }}',
    submit: 'Update user',
    messages: {
      loading: 'Loading user',
      success: 'User {{ username }} successfully updated',
    },
  },
  api: {
    title: 'Create API user',
  },
  SuccessNotificationDescription: {
    labels: {
      username: 'Username',
      password: 'Password',
    },
    messages: {
      expire: 'User will expire on',
    },
  },
  inductions: {
    title: 'Inductions',
    add: 'Add induction',
    save: 'Update inductions',
    fields: {
      title: 'Inductions of {{ username }}',
      inductionId: 'Select induction',
      issueDate: 'Issue date',
      expirationDate: 'Expiration date',
    },
    messages: {
      loading: 'Loading user inductions',
      success: "{{ username }}'s inductions updated",
    },
  },
  competencies: {
    title: 'Competencies',
    add: 'Add competency',
    save: 'Update competencies',
    fields: {
      title: 'Competencies of {{ username }}',
      competencyId: 'Select competency',
      issueDate: 'Issue date',
      expirationDate: 'Expiration date',
      description: 'Description',
    },
    messages: {
      loading: 'Loading user competencies',
      success: "{{ username }}'s competencies updated",
    },
  },
  remove: {
    content:
      'Are you sure you want to remove {{count}} user? All data created by user will be deleted. If you need to preserve it, disable the user, instead.',
    content_plural:
      'Are you sure you want to remove {{count}} users? All data created by user will be deleted. If you need to preserve it, disable the user, instead.',
  },
  rights: {
    labels: {
      restrictions: 'Restrictions',
      rights: 'User Rights',
      id: 'Select User Role',
      add: 'Add User Role',
    },
  },
  tags: {
    expired: 'Expired',
  },
};
