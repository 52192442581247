import { blue } from '@ant-design/colors';
import { Typography, Upload } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import React from 'react';
import styled from 'styled-components';
import { Schema } from 'yup';

import { useBoolean, useCustom } from '../../hooks';
import { FileAddIcon } from '../../icons';
import Loader from '../../Loader';
import importFile from '../utils/importFile';

const { Paragraph } = Typography;

export interface IImportComplete<T> {
  valid: T[];
  invalid: T[];
}

interface IProps<T> {
  validation: Schema<T>;
  onComplete: (props: IImportComplete<T>) => void;
  onCancel: () => void;
}

const UploadWrapper = styled.div`
  text-align: right;
  & > button {
    margin-top: 20px;
  }
`;

function ImportForm<T>({ onComplete, validation }: IProps<T>) {
  const file = useCustom<UploadFile | null>(null);
  const isLoading = useBoolean(false);

  React.useEffect(() => {
    const handleUpload = async () => {
      if (file.value) {
        isLoading.setTrue();
        const result = await importFile<T>(file.value);

        const valid: T[] = [];
        const invalid: T[] = [];

        await Promise.all(
          result.map((r) =>
            validation
              .validate(r)
              .then(() => {
                valid.push(r);
              })
              .catch(() => invalid.push(r)),
          ),
        );

        isLoading.setFalse();
        onComplete({ valid, invalid });
      }
    };

    handleUpload();
  }, [file, isLoading, onComplete, validation]);

  const onRemove = () => {
    file.setValue(null);
  };

  const beforeUpload = (uploadFile: UploadFile) => {
    file.setValue(uploadFile);

    return false;
  };

  const props = {
    accept: '.xlsx, .json, .ods, .csv',
    fileList: file.value ? [file.value] : [],
    beforeUpload,
    onRemove,
  };

  return (
    <UploadWrapper>
      {isLoading.value && <Loader message="Parsing your file for import..." />}
      {!isLoading.value && (
        <Upload.Dragger {...props}>
          <div style={{ padding: 40 }}>
            <FileAddIcon
              style={{
                fontSize: '35px',
                color: blue.primary,
                marginBottom: '10px',
              }}
            />
            <Paragraph>Click or drag file to this area to upload</Paragraph>
          </div>
        </Upload.Dragger>
      )}
    </UploadWrapper>
  );
}

export default ImportForm;
