export const adminUsers = {
  title: 'Admin users',
  fields: {
    id: 'ID',
    email: 'E-mail',
    username: 'Username',
    status: 'Status',
    role: 'User Role',
    actions: 'Actions',
    firstName: 'First name',
    lastName: 'Last name',
  },
  roles: {
    admin: 'Admin',
    cs: 'CS',
    developer: 'Developer',
    sales: 'Sales',
  },
  edit: {
    submit: 'Update',
    title: 'Edit {{ username }}',
    messages: {
      success: 'Admin user successfully updated',
    },
  },
  create: {
    submit: 'Create',
    title: 'Create',
    messages: {
      success: 'Admin user successfully created',
    },
  },
  ResendConfirmationEmail: {
    labels: {
      resend: 'Resend',
      resendEmail: 'Resend confirmation email',
    },
    messages: {
      confirm:
        'You are about to reset all settings for {{username}} and resend confirmation email. Are you sure?',
      success: 'Confirmation email successfully sent',
    },
  },
  RemoveAdminUser: {
    labels: {
      remove: 'Remove',
      title: 'Remove admin user',
    },
    messages: {
      confirm: 'You are about to remove {{id}}. Are you sure?',
      success: 'User was successfully removed',
    },
  },
  TotalAdminUsers: {
    labels: {
      title: 'Total admin users: <br>',
    },
  },
};
