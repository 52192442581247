import { PageHeader as AntPageHeader } from 'antd';
import { PageHeaderProps } from 'antd/lib/page-header';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  .ant-page-header {
    background: #fff;
    padding: 10px 20px;
  }
  .ant-page-header-heading-title {
    font-size: 15px;
  }
  .ant-page-header-back-button {
    font-size: 15px;
  }
`;

const PageHeader = (props: PageHeaderProps) => (
  <Wrapper>
    <AntPageHeader {...props} />
  </Wrapper>
);

export default PageHeader;
