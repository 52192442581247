import { useCallback, useState } from 'react';

export function useCustom<T>(initial: T) {
  const [value, setValue] = useState<T>(initial);

  return {
    setValue,
    value,
  };
}

export function useStepper() {
  const [value, setValue] = useState<number>(0);

  return {
    reset: () => setValue(0),
    next: () => setValue(value + 1),
    previous: () => setValue(value - 1),
    setStep: (step: number) => setValue(step),
    value,
  };
}

export const useBoolean = (initial: boolean) => {
  const [value, setValue] = useState(initial);

  return {
    setFalse: useCallback(() => setValue(false), []),
    setTrue: useCallback(() => setValue(true), []),
    toggle: useCallback(() => setValue((v: boolean) => !v), []),
    setValue,
    value,
  };
};
