export const contacts = {
  title: 'contact',
  title_plural: 'contacts',
  fields: {
    id: 'ID',
    firstName: 'First name',
    lastName: 'Last name',
    company: 'Company',
    jobTitle: 'Job title',
    address1: 'Address 1',
    address2: 'Address 2',
    city: 'City',
    comments: 'Comments',
    email: 'Email',
    mobile: 'Mobile',
    officePhone: 'Office phone',
    postcode: 'Postcode',
    state: 'State',
  },
  relation: {
    title: 'Contacts',
    labels: {
      id: 'Contact',
      contacts: 'Edit {{name}} contacts',
      addContact: 'Add contact',
      save: 'Update contacts',
      add: 'Add contact',
      loading: 'Loading contacts',
    },
    messages: {
      success: "{{ name }}'s contacts successfully updated",
      idRequired: 'Please select contact',
    },
  },
};
