import { message } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { QRCode } from 'react-qr-svg';
import { AuthState } from 'types';

import { Form, IFormFunc, Input, Loader, useCustom } from '../../components';
import { confirmMFASetup, generateMFAQr } from '../api';
import { MFASetupValidationSchema } from '../validations';
import AuthPage from './AuthPage';
import AuthPageActions from './AuthPageActions';
import { LoginWizardContext } from './LoginWizard';

interface IMFASetupFormValues {
  code: string;
}

const MFASetup = () => {
  const { t } = useTranslation('auth');
  const qrCode = useCustom<string | undefined>(undefined);
  const loginWizard = useContext(LoginWizardContext);

  React.useEffect(() => {
    generateMFAQr(loginWizard).then((generatedQrCode) => {
      qrCode.setValue(generatedQrCode);
    });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (values: IMFASetupFormValues, func: IFormFunc) => {
    try {
      const { code } = values;
      const user = await confirmMFASetup({
        user: loginWizard.user,
        token: code,
      });

      message.success('User successfully set, you can log in.');
      loginWizard.setState({ user, authState: AuthState.Default });
    } catch (e) {
      message.error(e.message);
    } finally {
      func.setSubmitting(false);
    }
  };

  return (
    <AuthPage title={t('MFASetup.labels.title')}>
      {qrCode.value ? (
        <>
          <QRCode value={qrCode.value} />
          <Form
            onSubmit={handleSubmit}
            validationSchema={MFASetupValidationSchema}
          >
            <Input name="code" placeholder={t('MFASetup.labels.code')} />
            <AuthPageActions />
          </Form>
        </>
      ) : (
        <Loader message="Generating MFA code..." />
      )}
    </AuthPage>
  );
};

export default MFASetup;
